import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authActions } from '../store';
import {customAxios} from "../functions/custom_axios";

// create slice

const name = 'users';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null,
        statuses: null,
        selectedCustomer: null,
        customers: null,
        account_roles: null,
        listAPIResponse: null
    }
}

function createExtraActions() {

    return {
        register: register(),
        getAll: getAll(),
        getAllByFilter: getAllByFilter(),
        getById: getById(),
        getNewEntity: getNewEntity(),
        update: update(),
        delete: _delete(),
        getAllStatuses: getAllStatuses(),
        changePassword: changePassword(),
        getCustomerById: getCustomerById(),
        getAllCustomers: getAllCustomers(),
        getAllAccountRoles: getAllAccountRoles(),
        getPaginatedList: getPaginatedList(),
        updateUserAccountStatus: updateUserAccountStatus()
    };

    function register() {
        return createAsyncThunk(
            `${name}/register`,
            async function (data, { getState }) {
                try {
                    // const token = getState().auth.value;
                    // const headers = {
                    //     "Authorization": `Bearer ${token}`
                    // };
                    //const response = await customAxios.post(`/api/register/save`, data,  {headers});
                    const response = await customAxios.post(`/open-api/register/save`, data);
                    if(response && response.data) {
                        const responseData = response.data;
                        return responseData;
                    } else {
                        const responseData = "Error occured in Registration Process ";
                        return responseData;
                    }
                } catch (error) {
                    return error
                }
                // update stored user if the logged in user updated their own record   //TODO: Revisit this logic
                // const auth = getState().auth.value;
                // if (id === auth?.id.toString()) {
                //     // update local storage
                //     const user = { ...auth, ...data };
                //     localStorage.setItem('auth', JSON.stringify(user));
                //
                //     // update auth user in redux state
                //     dispatch(authActions.setAuth(user));
                // }
            }
        );
    }

    // function getAll() {
    //     return createAsyncThunk(
    //         `${name}/getAll`,
    //         async () => await fetchWrapper.get(`/api/users`)
    //     );
    // }
    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async function ({ getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/users`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async function (id, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/user/${id}`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {}
                }
            }

        );
    }

    function getNewEntity() {
        return createAsyncThunk(
            `${name}/getNewEntity`,
            async function (entity) {
                return entity;
            }
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async function ({ id, data }, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.put(`/api/user/${id}`, data,  {headers});
                    const responseData = response.data;
                    return responseData;
                } catch (error) {
                    return {}
                }
                // update stored user if the logged in user updated their own record   //TODO: Revisit this logic
                // const auth = getState().auth.value;
                // if (id === auth?.id.toString()) {
                //     // update local storage
                //     const user = { ...auth, ...data };
                //     localStorage.setItem('auth', JSON.stringify(user));
                //
                //     // update auth user in redux state
                //     dispatch(authActions.setAuth(user));
                // }
            }
        );
    }

    // prefixed with underscore because delete is a reserved word in javascript
    function _delete() {
        return createAsyncThunk(
            `${name}/delete`,
            async function (id, { getState, dispatch }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.delete(`/api/user/${id}`, {headers});
                    const responseData = response.data;
                    // auto logout if the logged in user deleted their own record
                    if (id === getState().auth.value?.id) {
                        dispatch(authActions.logout());
                    }

                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

    function getAllStatuses() {
        return createAsyncThunk(
            `${name}/getAllStatuses`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/statuses`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function changePassword() {
        return createAsyncThunk(
            `${name}/change_password`,
            async function ({id, data}, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.put(`/api/change_password/${id}`, data,  {headers});
                    if(response) {
                        return response.data;
                    }
                    return "Error occurred while changing password";
                } catch (error) {
                    return error;
                }
            }
        );
    }

    function getAllByFilter() {
        return createAsyncThunk(
            `${name}/getAllByFilter`,
            async function ({customerId}, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/users/customer/${customerId}`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getCustomerById() {
        return createAsyncThunk(
            `${name}/getCustomerById`,
            async function (customerId, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/customer/${customerId}`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {}
                }
            }

        );
    }

    function getAllCustomers() {
        return createAsyncThunk(
            `${name}/getAllCustomers`,
            async function (args,{ getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/customers`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getAllAccountRoles() {
        return createAsyncThunk(
            `${name}/getAllAccountRoles`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/account_roles`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getPaginatedList() {
        return createAsyncThunk(
            `${name}/getPaginatedList`,
            async function (filter, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.post(`/api/customized/users`, filter, {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {};
                }
            }
        );
    }

    function updateUserAccountStatus() {
        return createAsyncThunk(
            `${name}/updateUserAccountStatus`,
            async function ({userId, toggledStatusId}, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    // const payload = {
                    //     userId,
                    //     accountStatusId: toggledStatusId
                    // }
                    const response = await customAxios.put(`/api/user/status/save?userId=${userId}&accountStatusId=${toggledStatusId}`, {}, {headers});
                    if(response &&  response.data) {
                        const responseData = response.data;
                        return responseData;
                    } else {
                        return "Error occurred while updating the Account Status";
                    }
                } catch (error) {
                    return {};
                }
            }
        );
    }
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
        getNewEntity();
//        _delete();
        getAllStatuses();
        getAllByFilter();
        getCustomerById();
        getAllCustomers();
        getAllAccountRoles();
        getPaginatedList();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        function getNewEntity() {
            var { pending, fulfilled, rejected } = extraActions.getNewEntity;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        // function _delete() {
        //     var { pending, fulfilled, rejected } = extraActions.delete;
        //     builder
        //         .addCase(pending, (state, action) => {
        //             const user = state.list.value.find(x => x.id === action.meta.arg);
        //             user.isDeleting = true;
        //         })
        //         .addCase(fulfilled, (state, action) => {
        //             state.list.value = state.list.value.filter(x => x.id !== action.meta.arg);
        //         })
        //         .addCase(rejected, (state, action) => {
        //             const user = state.list.value.find(x => x.id === action.meta.arg);
        //             user.isDeleting = false;
        //         });
        // }

        function getAllStatuses() {
            var { pending, fulfilled, rejected } = extraActions.getAllStatuses;
            builder
                .addCase(pending, (state) => {
                    state.statuses = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.statuses = { value: action.payload };
                    //alert('Payload: '+JSON.stringify(action.payload));
                })
                .addCase(rejected, (state, action) => {
                    state.statuses = { error: action.error };
                });
        }

        function getAllByFilter() {
            var { pending, fulfilled, rejected } = extraActions.getAllByFilter;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getCustomerById() {
            var { pending, fulfilled, rejected } = extraActions.getCustomerById;
            builder
                .addCase(pending, (state) => {
                    state.selectedCustomer = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.selectedCustomer = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.selectedCustomer = { error: action.error };
                });
        }

        function getAllCustomers() {
            var { pending, fulfilled, rejected } = extraActions.getAllCustomers;
            builder
                .addCase(pending, (state) => {
                    state.customers = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.customers = { value: action.payload };
                    //alert('Payload: '+JSON.stringify(action.payload));
                })
                .addCase(rejected, (state, action) => {
                    state.customers = { error: action.error };
                });
        }

        function getAllAccountRoles() {
            var { pending, fulfilled, rejected } = extraActions.getAllAccountRoles;
            builder
                .addCase(pending, (state) => {
                    state.account_roles = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.account_roles = { value: action.payload };
                    //alert('Payload: '+JSON.stringify(action.payload));
                })
                .addCase(rejected, (state, action) => {
                    state.account_roles = { error: action.error };
                });
        }

        function getPaginatedList() {
            var { pending, fulfilled, rejected } = extraActions.getPaginatedList;
            builder
                .addCase(pending, (state) => {
                    state.listAPIResponse = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.listAPIResponse = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.listAPIResponse = { error: action.error };
                });
        }

    };
}
