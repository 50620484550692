import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CancelIcon from '@mui/icons-material/Cancel';
import { history } from '../../../_services/essentials/helpers';

import {
    customersActions as myActions,
    alertActions,
    breadcrumbsActions
} from '../../../_services/essentials/store';
import { TextField, Button, Stack, FormControl} from '@mui/material';
import Typography from "@mui/material/Typography";
import {Authorization} from "../../../_components/Authorization";

export { AddEdit };

function AddEdit(props) {

    const entity_name = "customer";
    const entity_label = "Customer";
    const entities_name = "customers";
    const entities_label = "Customers";

    const { id } = useParams();
    const dispatch = useDispatch();
    const entity = useSelector(x => x.customers?.item);
    const previousURL = useSelector(x => x.breadcrumbs?.previous_url);

//alert('entity: '+JSON.stringify(entity));
    // form validation rules
    const validationSchema = Yup.object().shape({
        organizationName: Yup.string()
            .required('Required*'),
        websiteURL: Yup.string().url("Please enter a valid URL (http://www.kypl.com)")
            .required('Required*'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, control, setValue } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (id) {
            // fetch user details into redux state and
            // populate form fields with reset()
            dispatch(myActions.getById(id)).unwrap()
                .then(entity => {
                    reset(entity);
                    //      setUserValue(user);
                });

        } else {
            const newEntity = {
                organizationName: "",
                websiteURL: ""
            };
            dispatch(myActions.getNewEntity(newEntity)).unwrap()
                .then(entity => {
                    reset(entity);
                });
        }
    }, [id, dispatch, reset]);

    async function onSubmit(data) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            let message;
            if (id) {
                await dispatch(myActions.update({ id, data })).unwrap();
                //TODO: Make use of return value. and display message based on return value
                message = `${entity_label} details updated successfully`;

            } else {
                const response = await dispatch(myActions.create({data})).unwrap();
                //dispatch(alertActions.error(response));
                //TODO: Make use of return value. and display message based on return value
                message = response;
                //message = 'User added';
                history.navigate(`/tasks/customized/fresh/true/search/*/status/-1/priority/-1/category/-1/custom_list/-1`);
            }

            dispatch(alertActions.success({ message, showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <Authorization for={["Admin", "Super Admin"]}>
            <React.Fragment>
                <Stack
                    sx={{
                        //minWidth: '900px',
                        justifyContent: 'space-between', flexGrow: '1', gap: 1
                    }}
                    size={{ xs: 12, sm: 12, md:12, lg: 12 }}
                >
                    <Stack direction="column" size={{ xs: 6, sm: 6, md:3, lg: 3 }} sx={{ flexGrow: '1', gap: 1}}>
                        <Stack direction="column" size={{ xs: 12, sm: 12, md:12, lg: 12 }}>
                            <Typography component="h2" variant="h6" sx={{mb: 2}}>
                            {id ? `${entity_label}` : `Add New ${entity_label}`}
                            </Typography>
                        </Stack>
                    {!(entity?.loading || entity?.error) &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction="column" size={{ xs: 12, sm: 12, md:12, lg: 12 }}>
                            <FormControl>
                                <TextField
                                    id="organizationName"
                                    name="organizationName"
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="Organization Name*"
                                    placeholder={""}
                                    autoComplete="off"
                                    sx={{mb: 4}}
                                    inputlabelprops={{ shrink: true }}
                                    {...register('organizationName', {required: true})}
                                    error={Boolean(errors.organizationName)}
                                    helperText={errors.organizationName ? errors.organizationName.message : ""}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction="column" size={{ xs: 12, sm: 12, md:12, lg: 12 }}>
                            <FormControl>
                                <TextField
                                    id="websiteURL"
                                    name="websiteURL"
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="Website URL*"
                                    placeholder={""}
                                    autoComplete="off"
                                    multiline
                                    sx={{mb: 4}}
                                    inputlabelprops={{ shrink: true }}
                                    {...register('websiteURL', {required: true})}
                                    error={Boolean(errors.websiteURL)}
                                    helperText={errors.websiteURL ? errors.websiteURL.message : " "}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction="column" size={{ xs: 12, sm: 12, md:12, lg: 12 }}>
                            <FormControl>
                                <Stack direction="row" spacing={2} sx={{mb: 0.5, mt:0.5}} >
                                    <Button type={"submit"} variant="contained" color="primary" size="small" endIcon={<SaveIcon />}>
                                        Save
                                    </Button>
                                    <Button onClick={() => reset()} variant="outlined" color="secondary" size="small" endIcon={<SettingsBackupRestoreIcon />}>
                                        Reset
                                    </Button>
                                    <Button onClick={() => history.navigate(-1)} variant="outlined" color="secondary" size="small" startIcon={<CancelIcon />}>
                                        Cancel
                                    </Button>
                                </Stack>
                            </FormControl>
                        </Stack>
                    </form>
                    }
                    </Stack>
                </Stack>
            </React.Fragment>
        </Authorization>
    );
}
