import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {alertActions, breadcrumbsActions, persistor} from '../store';
import { history } from '../helpers';
import {customAxios} from "../functions/custom_axios";

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        //value: JSON.parse(localStorage.getItem('auth'))
        value: null,
        loggedInuser: null,
        accessTo: {
            SUPER_ADMIN_ONLY: false,
            ADMIN_AND_ABOVE: false,
            ANALYST_AND_ABOVE: false,
            USER_AND_ABOVE: false,
            ADMIN_ONLY: false,
            ANALYST_ONLY: false,
            USER_ONLY: false
        }
    }
}

function createReducers() {
    return {
        setAuth,
        getAuth,
        setLoggedInUser,
        setAccessTo
    };

    function setAuth(state, action) {
        state.value = action.payload;
    }

    function getAuth(state) {
        return state.value;
    }

    function setLoggedInUser(state, action) {
        state.loggedInUser = action.payload;

        const primary_role = (state.loggedInUser?.roles.length>0) ? state.loggedInUser?.roles[0] : null;
        if(primary_role && primary_role.name) {
            setAccessTo(state, primary_role.name);
        }
    }

    function setAccessTo(state, primary_role_name) {
        switch (primary_role_name) {
            case 'Super Admin':
                state.accessTo = {
                    SUPER_ADMIN_ONLY: true,
                    ADMIN_AND_ABOVE: true,
                    ADMIN_ONLY: false,
                    ANALYST_AND_ABOVE: false,
                    USER_AND_ABOVE: false,
                    ANALYST_ONLY: false,
                    USER_ONLY: false,
            };
            break;
            case 'Admin':
                state.accessTo = {
                    SUPER_ADMIN_ONLY: false,
                    ADMIN_AND_ABOVE: true,
                    ADMIN_ONLY: true,
                    ANALYST_AND_ABOVE: true,
                    USER_AND_ABOVE: true,
                    ANALYST_ONLY: false,
                    USER_ONLY: false
            };
            break;
            case 'Analyst':
                state.accessTo = {
                SUPER_ADMIN_ONLY: false,
                ADMIN_AND_ABOVE: false,
                ADMIN_ONLY: false,
                ANALYST_AND_ABOVE: true,
                USER_AND_ABOVE: true,
                ANALYST_ONLY: true,
                USER_ONLY: false
            };
            break;
            case 'User':
                state.accessTo = {
                    SUPER_ADMIN_ONLY: false,
                    ADMIN_AND_ABOVE: false,
                    ADMIN_ONLY: false,
                    ANALYST_AND_ABOVE: false,
                    USER_AND_ABOVE: true,
                    ANALYST_ONLY: false,
                    USER_ONLY: true
            };
            break;
            default:
            state.accessTo = {
                SUPER_ADMIN_ONLY: false,
                ADMIN_AND_ABOVE: false,
                ADMIN_ONLY: false,
                ANALYST_AND_ABOVE: true,
                USER_AND_ABOVE: false,
                ANALYST_ONLY: false,
                USER_ONLY: true
            };
            break;
        }
    }
}

function createExtraActions() {

    return {
        login: login(),
        logout: logout(),
        resetPassword: resetPassword(),
        updatePassword: updatePassword()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async function ({ email, password }, { dispatch }) {
                dispatch(alertActions.clear());
                try {
                    //const response = await instance.post(`/open-api/login`, { email, password });
                    const response = await customAxios.post(`/open-api/login`, { email, password });
                    const token = (response && response.data && response.data.data) ? response.data.data.token : null;
                    const loggedInUser = (response && response.data && response.data.data) ? response.data.data.loggedInUser : null;

                    const message = (response && response.data) ? response.data.message : "";
                    const error = (response && response.data) ? response.data.error : "";

                    // set auth user in redux state
                    if(message){
                        console.log('message: '+JSON.stringify(message));
                    }
                    if(token) {
                        dispatch(authActions.setAuth(token));
                        dispatch(authActions.setLoggedInUser(loggedInUser));
                        // localStorage.setItem('auth', token);
                        // localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
                        // get return url from location state or default to home page
                        const { from } = history.location.state || { from: { pathname: '/home' } };
                        history.navigate(from);
                    } else {
                        dispatch(alertActions.error(error));
                    }
                } catch (error) {
                    dispatch(alertActions.error(error));
                }
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            function (arg, { dispatch }) {
                dispatch(authActions.setAuth(null));
                dispatch(authActions.setLoggedInUser(null));
                dispatch(breadcrumbsActions.setSelectedMenuIndex(0));
                persistor.purge();
                persistor.flush();
                // localStorage.removeItem('auth');
                // localStorage.removeItem('loggedInUser');
                history.navigate('/account/login');
            }
        );
    }

    function resetPassword() {
        return createAsyncThunk(
            `${name}/resetPassword`,
            async function ({ email }, { dispatch }) {
                dispatch(alertActions.clear());
                let message;
                try {
                    const response = await customAxios.post(`/open-api/user/resetPassword?email=${email}&clientAppId=2`);
                    // set auth user in redux state
                    if(response){
                        console.log('message: '+JSON.stringify(response));
                    }
                    message = response.data;
                } catch (error) {
                    dispatch(alertActions.error(error));
                    message = error;
                }
                if(message) {
                    dispatch(alertActions.success({message, showAfterRedirect: true}));
                }
                history.navigate('/account/login');
            }
        );
    }

    function updatePassword() {
        return createAsyncThunk(
            `${name}/updatePassword`,
            async function (data, {dispatch}) {
                dispatch(alertActions.clear());
                let message;
                try {
                    const headers = {
                        "Content-Type": "application/x-www-form-urlencoded"
                    };
                    const response = await customAxios.post(`/open-api/user/savePassword`, data, {headers});
                    // set auth user in redux state
                    if (response) {
                        console.log('message: ' + JSON.stringify(response));
                    }
                    message = response.data;
                } catch (error) {
                    dispatch(alertActions.error(error));
                    message = error;
                }
                if (message) {
                    dispatch(alertActions.success({message, showAfterRedirect: true}));
                }
                history.navigate('/account/login');
            }
        );
    }
}