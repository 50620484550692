import axios from 'axios';
import {alertActions, authActions, store} from "../store";

const customAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 300000, //5 Minutes
//    headers: { 'Authorization': 'Bearer YOUR_ACCESS_TOKEN' },
});

export {customAxios};

// Request interceptor
customAxios.interceptors.request.use(
    (config) => {
        // Modify config before sending the request
       // config.headers['Authorization'] = 'Bearer YOUR_ACCESS_TOKEN';
        return config;
    },
    (error) => {
        // Handle request error
        if (error.request) {
            // The request was made, but no response was received
            //console.log('error.request: ' + error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
        }
        //console.log('error.config: '+error.config);
        return Promise.reject(error);
    }
);

// Response interceptor
customAxios.interceptors.response.use(
    (response) => {
        // Modify response data before passing it to the calling function
        //return response.data;
        //console.log('Resonse from Interceptor: '+JSON.stringify(response));
        return response;
    },
    (error) => {
        // Handle response error
        // console.log('Data: '+error);

        if (error.response) {
            // The request was made, but the server responded with a non-2xx status code
            // console.error('Data: '+error.response.data);
            // console.error('Status: '+error.response.status);
            // console.error('Headers: '+error.response.headers);
            //
            // console.log('Data: '+error.response.data);
            // console.log('Status: '+error.response.status);
            // console.log('Headers: '+error.response.headers);

            if(error.response.status === 401){
                store.dispatch(alertActions.error({message: "Session Expired. Please login again.", showAfterRedirect: true }));
                store.dispatch(authActions.logout());
            } else if (error.response.status === 500){
                // const dispatch = useDispatch();
                // dispatch(alertActions.error("Server Error. Please try again after some time."));
                store.dispatch(alertActions.error({message: "Server is busy. Please try again after some time."}));
            } else {
                store.dispatch(alertActions.error({message: "Server error. Please contact our support team."}));
            }

            // return Promise.reject(error);
        } else {
            // Something happened in setting up the request that triggered an Error
            store.dispatch(alertActions.error({message: "server not reachable. Please try again after some time."}));
            // return Promise.reject(error);`
        }
        return Promise.reject(error);
    }

);