//Reference: for Color Palatte: https://observablehq.com/@d3/color-schemes

export const colorPalette = [
    "#fd7f6f",
    "#b2e061",
    "#bd7ebe",
    "#ffb55a",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#8bd3c7",
    "#0085ff"];

export const colorPalettePaired = ["#a6cee3","#1f78b4","#b2df8a","#33a02c","#fb9a99","#e31a1c","#fdbf6f","#ff7f00","#cab2d6","#6a3d9a","#ffff99","#b15928"]

export const taskStatusNames = [
    "Dropped",
    "Follow up",
    "Closed",
    "On Hold",
    "In Progress",
    "Blocked",
    "Open",
    "Archived"
];

// export const taskStatusColors = [
//     "#919191",
//     "#cb85d3",
//     "#01e10e",
//     "#fafa55",
//     "#fdc55e",
//     "#e15858",
//     "#53a7d3",
//     "#b8bb6d",
// ];

export const taskStatusColors = [
    "#8dd3c7",
    "#bebada",
    "#b3de69",
    "#ffed6f",
    "#fdb462",
    "#c4c4c4",
    "#fb8072",
    "#fccde5"
];

export const StatusColors = {
    "Open":	"#0085ff",
    "In Progress": "#fd891b",
    "Closed": "#919191",
    "On Hold": "#fab981",
    "Blocked": "#ff0000",
    "Dropped": "#919191",
    "Archived": "#919191",
    "Follow up": "#53a7d3",
};