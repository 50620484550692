import React, { useEffect, useState } from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../../_services/essentials/helpers';
import { authActions, alertActions } from '../../../_services/essentials/store';
import {Button, Stack} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import FormLabel from "@mui/material/FormLabel";

export { UpdatePassword };

function UpdatePassword() {
    const [title, setTitle] = useState();
    const dispatch = useDispatch();
    const user = useSelector(x => x.users?.item);
    const previousURL = useSelector(x => x.breadcrumbs?.previous_url);

    const [searchParams] = useSearchParams();
    const token = searchParams?searchParams.get('token') : '';
    // form validation rules
    const validationSchema = Yup.object().shape({
        newPassword:  Yup.string().required("New password is required")
            .min(6, 'Password must be at least 6 characters'),
        confirmNewPassword: Yup.string().required("Confirm New password is required")
                .oneOf([Yup.ref('newPassword')], 'Password and Confirm Password must match')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
            // const newUser = {
            //     id: id,
            //     password: "",
            //     newPassword: "",
            //     confirmNewPassword: ""
            // };
            //setUserValue(newUser);
            setTitle('Update Password');
    }, []);

    const Card = styled(MuiCard)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '60%',
        padding: theme.spacing(4),
        gap: theme.spacing(2),
        margin: 'auto',
        boxShadow:
            'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
            [theme.breakpoints.up('sm')]: {
                width: '600px'
            },
        ...theme.applyStyles('dark', {
            boxShadow:
                'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
        }),
    }));

    async function onSubmit(data) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param

            // if(data.newPassword!==data.confirmNewPassword){
            //     dispatch(alertActions.error('New Password and Confirm New Password are not matching'));
            // } else {

                let message = await dispatch(authActions.updatePassword(data)).unwrap();
                //alert('message: '+JSON.stringify(+message));
                console.log(message);
                // redirect to user list with success message
                //history.navigate('/users');
                //dispatch(alertActions.success({ message, showAfterRedirect: true }));
//            }
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (

        <Stack
            sx={{
                justifyContent: 'space-between',
//                height: '100dvh',
                p: 2,
                mt: 6
            }}
        >
            <Card variant="outlined">
                {/*<SitemarkIcon />*/}
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{ fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                >
                    Reset Password
                </Typography>
                {/*<Typography*/}
                {/*    component="h1"*/}
                {/*    variant="h4"*/}
                {/*    sx={{ width: '100%', fontSize: '16px' }}*/}
                {/*>*/}
                {/*    Enter the email address associated with your account and we'll send you a link to reset your password.*/}
                {/*</Typography>*/}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}
                    >

                        <Stack direction={"column"}
                               sx={{
                                   justifyContent: 'space-between',
                               }}
                        >
                            <Stack direction={"column"}
                                   sx={{
                                       justifyContent: 'space-between',
                                       p: 2
                                   }}
                            >

                                <FormControl>
                                    <TextField name="token" hidden type="hidden" {...register('token')} value={token} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="newPassword">New Password</FormLabel>
                                    <TextField
                                        name="newPassword"
                                        placeholder="••••••"
                                        type="password"
                                        id="newPassword"
                                        autoComplete="off"
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        {...register('newPassword', {required: true})}
                                        error={Boolean(errors.newPassword)}
                                        helperText={errors.newPassword ? errors.newPassword.message : ""}
                                        sx={{mb:2}}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="confrimNewPassword">Confirm New Password</FormLabel>
                                    <TextField
                                        name="confirmNewPassword"
                                        placeholder="••••••"
                                        type="password"
                                        id="confirmNewPassword"
                                        autoComplete="off"
                                        fullWidth
                                        variant="outlined"
                                        {...register('confirmNewPassword', {required: true})}
                                        error={Boolean(errors.confirmNewPassword)}
                                        helperText={errors.confirmNewPassword ? errors.confirmNewPassword.message : ""}
                                        sx={{mb:2}}
                                    />
                                </FormControl>

                                {/*<FormControlLabel*/}
                                {/*    control={<Checkbox value="remember" color="primary" />}*/}
                                {/*    label="Remember me"*/}
                                {/*/>*/}
                                {/*<ForgotPassword open={open} handleClose={handleClose} />*/}
                            </Stack>
                            <Stack direction={"row"}
                                   sx={{
                                       justifyContent: 'space-between',
                                       p: 2
                                   }}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                >
                                    Set Password
                                </Button>
                                <Button onClick={() => history.navigate("/account/login")} variant="outlined" color="primary" size="small" startIcon={<CancelIcon />}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </form>
            </Card>
        </Stack>
    );
}
