import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {customAxios} from "../functions/custom_axios";
// create slice

const name = 'customers';
const entity = 'customer';
const entities = 'customers';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const customersActions = { ...slice.actions, ...extraActions };
export const customersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null,
        statuses: null,
        listAPIResponse: null
    }
}

function createExtraActions() {

    return {
        getAll: getAll(),
        getById: getById(),
        getNewEntity: getNewEntity(),
        create: create(),
        update: update(),
        delete: _delete(),
        getPaginatedList: getPaginatedList()
    };

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async function ({ getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`,
                    };
                    const response = await customAxios.get(`/api/${entities}`,  {headers});
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async function (id, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`,
                    };
                    const response = await customAxios.get(`/api/${entity}/${id}`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {}
                }
            }

        );
    }

    function getNewEntity() {
        return createAsyncThunk(
            `${name}/getNewEntity`,
            async function (entity) {
                //alert('entity: '+JSON.stringify(entity));
                return entity;
            }
        );
    }


    function create() {
        return createAsyncThunk(
            `${name}/create`,
            async function ({ data }, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.post(`/api/${entity}`, data,  {headers});
                    const responseData = response.data;
                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async function ({ id, data }, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.put(`/api/${entity}/${id}`, data,  {headers});
                    const responseData = response.data;
                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

    // prefixed with underscore because delete is a reserved word in javascript
    function _delete() {
        return createAsyncThunk(
            `${name}/delete`,
            async function (id, { getState, dispatch }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.delete(`/api/${entity}/${id}`, {headers});
                    const responseData = response.data;
                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

    function getPaginatedList() {
        return createAsyncThunk(
            `${name}/getPaginatedList`,
            async function (filter, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`,
                    };
                    const response = await customAxios.post(`/api/customized/${entities}`, filter, {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {};
                }
            }
        );
    }

}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
        update();
        _delete();
        getPaginatedList();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        function update() {
            var { pending, fulfilled, rejected } = extraActions.update;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        function _delete() {
            var { pending, fulfilled, rejected } = extraActions.delete;
            builder
                .addCase(pending, (state, action) => {
                    const user = state.list.value.find(x => x.id === action.meta.arg);
                    user.isDeleting = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.list.value = state.list.value.filter(x => x.id !== action.meta.arg);
                })
                .addCase(rejected, (state, action) => {
                    const user = state.list.value.find(x => x.id === action.meta.arg);
                    user.isDeleting = false;
                });
        }

        function getPaginatedList() {
            var { pending, fulfilled, rejected } = extraActions.getPaginatedList;
            builder
                .addCase(pending, (state) => {
                    state.listAPIResponse = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.listAPIResponse = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.listAPIResponse = { error: action.error };
                });
        }

    };
}
