import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Login, Register, ForgotPassword, UpdatePassword } from './';

export { AccountLayout };

function AccountLayout() {
    const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
    if (auth) {
        return <Navigate to="/dashboard" />;
    }

    return (
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot_password" element={<ForgotPassword />} />
                <Route path="update_password" element={<UpdatePassword />} />
            </Routes>
    );
}
