// custom history object to allow navigation outside react components
export const uxscreen = {
//    12.5	312.5	625	937.5	1250	1562.5
    size12: { xs:'300px', sm:'600px', md:'900px', lg:'1200px', xl:'1500px'},
    size11_5: { xs:'287.5px', sm:'575px', md:'862.5px', lg:'1150px', xl:'1437.5px'},
    size11: { xs:'275px', sm:'550px', md:'825px', lg:'1100px', xl:'1375px'},
    size10_5: { xs:'262.5px', sm:'525px', md:'787.5px', lg:'1050px', xl:'1312.5px'},
    size10: { xs:'250px', sm:'500px', md:'750px', lg:'1000px', xl:'1250px'},
    size9_5: { xs:'237.5px', sm:'475px', md:'712.5px', lg:'950px', xl:'1187.5px'},
    size9: { xs:'225px', sm:'450px', md:'675px', lg:'900px', xl:'1125px'},
    size8_5: { xs:'212.5px', sm:'425px', md:'637.5px', lg:'850px', xl:'1062.5px'},
    size8: { xs:'200px', sm:'400px', md:'600px', lg:'800px', xl:'1000px'},
    size7_5: { xs:'187.5px', sm:'375px', md:'562.5px', lg:'750px', xl:'937.5px'},
    size7: { xs:'175px', sm:'350px', md:'525px', lg:'700px', xl:'875px'},
    size6_5: { xs:'162.5px', sm:'325px', md:'487.5px', lg:'650px', xl:'812.5px'},
    size6: { xs:'150px', sm:'300px', md:'450px', lg:'600px', xl:'750px'},
    size5_5: { xs:'137.5px', sm:'275px', md:'412.5px', lg:'550px', xl:'687.5px'},
    size5: { xs:'125px', sm:'250px', md:'375px', lg:'500px', xl:'625px'},
    size4_5: { xs:'112.5px', sm:'225px', md:'337.5px', lg:'450px', xl:'562.5px'},
    size4: { xs:'100px', sm:'200px', md:'300px', lg:'400px', xl:'500px'},
    size3_5: { xs:'87.5px', sm:'175px', md:'262.5px', lg:'350px', xl:'437.5px'},
    size3: { xs:'75px', sm:'150px', md:'225px', lg:'300px', xl:'375px'},
    size2_5: { xs:'62.5px', sm:'125px', md:'187.5px', lg:'250px', xl:'312.5px'},
    size2: { xs:'50px', sm:'100px', md:'150px', lg:'200px', xl:'250px'},
    size1_5: { xs:'37.5px', sm:'75px', md:'112.5px', lg:'150px', xl:'187.5px'},
    size1: { xs:'25px', sm:'50px', md:'75px', lg:'100px', xl:'125px'},
    size0_5: { xs:'12.5px', sm:'25px', md:'37.5px', lg:'50px', xl:'62.5px'},
    size0_255: { xs:'6.25px', sm:'12.5px', md:'18.75px', lg:'25px', xl:'31.75px'},
};










