import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {useDispatch, useSelector} from "react-redux";
import {alertActions, breadcrumbsActions, userActions as myActions} from "../../../_services/essentials/store";
import useScreenSize from "../../../_components/UserScreenSize";

import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Pagination from '@mui/material/Pagination';
import ViewHeadlineRoundedIcon from '@mui/icons-material/ViewHeadlineRounded';
//import Copyright from '../../../_components/internals/components/Copyright';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {history} from "../../../_services/essentials/helpers";
import {Authorization} from "../../../_components/Authorization";

export { List };

function List() {
    const entity_name = "user";
    const entity_label = "User";
    const entities_name = "users";
    const entities_label = "Users";

    const listAPIResponse = useSelector(x => x.users?.listAPIResponse);
    const entities = listAPIResponse?.value?.list;
    const totalPages = listAPIResponse?.value?.totalPages;
    const totalItems = listAPIResponse?.value?.totalItems;
    const dispatch = useDispatch();

    const { customerId } = useParams();

    const screenSize = useScreenSize();

    const [dataFilter, setDataFilter] = useState({
        "userFilter": {
            "customerId": customerId
        }
    });
    const [pageFilter, setPageFilter] = useState({
        "pageNumber": 1,
        "pageSize": 50,
        "sortField": "firstname",
        "sortDirection": "asc",
        "dataFilter": dataFilter
    });

    useEffect(() => {
        setDataFilter({
            "userFilter": {
                "customerId": customerId
            }
        });
    }, [customerId]);

    useEffect(() => {
        setPageFilter({
            ...pageFilter,
            "dataFilter": dataFilter
        })
    }, [dataFilter]);

    useEffect(() => {
        dispatch(breadcrumbsActions.setCurrentTitle(entities_label));
        // if(customerId) {
        //     dispatch(myActions.getCustomerById(customerId));
        // }
        dispatch(myActions.getPaginatedList(pageFilter));
    }, [dispatch, pageFilter]);

    function navigatePage(event, newPageNumber) {
        setPageFilter({
            ...pageFilter, // copy the current properties of "json"
            "pageNumber": newPageNumber // update the "name" property
        });
    }

    function showDetails(selectedId) {
        history.navigate(`/${entities_name}/edit/${selectedId}`);
    }

    function taggoleStatusId(currnetStatusId) {
        if(currnetStatusId===1) {
            return 2;
        } else {
            return 1;
        }
    }

    function taggoleStatusName(currnetStatusId) {
        if(currnetStatusId===1) {
            return "Deactivate";
        } else {
            return "Activate";
        }
    }

    async function handleStatus(userId, toggledStatusId) {
        try {
            let message;
            message = await dispatch(myActions.updateUserAccountStatus({userId, toggledStatusId})).unwrap(); //TODO: Add comfirmation
            dispatch(alertActions.success({ message, showAfterRedirect: true }));
            dispatch(myActions.getPaginatedList(pageFilter));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    const columns = [
        { field: 'id',
            renderHeader: () => (<h6><strong>{'#'}</strong></h6>),
            flex: 1,
            minWidth: 5,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
        },
        {
            field: 'firstname',
            renderHeader: () => (<h6><strong>{'First Name'}</strong></h6>),
            flex: 1,
            minWidth: 15,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
        },
        {
            field: 'lastname',
            renderHeader: () => (<h6><strong>{'Last Name'}</strong></h6>),
            flex: 1,
            minWidth: 15,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
        },
        {
            field: 'email',
            renderHeader: () => (<h6><strong>{'Email'}</strong></h6>),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 250,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
        },
        {
            field: 'phone',
            renderHeader: () => (<h6><strong>{'Phone'}</strong></h6>),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 50,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
        },
        {
            field: 'accountStatus',
            renderHeader: () => (<h6><strong>{'Status'}</strong></h6>),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 30,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value.name}</Typography>
        },
        {
            field: 'actions',
            renderHeader: () => (<h6><strong>{'Actions'}</strong></h6>),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Stack direction="row" spacing={2} sx={{mb: 0.5, mt:0.5}} >
                    <Button variant="outlined" color="secondary" size="small" endIcon={<EditNoteIcon />} onClick={()=>history.navigate(`/${entities_name}/edit/${params.row.id}`)} >
                        Edit
                    </Button>
                    <Button variant="outlined" color="secondary" size="small" sx={{width: '100px'}} onClick={()=> handleStatus(params.row.id, taggoleStatusId(params.row.accountStatus.id))}>
                        {taggoleStatusName(params.row.accountStatus.id)}
                    </Button>
                </Stack>
            )
        }
    ];

    return (
        <Authorization for={["Admin", "Super Admin"]}>
            <Box sx={{ width: '100%', minWidth: { sm: '100%', md: '1050px' } }}>
                <Stack direction="column" sx={{flexGrow: '1', gap: 1}}  spacing={2} >
                    <Stack direction="row" sx={{justifyContent: 'space-between', flexGrow: '1', gap: 1}}  spacing={4} >
                        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                            {entities_label}
                        </Typography>
                        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                            <Button onClick={()=>history.navigate(`/${entities_name}/add`)} variant="contained" color="primary" size="small" startIcon={<AddCircleIcon />}>
                                Add New User
                            </Button>
                        </Typography>
                    </Stack>
                    <Stack direction="column" sx={{flexGrow: '1', gap: 1, minWidth: '800px'}} >
                        <Grid container spacing={2} columns={12}>
                            <Grid size={{ md: 12, lg: 12 }}>
                                {(screenSize.screen ==='web') ? (
                                    <DataGrid
                                        autoHeight
                                        rowHeight={42}
                                        // checkboxSelection
                                        disableColumnMenu
                                        disableColumnFilter
                                        disableColumnSelector
                                        disableColumnResize
                                        hideFooterPagination={true}
                                        rows={entities}
                                        columns={columns}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                        }
                                        initialState={{
                                        }}
                                        slotProps={{
                                            // filterPanel: {
                                            //     filterFormProps: {
                                            //         logicOperatorInputProps: {
                                            //             variant: 'outlined',
                                            //             size: 'small',
                                            //         },
                                            //         columnInputProps: {
                                            //             variant: 'outlined',
                                            //             size: 'small',
                                            //             sx: { mt: 'auto' },
                                            //         },
                                            //         operatorInputProps: {
                                            //             variant: 'outlined',
                                            //             size: 'small',
                                            //             sx: { mt: 'auto' },
                                            //         },
                                            //         valueInputProps: {
                                            //             InputComponentProps: {
                                            //                 variant: 'outlined',
                                            //                 size: 'small',
                                            //             },
                                            //         },
                                            //     },
                                            //  },
                                        }}
                                    />
                                ):(
                                    <Grid
                                        container
                                        spacing={2}
                                        columns={12}
                                        sx={{ mb: (theme) => theme.spacing(2) }}
                                    >
                                        {entities?.map((entity, index) => (
                                            <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
                                                <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
                                                    <CardContent>
                                                        <Typography component="h2" variant="subtitle2" gutterBottom>
                                                            <Button
                                                                variant="link"
                                                                color="primary"
                                                                startIcon={<ViewHeadlineRoundedIcon color="primary"/>}
                                                                href={`/${entities_name}/edit/${entity.id}`}
                                                            />
                                                            {entity.firstname} {entity.lastname}
                                                        </Typography>

                                                        <Stack
                                                            direction="column"
                                                            sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
                                                        >
                                                            <Stack sx={{ justifyContent: 'space-between' }}>
                                                                <Stack
                                                                    direction="row"
                                                                    sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                                                >
                                                                    <Typography variant="h6" component="p">
                                                                        Email: {entity.email}
                                                                    </Typography>
                                                                    <Typography variant="h6" component="p">
                                                                        Phone: {entity.phone}
                                                                    </Typography>
                                                                </Stack>
                                                                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                                    Company Name: {entity.customer.organizationName}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {(totalPages > 1)  &&
                        <Pagination variant="outlined" color="primary" count={totalPages}  page={pageFilter.pageNumber} onChange={navigatePage}  />
                        }
                    </Stack>
                </Stack>
            </Box>
        </Authorization>
    );
}
