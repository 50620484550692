import { configureStore, combineReducers  } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
//import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
//import { thunk } from 'redux-thunk';


import { alertReducer } from './alert.slice';
import { breadcrumbsReducer } from './breadcrumbs.slice';
import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { customersReducer } from './customers.slice';
import { tasksReducer } from './tasks.slice';
import { dashboardReducer } from './dashboard.slice';

export * from './alert.slice';
export * from './breadcrumbs.slice';
export * from './auth.slice';
export * from './users.slice';
export * from './customers.slice';
export * from './tasks.slice';
export * from './dashboard.slice';

const secretKeyForLocalStorage = 'my-super-secret-key-is-always-secret-for-cs';

const rootPersistConfig = {
    key: 'root',
//    storage: storageSession,
    storage: storage,
    whitelist: ['auth','breadcrumbs'],
    blacklist: ['users'],
    transforms: [encryptTransform({
        secretKey: secretKeyForLocalStorage,
        onError: function(error) {
            // handle the error
        }
    })]
};

const authPersistConfig = {
    key: 'auth',
//    storage: storageSession,
    storage: storage,
    transforms: [encryptTransform({
        secretKey: secretKeyForLocalStorage,
        onError: function(error) {
            // handle the error
        }
    })]
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

const rootReducer = combineReducers(
    {
        alert: alertReducer,
        auth: persistedAuthReducer,
        users: usersReducer,
        customers: customersReducer,
        breadcrumbs: breadcrumbsReducer,
        tasks: tasksReducer,
        dashboard:  dashboardReducer,

    }
);


const persistedReducer = persistReducer(rootPersistConfig, rootReducer);


export const store = configureStore({
    reducer: persistedReducer,
    devTools: false,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
});

export const persistor = persistStore(store);