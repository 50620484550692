import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';

// interface UserAgreementProps {
//     open: boolean;
//     handleAgreementPageClose: () => void;
//     setAgreementSigned: (boolean) => void;
// }

export { UserAgreement };

function UserAgreement({ open, handleAgreementPageClose, setAgreementSigned }: {
    open: boolean;
    handleAgreementPageClose: () => void;
    setAgreementSigned: (boolean) => void;
}) {
    return (
        <Dialog
            open={open}
            onClose={handleAgreementPageClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    handleAgreementPageClose();
                },
            }}
        >
            <DialogTitle>A-Cubes User Agreement</DialogTitle>
            <DialogContent
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
            >
                <DialogContentText>
                    A-Cubes USER AGREEMENT
                    This User Agreement sets out the terms between you and A-Cubes Limited (“A-Cubes”)
                    under which you may access the intranet and software platform operated by A-Cubes
                    (the “Interface”). This User Agreement applies to all users of the Interface (each
                    a “User” or “You”, collectively “Users”) and constitutes a legally binding
                    agreement between each User individually and A-Cubes. If you, the User do not accept
                    the terms of this User Agreement, you cannot use the Interface.
                    By clicking the ‘Step Two’ button during the registration process you accept the
                    terms and conditions of this User Agreement.
                    1. YOUR USER ACCOUNT
                    1.1 When you register on the Website you will be allocated a personal account
                    on the Interface, which you can access by entering your username and
                    password (“User Account”).
                    1.2 The User Account is for a single User only. A-Cubes will not permit you to share
                    your username and password with any other person nor with multiple Users
                    on a network. Responsibility for the security and confidentiality of any
                    passwords issued rests with you. A-Cubes will not be liable for any losses or
                    damages suffered by you due to the disclosure of any User Account
                    passwords.
                    1.3 All User Accounts must be registered with your own name and a valid
                    personal email address that you access regularly so that moderation emails
                    can be sent to you. User Accounts registered with someone else's email
                    address, or with temporary email addresses, may be closed without notice.
                    A-Cubes may require Users to revalidate their User Account if A-Cubes believes
                    they have been using an invalid email address.
                    1.4 You will be responsible and liable for all activities occurring under your User
                    Account. If you suspect that a third party has gained unauthorised access to
                    access data, you must inform DWL immediately by sending an e-mail to
                    compliance@A-Cubes.com or such other e-mail address as may be notified to
                    you from time to time.
                    2. PROVISION AND USE OF SERVICES AND THE INTERFACE
                    2.1 For the duration of this User Agreement A-Cubes grants the User the revocable,
                    non-exclusive, non-transferable and non-sub-licensable right to use the
                    Interface for own business purposes in accordance with this User Agreement
                    and the respective description of services.
                    2
                    2.2 The Interface may from time to time provide certain services which enable
                    Users to submit information, statements, content, documents, ideas,
                    concepts, techniques or data (“Content”) to the Interface (“Interactive
                    Services”).
                    2.3 A-Cubes is under no obligation to oversee, monitor or moderate any Interactive
                    Services A-Cubes provides on the Interface, and A-Cubes expressly excludes
                    liability for any loss or damage arising from the use of any Interactive
                    Services by a User in contravention of A-Cubes’s content standards, whether
                    the service is moderated or not.
                    2.4 A-Cubes has no obligation to verify the identity of any Users when they are
                    connected to the Interface or to supervise the Content which has been
                    provided by Users.
                    2.5 Although some Interactive Services may allow you to choose to hide Content
                    you submit from certain classes of other Users ("Privacy Settings"), A-Cubes
                    cannot guarantee that any of these Privacy Settings will be effective or that
                    Content subject to these Privacy Settings will not be made generally and
                    publicly available by other Users or by third parties. A-Cubes will however, use
                    commercially reasonable efforts to control the display of Content that is
                    restricted by the Privacy Settings to other Users in accordance with the
                    Privacy Settings you have chosen.
                    2.6 If you submit Content A-Cubes cannot guarantee that other Users of the
                    Interface will not use the Content you have submitted. Therefore, if you have
                    Content that you would like to keep confidential and/or do not want others
                    to use, do not submit it to an Interactive Service or to any other part of the
                    Interface. A-Cubes is not responsible for the misuse or misappropriation by
                    other Users of the Interface of any Content submitted by you to an
                    Interactive Service.
                    2.7 Please note that certain Content which you may choose to submit to A-Cubes’s
                    Interactive Services or to any other part of this Interface may reveal personal
                    information about you (“Personal Data”). Any Personal Data is provided
                    voluntarily and with your consent for its use, storage, disclosure and possible
                    transfer out of the EU.
                    2.8 Prior to submitting Content to the Interface, you must consider and decide,
                    yourself, the extent to which you wish to reveal Personal Data about yourself
                    to the large community of other Users of this Interface, and to A-Cubes, and
                    you must not communicate any Content which could be harmful to you if
                    disclosed, processed or published in any way. Any Personal Data provided
                    3
                    by you is given at your own discretion for all purposes and without
                    expectation of protection or privacy.
                    3. PROHIBITED USES
                    This Interface is to be used for lawful purposes only. You may not use the Interface:
                    3.1 if you are under the age of 18;
                    3.2 in any way that is unlawful or fraudulent, or has any unlawful or fraudulent
                    purpose or effect or that breaches any applicable local, national or
                    international law or regulation;
                    3.3 to transmit, or procure the sending of, any unsolicited or unauthorised
                    advertising or promotional material or any other form of solicitation,
                    marketing, transaction, video, photo, sound, notice or advice;
                    3.4 to knowingly transmit any data, send or upload any Content that contains
                    viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware,
                    adware or any other harmful programs or similar computer code designed
                    to adversely affect the operation of any computer software or hardware;
                    3.5 to reproduce, duplicate, copy or re-sell any part of the Interface in
                    contravention of the terms of this User Agreement; and
                    3.6 to interfere with, cause damage or disrupt:
                    (i) any part of the Interface;
                    (ii) any equipment or network on which the Interface is stored;
                    (iii) any software used in the provision of the Interface; or
                    (iv) any equipment or network or software owned or used by any third
                    party.
                    4. TERMINATION AND FURTHER ACTION
                    4.1 Either party may terminate the User Agreement at any time. Termination of
                    the User Agreement shall automatically result in the withdrawal of all
                    authorizations and rights of use of the User as well as the access to the
                    Interface.
                    4
                    4.2 In addition to termination A-Cubes reserves the right to take further legal action
                    if A-Cubes determines, at its sole discretion whether, there has been a breach
                    of this User Agreement. A-Cubes may take all or any of the following actions,
                    with or without a warning to you:
                    (v) immediate temporary or permanent withdrawal of your right to
                    access and use the Interface;
                    (vi) immediate temporary or permanent removal of any posting or
                    material uploaded by you to the Interface;
                    (vii) legal proceedings against you for reimbursement of all costs on an
                    indemnity basis (including, but not limited to, reasonable
                    administrative and legal costs) resulting from the breach; and
                    (viii) disclosure of such information to law enforcement authorities or
                    other Users, Advertisers or Publishers as A-Cubes reasonably feels is
                    necessary.
                    5. RESERVATION OF RIGHTS
                    5.1 A-Cubes may add to, change, suspend or discontinue any aspect of the
                    Interface or this User Agreement at any time by publishing such
                    modifications on the Interface.
                    5.2 It is the Users obligation and responsibility to periodically review the User
                    Agreement on the Interface in order to remain aware of any changes to the
                    User Agreement.
                    6. GENERAL
                    6.1 A-Cubes may assign or otherwise transfer any of its rights, benefits and duties
                    under this User Agreement. Users may not transfer any of its rights, benefits
                    or duties under this User Agreement without A-Cubes’s prior written consent.
                    6.2 This User Agreement constitutes the entire agreement between the parties
                    and supersedes all previous agreements between the parties relating to its
                    subject matter. No other representation or statement, whether or not in
                    writing shall form a term of the User Agreement.
                    6.2 This User Agreement is governed by the law of England and Wales and the
                    courts of England and Wales have exclusive jurisdiction.                    
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pb: 3, px: 3 }}>
                <Button onClick={handleAgreementPageClose}>Cancel</Button>
                <Button variant="contained" onClick={()=> {handleAgreementPageClose(); setAgreementSigned(true)}} type="submit">
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
}
