import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './common/AppNavbar';
import Header from './common/AppHeader';
import MainGrid from './common/MainGrid';
import SideMenu from './common/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';
import {CustomRoute} from "./CustomRoute";
import { AppAlertBox } from "./AppAlertBox";
import {useSelector} from "react-redux";
import Copyright from "./internals/components/Copyright";
import {uxscreen} from "../_services/essentials/helpers";
import {Nav as WebNav} from "../_pages/web/Nav";
import {Footer as WebFooter} from "../_pages/web/Footer";
import useScreenSize from "./UserScreenSize";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export { WebLayout };

function WebLayout(props) {

    const screenSize = useScreenSize();

  return (
      <Stack
          direction={"column"}
          sx={{
            justifyContent: 'space-between',
            verticalAlign: 'middle',
            minHeight: screenSize.height
          }}>
            <WebNav/>
            <AppAlertBox/>
            <CustomRoute/>
            <WebFooter/>
      </Stack>
  );
}
