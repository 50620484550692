import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {useDispatch, useSelector} from "react-redux";
import {alertActions, breadcrumbsActions, customersActions as myActions} from "../../../_services/essentials/store";
import useScreenSize from "../../../_components/UserScreenSize";

import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Pagination from '@mui/material/Pagination';
import ViewHeadlineRoundedIcon from '@mui/icons-material/ViewHeadlineRounded';
//import Copyright from '../../../_components/internals/components/Copyright';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {history} from "../../../_services/essentials/helpers";
import {Authorization} from "../../../_components/Authorization";

export { List };

function List() {
    const entity_name = "customer";
    const entity_label = "Customer";
    const entities_name = "customers";
    const entities_label = "Customers";

    const listAPIResponse = useSelector(x => x.customers?.listAPIResponse);
    const entities = listAPIResponse?.value?.list;
    const totalPages = listAPIResponse?.value?.totalPages;
    const totalItems = listAPIResponse?.value?.totalItems;
    const dispatch = useDispatch();

    const screenSize = useScreenSize();

    const [dataFilter, setDataFilter] = useState({
        "customerFilter": {
        }
    });
    const [pageFilter, setPageFilter] = useState({
        "pageNumber": 1,
        "pageSize": 50,
        "sortField": "organizationName",
        "sortDirection": "asc",
        "dataFilter": dataFilter
    });

    useEffect(() => {
        dispatch(myActions.getPaginatedList(pageFilter));
    }, [dispatch, pageFilter]);

    function navigatePage(event, newPageNumber) {
        setPageFilter({
            ...pageFilter, // copy the current properties of "json"
            "pageNumber": newPageNumber // update the "name" property
        });
    }

    function showDetails(selectedId) {
        history.navigate(`/${entities_name}/edit/${selectedId}`);
    }

    async function handleDelete(id) {
        try {
            let message;
            //await dispatch(myActions.delete(id));  //TODO: Add comfirmation
            message = `${entity_label} record has been deleted`;
            dispatch(alertActions.success({ message, showAfterRedirect: true }));
            dispatch(myActions.getPaginatedList(pageFilter));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    const columns = [
        { field: 'id',
            renderHeader: () => (<h6><strong>{'#'}</strong></h6>),
            flex: 1.5,
            minWidth: 60,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
        },
        {
            field: 'organizationName',
            renderHeader: () => (<h6><strong>{'Organization Name'}</strong></h6>),
            flex: 1,
            minWidth: 280,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
        },
        {
            field: 'websiteURL',
            renderHeader: () => (<h6><strong>{'Website'}</strong></h6>),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 260,
            renderCell: (params) =>
                <Typography sx={{fontSize: '14px', mt:1.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
        },
        {
            field: 'actions',
            renderHeader: () => (<h6><strong>{'Actions'}</strong></h6>),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                    <Stack direction="row" spacing={2} sx={{mb: 0.5, mt:0.5}} >
                        <Button variant="outlined" color="secondary" size="small" endIcon={<EditNoteIcon />} onClick={()=>history.navigate(`/${entities_name}/edit/${params.row.id}`)} >
                            Edit
                        </Button>
                        <Button variant="outlined" color="secondary" size="small" endIcon={<GroupIcon />} onClick={()=>history.navigate(`/users/customer/${params.row.id}`)}>
                            View Users
                        </Button>
                        {/*<Button variant="outlined" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => handleDelete(params.row.id)}>*/}
                        {/*    Delete*/}
                        {/*</Button>*/}
                    </Stack>
            )
        }
    ];

    return (
        <Authorization for={["Admin", "Super Admin"]}>
        <Stack
            sx={{
                alignItems: 'center',
            }}
        >
        <Box sx={{ flexGrow: 1 }}>
            <Stack direction="column" sx={{flexGrow: '1', gap: 1}}  spacing={2} sx={{mb: 0.5, mt:0.5}} >
                <Stack direction="row" sx={{justifyContent: 'space-between', flexGrow: '1', gap: 1}}  spacing={4} >
                    <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                        {entities_label}
                    </Typography>
                    <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                        <Button  onClick={()=>history.navigate(`/${entities_name}/add`)} variant="contained" color="primary" size="small" startIcon={<AddCircleIcon />}>
                            Add New Customer
                        </Button>
                    </Typography>
                </Stack>
                <Stack direction="column" sx={{flexGrow: '1', gap: 1, minWidth: '800px'}} >
                    <Grid container spacing={2} columns={12}>
                        <Grid size={{ md: 12, lg: 12 }}>
                            {(screenSize.screen ==='web') ? (
                                <DataGrid
                                    autoHeight
                                    rowHeight={42}
                                    // checkboxSelection
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableColumnResize
                                    hideFooterPagination={true}
                                    rows={entities}
                                    columns={columns}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                    }
                                    initialState={{
                                    }}
                                    slotProps={{
                                        // filterPanel: {
                                        //     filterFormProps: {
                                        //         logicOperatorInputProps: {
                                        //             variant: 'outlined',
                                        //             size: 'small',
                                        //         },
                                        //         columnInputProps: {
                                        //             variant: 'outlined',
                                        //             size: 'small',
                                        //             sx: { mt: 'auto' },
                                        //         },
                                        //         operatorInputProps: {
                                        //             variant: 'outlined',
                                        //             size: 'small',
                                        //             sx: { mt: 'auto' },
                                        //         },
                                        //         valueInputProps: {
                                        //             InputComponentProps: {
                                        //                 variant: 'outlined',
                                        //                 size: 'small',
                                        //             },
                                        //         },
                                        //     },
                                        // },
                                    }}
                                />
                            ):(
                                <Grid
                                    container
                                    spacing={2}
                                    columns={12}
                                    sx={{ mb: (theme) => theme.spacing(2) }}
                                >
                                    {entities?.map((entity, index) => (
                                        <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
                                            <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
                                                <CardContent>
                                                    <Typography component="h2" variant="subtitle2" gutterBottom>
                                                        <Button
                                                            variant="link"
                                                            color="primary"
                                                            startIcon={<ViewHeadlineRoundedIcon color="primary"/>}
                                                            href={`/${entities_name}/edit/${entity.id}`}
                                                        />
                                                        {entity.organizationName}
                                                    </Typography>

                                                    <Stack
                                                        direction="column"
                                                        sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
                                                    >
                                                        <Stack sx={{ justifyContent: 'space-between' }}>
                                                            <Stack
                                                                direction="row"
                                                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                                            >
                                                                <Typography variant="h6" component="p">
                                                                    {entity.organizationName}
                                                                </Typography>
                                                            </Stack>
                                                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                                {entity.websiteURL}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {(totalPages > 1)  &&
                    <Pagination variant="outlined" color="primary" count={totalPages}  page={pageFilter.pageNumber} onChange={navigatePage}  />
                    }
                </Stack>
            </Stack>
        </Box>
        </Stack>
        </Authorization>
    );
}
