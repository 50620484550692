import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { history, uxscreen } from '../../../_services/essentials/helpers';
import {userActions, alertActions, breadcrumbsActions} from '../../../_services/essentials/store';
import Typography from "@mui/material/Typography";
import {Button, Stack} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";

export { ChangePassword };

function ChangePassword() {
    const dispatch = useDispatch();

    const user = useSelector(x => x.users?.item);
    const loggedInUser = useSelector(x => x.auth?.loggedInUser);
    const previousURL = useSelector(x => x.breadcrumbs?.previous_url);
    const id  = loggedInUser?.id;

    // form validation rules
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Required*'),
        newPassword:  Yup.string().required("New password is required")
            .min(6, 'Password must be at least 6 characters'),
        confirmNewPassword: Yup.string().required("Confirm New password is required")
            .oneOf([Yup.ref('newPassword')], 'Password and Confirm Password must match')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        // dispatch(breadcrumbsActions.setCurrentTitle(`Change Password`));
            // const newUser = {
            //     id: id,
            //     password: "",
            //     newPassword: "",
            //     confirmNewPassword: ""
            // };
            //setUserValue(newUser);
    }, []);

    async function onSubmit(data) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            let message;
            if (id) {
                message = await dispatch(userActions.changePassword({id, data})).unwrap();
                console.log(message);
                dispatch(alertActions.success({ message, showAfterRedirect: true }));
                history.navigate(-1);
            }
            // redirect to user list with success message

        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (

        <Stack
            sx={{
                justifyContent: 'space-between',
//                height: '100dvh',
                width: uxscreen.size4,
                p: 2
            }}
        >
            <Typography component="h2" variant="h6" sx={{mb: 2}}>
                Change Password
            </Typography>
            {/*<Typography*/}
            {/*    component="h1"*/}
            {/*    variant="h4"*/}
            {/*    sx={{ width: '100%', fontSize: '16px' }}*/}
            {/*>*/}
            {/*    Enter the email address associated with your account and we'll send you a link to reset your password.*/}
            {/*</Typography>*/}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
                >

                    <Stack direction={"column"}
                           sx={{
                               justifyContent: 'space-between',
                           }}
                    >
                        <Stack direction={"column"}
                               sx={{
                                   justifyContent: 'space-between',
                                   p: 2
                               }}
                        >
                            <FormControl>
                                <FormLabel htmlFor="password">Current Password</FormLabel>
                                <TextField
                                    name="password"
                                    placeholder=""
                                    type="password"
                                    id="password"
                                    autoComplete="off"
                                    autoFocus
                                    fullWidth
                                    variant="outlined"
                                    {...register('password', {required: true})}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password ? errors.password.message : ""}
                                    sx={{mb:2}}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="newPassword">New Password</FormLabel>
                                <TextField
                                    name="newPassword"
                                    placeholder=""
                                    type="password"
                                    id="newPassword"
                                    autoComplete="off"
                                    fullWidth
                                    variant="outlined"
                                    {...register('newPassword', {required: true})}
                                    error={Boolean(errors.newPassword)}
                                    helperText={errors.newPassword ? errors.newPassword.message : ""}
                                    sx={{mb:2}}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="confrimNewPassword">Confirm New Password</FormLabel>
                                <TextField
                                    name="confirmNewPassword"
                                    placeholder=""
                                    type="password"
                                    id="confirmNewPassword"
                                    autoComplete="off"
                                    fullWidth
                                    variant="outlined"
                                    {...register('confirmNewPassword', {required: true})}
                                    error={Boolean(errors.confirmNewPassword)}
                                    helperText={errors.confirmNewPassword ? errors.confirmNewPassword.message : ""}
                                    sx={{mb:2}}
                                />
                            </FormControl>

                            {/*<FormControlLabel*/}
                            {/*    control={<Checkbox value="remember" color="primary" />}*/}
                            {/*    label="Remember me"*/}
                            {/*/>*/}
                            {/*<ForgotPassword open={open} handleClose={handleClose} />*/}
                        </Stack>
                        <Stack direction={"row"}
                               sx={{
                                   justifyContent: 'space-between',
                                   p: 2
                               }}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                            >
                                Set Password
                            </Button>
                            <Button onClick={() => history.navigate("/account/login")} variant="outlined" color="primary" size="small" startIcon={<CancelIcon />}>
                                Cancel
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </form>
        </Stack>

        // <div>
        //     <Typography component="h2" variant="h6" sx={{mb: 2}}>
        //         {title}
        //     </Typography>
        //     {!(user?.loading || user?.error) &&
        //         <form onSubmit={handleSubmit(onSubmit)}>
        //             <div className="row">
        //                 <div className="mb-3 col">
        //                     <label className="form-label">Current Password</label>
        //                     <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
        //                     <div className="invalid-feedback">{errors.password?.message}</div>
        //                 </div>
        //             </div>
        //             <div className="row">
        //                 <div className="mb-3 col">
        //                     <label className="form-label">New Password</label>
        //                     <input name="newPassword" type="password" {...register('newPassword')} className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`} />
        //                     <div className="invalid-feedback">{errors.newPassword?.message}</div>
        //                 </div>
        //             </div>
        //             <div className="row">
        //                 <div className="mb-3 col">
        //                     <label className="form-label">Confirm New Password</label>
        //                     <input name="confirmNewPassword" type="password" {...register('confirmNewPassword')} className={`form-control ${errors.confirmNewPassword ? 'is-invalid' : ''}`} />
        //                     <div className="invalid-feedback">{errors.email?.message}</div>
        //                 </div>
        //             </div>
        //             <div className="mb-3">
        //                 <button type="submit" disabled={isSubmitting} className="btn btn-primary me-2">
        //                     {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
        //                     Change
        //                 </button>
        //                 <button onClick={() => reset()} type="button" disabled={isSubmitting} className="btn btn-secondary">Reset</button>
        //                 <Button onClick={() => history.navigate(-1)} variant="outlined" color="secondary" size="small" startIcon={<CancelIcon />}>
        //                     Cancel
        //                 </Button>
        //             </div>
        //         </form>
        //     }
        //     {user?.loading &&
        //         <div className="text-center m-5">
        //             <span className="spinner-border spinner-border-lg align-center"></span>
        //         </div>
        //     }
        //     {user?.error &&
        //         <div class="text-center m-5">
        //             <div class="text-danger">Error loading user: {user.error}</div>
        //         </div>
        //     }
        // </div>
    );
}
