import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import {useSelector} from "react-redux";
import {ModalPlaceholder} from "../ModalPlaceholder";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: (theme.vars || theme).palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: 'center',
  },
}));

export default function NavbarBreadcrumbs() {

  const currentPageTitle = useSelector(x => x.breadcrumbs?.current_title);
  const selectedTaskId = useSelector(x => x.breadcrumbs?.selected_task_id);
  const keepModalOpen = useSelector(x => x.breadcrumbs?.keep_modal_open);

  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      {/*<Typography variant="body1">Dashboard</Typography>*/}
      <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 600 }}>
        {currentPageTitle}
      </Typography>
      { (selectedTaskId && !(keepModalOpen.loading || keepModalOpen.error) && !(selectedTaskId.loading || selectedTaskId.error)) &&
      <ModalPlaceholder open={keepModalOpen} id={selectedTaskId}/>
      }
    </StyledBreadcrumbs>
  );
}
