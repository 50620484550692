import React from 'react';
import { useSelector, } from 'react-redux';
import Stack from "@mui/material/Stack";
import Alert from '@mui/material/Alert';
import {Button} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {history} from "../_services/essentials/helpers";
export { NotAuthorized };

function NotAuthorized() {
    const auth = useSelector(x => x.auth.value);

    // const url_list = useSelector(x => x.breadcrumbs?.url_list);
    // const url_list_length = useSelector(x => x.breadcrumbs?.url_list_length);
    //
    // const previousURL = url_list_length>=3 ? url_list[url_list_length-3] : "/home";

    // only show nav when logged in
    if (!auth) return null;

    return (
        <Stack direction="column" sx={{ display: 'flex', alignContent: 'center'}}>
            <Stack direction="column" sx={{ flexGrow: '1', gap: 1, mt: 5}}>
                <Alert variant="outlined" severity="error">
                    You are not Authorized to View or Edit this content!
                </Alert>`
            </Stack>
            <Stack direction="column" sx={{ display: 'flex', justifyContent:'center',alignItems:'center', mt: 5}}>
                <Button type={"submit"} onClick={()=>history.navigate(-1)} variant="contained" size="small" startIcon={<ArrowBackIcon />}>
                    Back to previous page
                </Button>
            </Stack>
        </Stack>
    );
}