import * as React from 'react';
import { styled } from '@mui/material/styles';
import Divider, { dividerClasses } from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { listClasses } from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MenuButton from './MenuButton';
import {authActions} from "../../_services/essentials/store";
import {useDispatch, useSelector} from "react-redux";
import {history} from "../../_services/essentials/helpers";

const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

export default function OptionsMenu() {
    const loggedInUser = useSelector(x => x.auth.loggedInUser);
    const loggedInUserId =  loggedInUser?.id;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };

    const handleProfile = (userId) => {
        setAnchorEl(null);
        history.navigate(`/users/edit/${userId}`);
    };

    const handleCreateNewAccount = () => {
        setAnchorEl(null);
        history.navigate(`/users/add`);
    };

    const handleChangePassword = (userId) => {
        setAnchorEl(null);
        history.navigate(`/users/change_password`);
    };


    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

  return (
    <React.Fragment>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: 'transparent' }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
          <Menu
            anchorEl={anchorEl}
            id="menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            sx={{
              [`& .${listClasses.root}`]: {
                padding: '4px',
              },
              [`& .${paperClasses.root}`]: {
                padding: 0,
              },
              [`& .${dividerClasses.root}`]: {
                margin: '4px -4px',
              },
            }}
          >
        <MenuItem onClick={() => handleProfile(loggedInUserId)}>My account</MenuItem>
        {/*<MenuItem onClick={() => handleCreateNewAccount()}>Create New Account</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
        <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
        {/*<MenuItem onClick={handleChangePassword}>Settings</MenuItem>*/}
        <Divider />
        <MenuItem
          onClick={logout}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: 'auto',
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Logout</ListItemText>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
