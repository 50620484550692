import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import { Stack } from '@mui/material';
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { useSelector, useDispatch } from 'react-redux';
import {breadcrumbsActions, dashboardActions as myActions} from '../../../_services/essentials/store';
import { colorPalette, taskStatusColors } from '../../../_services/essentials/constants';
import { TagCloud } from 'react-tagcloud';
import useScreenSize from "../../../_components/UserScreenSize";

export { Dashboard };

function Dashboard() {
    const inProgress = true;

    const dispatch = useDispatch();
    const loggedInUser = useSelector(x => x.auth?.loggedInUser);

    const screenSize = useScreenSize();

    //Start: Chart Data
    const [chartPubCategoryData, setChartPubCategoryData] = useState([]);
    const [chartPubSeriesData, setChartPubSeriesData] = useState([]);

    // const [chartEventCategoryData, setChartEventCategoryData] = useState([]);
    // const [chartEventSeriesData, setChartEventSeriesData] = useState([]);

    //    const [chartClinicalTrialCategoryData, setChartClinicalTrialCategoryData] = useState([]);
    const [chartClinicalTrialSeriesData, setChartClinicalTrialSeriesData] = useState([]);

    // const [chartSocialMediaCategoryData, setChartSocialMediaCategoryData] = useState([]);
    // const [chartSocialMediaSeriesData, setChartSocialMediaSeriesData] = useState([]);

    const [chartPubDataResponseData, setChartPubDataResponseData] = useState(null);
//    const [chartEventDataResponseData, setChartEventDataResponseData] = useState(null);
    const [chartClinicalTrialDataResponseData, setChartClinicalTrialDataResponseData] = useState(null);
//    const [chartSocialMediaDataResponseData, setChartSocialMediaDataResponseData] = useState(null);

    const [overDueMetricsData, setOverDueMetricsData] = useState([]);

    //End:

    useEffect(() => {
        if (loggedInUser) {
            loadCharts(loggedInUser.id);
            loadOverDueMeterics(loggedInUser.id);
        }
    }, [loggedInUser]);

    useEffect(() => {
        if(chartClinicalTrialDataResponseData) {
            const chartClinicalTrialDataResponse = chartClinicalTrialDataResponseData?.payload;
            if (chartClinicalTrialDataResponse && chartClinicalTrialDataResponse.seriesData) {

                let chartClinicalTrialSeriesDataJSONArray = [];
                for (const key in chartClinicalTrialDataResponse.seriesData) {
                    chartClinicalTrialSeriesDataJSONArray.push(
                        {
                            //id: `${key}-views`,
                            label: key,
                            value: chartClinicalTrialDataResponse.seriesData[key],
                        }
                    );

                    setChartClinicalTrialSeriesData([
                        {
                            arcLabel: (item) => `${item.value}`,
                            arcLabelMinAngle: 25,
                            data: chartClinicalTrialSeriesDataJSONArray,
                            innerRadius: 66,
                            outerRadius: 100,
                            paddingAngle: 0,
                            highlightScope: {faded: 'global', highlighted: 'item'},
                        },
                    ]);
                }
            }
        }
    }, [chartClinicalTrialDataResponseData]);

    useEffect(() => {
        const chartPubDataResponse = chartPubDataResponseData?.payload;
        if (chartPubDataResponse) {
            let chartPubSeriesDataJSONArray = [];
            setChartPubCategoryData([
                {
                    scaleType: 'band',
                    categoryGapRatio: 0.5,
                    data: chartPubDataResponse.categoryData,
                    label: 'Years',
                },
            ]);
            for (const key in chartPubDataResponse.seriesData) {
                chartPubSeriesDataJSONArray.push(
                    {
                        id: `${key}-views`,
                        label: key,
                        data: chartPubDataResponse.seriesData[key],
                        stack: 'A',
                    }
                );

                setChartPubSeriesData(chartPubSeriesDataJSONArray);
            }
        }
    }, [chartPubDataResponseData]);

    async function loadOverDueMeterics(userId) {
        if (!overDueMetricsData || (overDueMetricsData && overDueMetricsData.length===0) ) {
            setOverDueMetricsData(await dispatch(myActions.getOverDueMetricsData({userId})));
        }

    }

    async function loadCharts(userId) {
        //const chart_limit = -1;
        // const [pubData, eventData, trialData, socialMediaData] = await Promise.all([
        //     chartPubDataResponseData === null
        //         ? dispatch(myActions.getChartPubData({ userId, chart_limit }))
        //         : Promise.resolve(null),
        //     chartEventDataResponseData === null
        //         ? dispatch(myActions.getChartEventData({ userId, chart_limit }))
        //         : Promise.resolve(null),
        //     chartClinicalTrialDataResponseData === null
        //         ? dispatch(myActions.getCurrentMonthTaskData({ userId, chart_limit }))
        //         : Promise.resolve(null),
        //     chartSocialMediaDataResponseData === null
        //         ? dispatch(myActions.getChartSocialMediaData({ userId, chart_limit: 10 }))
        //         : Promise.resolve(null),
        // ]);

        const chart_limit = (screenSize.screen ==='web') ? -1 : 6;

        const tasksPerMonthData = await dispatch(myActions.getTasksPerMonthData({ userId,  chart_limit: chart_limit }));
        const trialData = await dispatch(myActions.getCurrentMonthTaskData({ userId, chart_limit: -1 }));

        setChartPubDataResponseData(tasksPerMonthData || chartPubDataResponseData);
        // setChartEventDataResponseData(eventData || chartEventDataResponseData);
        setChartClinicalTrialDataResponseData(trialData || chartClinicalTrialDataResponseData);
        // setChartSocialMediaDataResponseData(socialMediaData || chartSocialMediaDataResponseData);
    }

    function showDetails(selectedId) {
        //history.navigate(`/tasks/edit/${selectedId}`);
        dispatch(breadcrumbsActions.setSelectedTaskId(selectedId));
        dispatch(breadcrumbsActions.setKeepModalOpen(true));
        dispatch(breadcrumbsActions.setModalModified(false));
        //alert('Due: '+tagCloud.count+' days ago. Task name: '+tagCloud.value);
    }

    return (
        <React.Fragment>
            <Stack direction="column" spacing={2} sx={{ gap: 1, width: '100%' }}>
                <Stack
                    direction="column"
                    sx={{
                        width: '100%',
                        flex: 1, // Equal distribution
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 1,
                        //background: '#F6F8FB',
                        background: '#eafaf1',
                        backdropFilter: 'blur(1px)',
                        borderRadius: '12px',
                        border: '1px solid #009999'
                    }}
                >
                    <Typography component="h2" sx={{ mt: 1, mb: 2, fontSize: '16px', fontWeight: 'bold' }}>
                        Over Due Tasks Cloud
                    </Typography>
                    <Stack
                        direction="column"
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '95%'
                        }}
                    >
                        {(overDueMetricsData?.payload && Array.prototype.isPrototypeOf(overDueMetricsData?.payload)) &&
                        <TagCloud
                            // width = "400px"
                            minSize={5}
                            maxSize={30}
                            tags={overDueMetricsData?.payload}
                            // colorOptions={{
                            //     luminosity: '#eafaf1',
                            //     hue: '#0d0856',
                            // }}
                            onClick={tagCloud => showDetails(tagCloud.id)}
                            // onClick={tagCloud => alert('Due: '+JSON.stringify(tagCloud))}
                        />
                        }
                        {!(overDueMetricsData?.payload && Array.prototype.isPrototypeOf(overDueMetricsData?.payload)) &&
                        <Typography component="h2" sx={{ mt: 1, mb: 3, fontSize: '16px', color: '#404040' }}>
                            You have no over due Tasks. Enjoy!
                        </Typography>
                        }
                    </Stack>
                </Stack>
                <Stack
                    direction="column"
                    sx={{
                        width: '100%',
                        flex: 1, // Equal distribution
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 1,
                        //background: '#F6F8FB',
                        background: '#ebf5fb',
                        backdropFilter: 'blur(1px)',
                        borderRadius: '12px',
                        border: '1px solid #009999'
                    }}
                >
                    <Typography component="h2" sx={{ mt: 1, fontSize: '16px', fontWeight: 'bold' }}>
                        Tasks per Status
                    </Typography>
                    {(chartClinicalTrialSeriesData && chartClinicalTrialSeriesData.length > 0) &&
                    <PieChart
                        colors={taskStatusColors}
                        margin={{
                            left: 60,
                            right: 60,
                            top: 60,
                            bottom: 155,
                        }}
                        series={chartClinicalTrialSeriesData}
                        height={300}
                        slotProps={{
                            legend: {
                                hidden: false,
                                direction: "row",
                                position: { vertical: 'bottom', horizontal: 'middle' },
                                labelStyle: {
                                    fontSize: 14,
                                    // fill: 'blue',
                                }
                            },
                        }}
                    >
                        {/*<PieCenterLabel primaryText="98.5K" secondaryText="Total" />*/}
                    </PieChart>
                    }
                </Stack>
                <Stack
                    direction="column"
                    sx={{
                        width: '100%',
                        flex: 1, // Equal distribution
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 1,
                        //background: '#F6F8FB',
                        background: '#fff1f8',
                        backdropFilter: 'blur(1px)',
                        borderRadius: '12px',
                        border: '1px solid #009999'
                    }}
                >
                    <Typography component="h2" sx={{ mt: 1, fontSize: '16px', fontWeight: 'bold' }}>
                        Tasks per Month
                    </Typography>
                    <BarChart
                        borderRadius={8}
                        colors={taskStatusColors}
                        // colorMap={{
                        //     type: 'ordinal',
                        //     thresholds: taskStatusNames,
                        //     colors: taskStatusColors
                        // }}
                        xAxis={chartPubCategoryData}
                        series={chartPubSeriesData}
                        barLabel={(item, context) => {
                            // if ((item.value ?? 0) > 10) {
                            //     return 'High';
                            // }
                            return context.bar.height < 20 ? null : item.value?.toString();
                        }}
                        height={300}
                        margin={{ left: 50, right: 0, top: 6, bottom: 160 }}
                        grid={{ horizontal: true }}
                        sx={{ mb: 2, ml: 2, mr: 2 }}
                        slotProps={{
                            legend: {
                                hidden: false,
                                direction: "row",
                                position: { vertical: 'bottom', horizontal: 'middle' },
                                labelStyle: {
                                    fontSize: 14,
                                    // fill: 'blue',
                                }
                            },
                        }}
                    />
                </Stack>
            </Stack>
        </React.Fragment>
    );

}