import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './common/AppNavbar';
import Header from './common/AppHeader';
import SideMenu from './common/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';
import {CustomRoute} from "./CustomRoute";
import { AppAlertBox } from "./AppAlertBox";
import Copyright from "./internals/components/Copyright";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import OptionsMenu from "./common/OptionsMenu";
import useScreenSize from "./UserScreenSize";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export { AppLayout };

function AppLayout(props) {

    //Notification Logic:
    // if (!("Notification" in window)) {
    //     console.log("Browser does not support desktop notification");
    // } else {
    //     Notification.requestPermission();
    // }
    //  new Notification('Hello World')

    const screenSize = useScreenSize();

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      {/*<ContinueSession open={true} />*/}
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        {/*<Stack sx={{ flexGrow: 1, p: 0, height: '100$', justifyContent: 'space-between'}}></Stack>*/}
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            // backgroundColor: theme.vars
            //   ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
            //   : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
            //backgroundColor: `rgb(255, 230, 243,0.1)`
            background: `linear-gradient(to top,                 
                 rgba(173, 102, 255, 0.4),
                 rgba(168, 240, 229, 0.4)
                 )`,
          })}
        >
          <Stack
            // spacing={2}
            sx={{
                alignItems: 'center',
                mx: 3,
                pb: 2,
                mt: { xs: 8, md: 0 },
                height: screenSize.height
            }}
          >
            <Header />
            {/*<MainGrid />*/}
            <AppAlertBox/>
            <CustomRoute/>
            <Stack direction="row" sx={{height: '100%'}}></Stack>{/*Following line height: '100%' is used to adjust the screen size based on the content*/}
            <Copyright sx={{ mt: 1, mb: 1 }} />
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
