import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import SnoozeOutlinedIcon from '@mui/icons-material/SnoozeOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/system";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import {history, uxscreen} from '../../../_services/essentials/helpers';

import {
    tasksActions,
    alertActions,
    breadcrumbsActions,
    userActions,
    userProjectsActions
} from '../../../_services/essentials/store';
import { TextField, Button, Container, Stack, Select, MenuItem, InputLabel, FormControl} from '@mui/material';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export { AddEdit };

function AddEdit(props) {

    const entities = 'tasks';
    const entityLabel = 'Task';
    const pageParams = useParams();

    const isPageInPopupMode = props.quickAddFlag || !!props?.id;
    const { id } = isPageInPopupMode ? props : pageParams;

    const dispatch = useDispatch();
    // if(!isPageInPopupMode) {
    //     dispatch(breadcrumbsActions.setCurrentTitle(`New Task`));
    // }

    const entity = useSelector(x => x.tasks?.item);
    const task_statuses = useSelector(x => x.tasks?.task_statuses);
    const task_priorities = useSelector(x => x.tasks?.task_priorities);
    const task_categories = useSelector(x => x.tasks?.task_categories);
    const custom_lists = useSelector(x => x.tasks?.custom_lists);
    const all_users = useSelector(x => x.tasks?.all_users);
    const task_repeat_options = useSelector(x => x.tasks?.task_repeat_options);
    const task_repeat_frequency_types = useSelector(x => x.tasks?.task_repeat_frequency_types);
    const loggedInUser = useSelector(x => x.auth?.loggedInUser);

//alert('entity: '+JSON.stringify(entity));
//    alert('all_users: '+JSON.stringify(all_users));
    // form validation rules
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Task Name is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, control, setValue } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const currentTimeObj = new Date(Date.now());
    const [dueDate, setDueDate] = useState(currentTimeObj);
    const [toggleMoreDetails, setToggleMoreDetails] = useState(false);
    const [openSaveConfirmationPopup, setOpenSaveConfirmationPopup] = useState(false);
    const [quickAddFlag, setQuickAddFlag] = useState(props.quickAddFlag | false);

    let defaultReminderTime = currentTimeObj;
    defaultReminderTime.setHours(0);
    defaultReminderTime.setMinutes(0);
    defaultReminderTime.setSeconds(0);
    const [reminderDateTime, setReminderDateTime] = useState(defaultReminderTime);

    const [redirectURL, setRedirectURL] = useState('');
    const [showButtonToolTip, setShowButtonToolTip] = useState(true);
    const [showRepeatFrequencyOption, setShowRepeatFrequencyOption] = useState(false); //Info 7=> Custom
    //const [saveAndCloseFlag, setSaveAndCloseFlag] = useState(false);
    let  saveAndCloseFlag = false;

    const [isRepeatOptionModified, setIsRepeatOptionModified] = useState(false);
    const [isDropdownOptionsLoaded, setIsDropdownOptionsLoaded] = useState(false);

    const [userDataFilter, setUserDataFilter] = useState({
        "userFilter": {
            "customerId": loggedInUser?.customer?.id
        }
    });
    const [userPageFilter, setUserPageFilter] = useState({
        "pageNumber": 1,
        "pageSize": 50,
        "sortField": "firstname",
        "sortDirection": "asc",
        "dataFilter": userDataFilter
    });

    let currentAPIFunctionTypeIdBasedOnSelection = 1;

    const CreateProjectPopupStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        // bgcolor: 'background.modal',
        bgcolor: '#ffffff',
        border: '2px solid #000000',
        boxShadow: 104,
        borderRadius: '10px'
    };

    const newEntity = {
        "name": "",
        "description": "",
        "status": {"id": 1},
        "priority": {"id": 1},
        "category": {"id": 8},
        "dueDate": dueDate,
        "reminderAt": reminderDateTime,
        "assignedTo": {"id": loggedInUser ? loggedInUser.id : loggedInUser?.id},
        "createdBy": {"id": loggedInUser ? loggedInUser.id : loggedInUser?.id},
        "createdAt": currentTimeObj.getTime(),
        "lastModifiedAt": currentTimeObj.getTime(),
        "repeatOption": {"id": 1},
        "repeatFrequencyType": {"id": 1,},
        "repeatFrequency": 1,
        "groupId": 0,
        "customList": {"id": 1,}
    };

// Version 1
//     const CustomToolTip = styled(({ className, ...props }) => (
//         <Tooltip {...props} componentsProps={{ tooltip: { className: className }, arrow: { className: className } }} />
//     ))(`
//     color: #6b6f71;
//     background-color: #ecf7fe
// `);

    useEffect(() => {
//        alert('Params Flag: '+(pageParams && Object.keys(pageParams).length>0));
        if(quickAddFlag || props || (pageParams && Object.keys(pageParams).length>0)) {
            if (id) {
                // fetch user details into redux state and
                // populate form fields with reset()
                dispatch(tasksActions.getById(id)).unwrap()
                    .then(entity => {
                        reset(entity);
                        //      setUserValue(user);
                    });
            } else {
                dispatch(tasksActions.getNewEntity(newEntity)).unwrap()
                    .then(entity => {
                        reset(entity);
                    });
                setRedirectURL(`/tasks/customized/due_date_status/fresh/search/*/status/-1/priority/-1/category/-1/custom_list/-1`);
            }
        // } else if(quickAddFlag && !id){
        //     dispatch(tasksActions.getNewEntity(newEntity)).unwrap()
        //         .then(entity => {
        //             reset(entity);
        //         });
        }
    }, [id, quickAddFlag]);

    useEffect(() => {
        setShowRepeatFrequencyOption((entity?.value?.repeatOption.id)===7);
        setToggleMoreDetails(false);
        setOpenSaveConfirmationPopup(false);
        setRedirectURL('');
        setShowButtonToolTip(true);
        setIsRepeatOptionModified(false);
    }, [entity]);

    useEffect(() => {
        if(!isDropdownOptionsLoaded) {
            dispatch(tasksActions.getAllTaskStatuses());
            dispatch(tasksActions.getAllTaskPriorities());
            dispatch(tasksActions.getAllTaskCategories());
            dispatch(tasksActions.getAllCustomLists());

            dispatch(tasksActions.getCustomizedUsers(userPageFilter));

            dispatch(tasksActions.getAllRepeatOptions());
            dispatch(tasksActions.getAllRepeatFrequencyTypes());
            setIsDropdownOptionsLoaded(true);
        }
    }, [isDropdownOptionsLoaded]);

    const handleStatusChange = (event) => {
        //setAge(event.target.value);
    };
    const handlePriorityChange = (event) => {
        //setAge(event.target.value);
    };
    const handleCategoryChange = (event) => {
        //setAge(event.target.value);
    };

    const handleCustomListChange = (event) => {
        //setAge(event.target.value);
    };

    const handleDueDateChange = (dateChange) => {
        setValue("dueDate", dateChange, {
            shouldDirty: true
        });
        setDueDate(dateChange);
    };

    const handleShowMoreDetails = () => {
        setToggleMoreDetails(!toggleMoreDetails);
    };

    const handleReminderDateChange = (dateTimeChange) => {
        setValue("reminderAt", dateTimeChange, {
            shouldDirty: true
        });
        setReminderDateTime(dateTimeChange);
    };

    async function saveAndStay(event) {
        //setSaveAndCloseFlag(false);
        saveAndCloseFlag = false;
        if (id && isRepeatOptionModified) {
            setOpenSaveConfirmationPopup(true);
        } else {
            setOpenSaveConfirmationPopup(false);
            await handleSaveThisTaskAlone();
        }

    }

    async function handleSaveAndClose(event) {
        //setSaveAndCloseFlag(true);
        saveAndCloseFlag = true;
        if (id && isRepeatOptionModified) {
            setOpenSaveConfirmationPopup(true);
        } else {
            setOpenSaveConfirmationPopup(false);
            await handleSaveThisTaskAlone();
        }
    }

    async function saveAndAddNew(event) {
        if(isRepeatOptionModified){
            setOpenSaveConfirmationPopup(true);
        }
        const taskForm = document
            .getElementById('taskForm');
//        await taskForm.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
//        event.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  //      history.navigate('/tasks/add');
    }

    async function closeAndCreateFollowup(event) {
        const taskForm = document
            .getElementById('taskForm');
        await taskForm.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
//        event.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        history.navigate('/tasks/add');
    }

    async function remindMeLater(event) {
        const taskForm = document
            .getElementById('taskForm');
        await taskForm.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
//        event.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        history.navigate('/tasks/add');
    }

    function handleRepeatOption(event) {
        setShowButtonToolTip(true);
        setShowRepeatFrequencyOption((event.target.value === 7));

        if(entity?.value?.repeatOption?.id!==1 && event.target.value===1) {
            setIsRepeatOptionModified(true);
        } else if(entity?.value?.repeatOption?.id!==1 && event.target.value!==1) {
            setIsRepeatOptionModified(true);
        } else if(entity?.value?.repeatOption?.id==1 && event.target.value!==1) {
            setIsRepeatOptionModified(true);
        } else if(entity?.value?.repeatOption?.id===1 && event.target.value===1){
            setIsRepeatOptionModified(false);
        } else {
            setIsRepeatOptionModified(false);
        }

    }

    function handleSaveConfirmationPopupClose(event) {
        setOpenSaveConfirmationPopup(false);
    }

    async function handleSaveThisTaskAlone() { // Tested
        currentAPIFunctionTypeIdBasedOnSelection = 1;
        const saveAndCloseFlagLocal =  saveAndCloseFlag;
        const taskForm = document
            .getElementById('taskForm');
        await taskForm.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        setOpenSaveConfirmationPopup(false);
        if(saveAndCloseFlagLocal) {
            handleWindowClose();
        }
    }

    async function handleSaveThisTaskAndReccurentTasks() {
        currentAPIFunctionTypeIdBasedOnSelection = 2;
        const saveAndCloseFlagLocal =  saveAndCloseFlag;
        const taskForm = document
            .getElementById('taskForm');
        await taskForm.dispatchEvent(new CustomEvent('submit', { cancelable: true, bubbles: true }));
        setOpenSaveConfirmationPopup(false);
        if(saveAndCloseFlagLocal) { //TODo: implement Save and Close
            handleWindowClose();
        }

    }

    function handleWindowClose() {
        if(isPageInPopupMode){
            dispatch(breadcrumbsActions.setSelectedTaskId(null));
            dispatch(breadcrumbsActions.setKeepModalOpen(false));
        } else {
            history.navigate(-1);
        }
    }

    function handleDoNothing() {
        setOpenSaveConfirmationPopup(false);
    }

    async function onSubmit(data, api_fn_type_id) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            const modifiedData = {
                ...data,
                lastModifiedAt: new Date(Date.now()).getTime()
            };
//            alert('modifiedData: '+JSON.stringify(modifiedData));
            let message;
            if (id) {
                if(currentAPIFunctionTypeIdBasedOnSelection === 1) {
                    await dispatch(tasksActions.update({id, data: modifiedData})).unwrap();
                    //TODO: Make use of return value. and display message based on return value
                    message = `Task has been updated`;
                } else {
                    await dispatch(tasksActions.updateUpcomingRecurrentTasks({id, data: modifiedData})).unwrap();
                    //TODO: Make use of return value. and display message based on return value
                    message = `Task and its upcoming recurrent tasks have been updated`;
                }

                if(!isPageInPopupMode) {
                    dispatch(tasksActions.getById(id)).unwrap()
                        .then(entity => {
                            reset(entity);
                        });
                }

            } else {
               const response = await dispatch(tasksActions.create({data: modifiedData})).unwrap();
                //dispatch(alertActions.error(response));
                //TODO: Make use of return value. and display message based on return value
                message = response;

                // if(redirectURL.length>0){
                //     dispatch(tasksActions.getNewEntity(newEntity)).unwrap()
                //         .then(entity => {
                //             reset(entity);
                //         });
                //     setRedirectURL(`/tasks/customized/due_date_status/fresh/search/*/status/-1/priority/-1/category/-1/custom_list/-1`);
                //     history.navigate(redirectURL);
                // }
                if(!isPageInPopupMode) {
                    dispatch(tasksActions.getNewEntity(newEntity)).unwrap()
                        .then(entity => {
                            reset(entity);
                        });
                }
            }

            dispatch(breadcrumbsActions.setModalModified(true)); //Info: For reloading Parent page

            dispatch(alertActions.success({ message, showAfterRedirect: true })); //Info: For showing Alert

        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <React.Fragment>
            <Stack direction="column" sx={{ width: '100%', justifyContent:'center', alignItems:'center'}} >
            {/*<Box*/}
            {/*    component="form"*/}
            {/*    noValidate*/}
            {/*    autoComplete="off"*/}
            {/*>*/}
                <Stack direction="column" >
                    <FormControl>
                        <Typography component="h2" variant="h6" sx={{mb: 2}}>
                            {id ? `Task` : `Add New Task`}
                        </Typography>
                    </FormControl>
                </Stack>
                <Stack direction="column">
                    {!(entity?.loading || entity?.error) &&
                    <form name="taskForm" id="taskForm" onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction="column">
                            <FormControl>
                                <TextField
                                    id="name"
                                    name="name"
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="Task Name"
                                    autoFocus={!isPageInPopupMode}
                                    placeholder={"Add Task here"}
                                    autoComplete={'off'}
                                    sx={{mb: 2}}
                                    InputLabelProps={{ shrink: true }}
                                    {...register('name', {required: true})}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name ? errors.name.message : ""}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="description"
                                    name="description"
                                    variant='outlined'
                                    color='primary'
                                    label="Description"
                                    placeholder={"Add Description here"}
                                    autoComplete={'off'}
                                    size="description"
                                    multiline
                                    rows={2}
                                    sx={{mb: 2}}
                                    InputLabelProps={{ shrink: true }}
                                    {...register('description')}
                                />
                            </FormControl>
                            <FormControl>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']} sx={{mb: 2}}>
                                        <DatePicker
                                             id="dueDate"
                                             name="dueDate"
                                             label="Due Date"
                                             format={"MMM DD, YYYY (ddd)"}
                                             defaultValue={dayjs(entity?.value.dueDate)}
                                             onChange={handleDueDateChange}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']} sx={{mb: 2}}>
                                        <DateTimePicker
                                            id="reminderAt"
                                            name="reminderAt"
                                            label="Reminder At"
                                            format={"MMM DD, YYYY (ddd) hh:mm A"}
                                            defaultValue={dayjs(entity?.value.reminderAt)}
                                            onChange={handleReminderDateChange}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            {!quickAddFlag &&
                                <Stack direction="column">
                                    {!(task_repeat_options?.loading || task_repeat_options?.error) &&
                                    <FormControl>
                                        <InputLabel id="select-status-label">Repeat</InputLabel>
                                        <Select
                                            labelId="select-task_repeat_options-label"
                                            id="task_repeat_options.id"
                                            name="task_repeat_options.id"
                                            label="Repeat"
                                            defaultValue={entity?.value?.repeatOption.id || 1}
                                            {...register('repeatOption.id')}
                                            sx={{mb: 2}}
                                            onMouseDown={()=>setShowButtonToolTip(!showButtonToolTip)}
                                            onChange={(e)=>handleRepeatOption(e)}
                                        >
                                            {task_repeat_options?.value?.map((repeat_option) => (
                                                <MenuItem key={repeat_option.id} value={repeat_option.id}>{repeat_option.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    }
                                    {(showRepeatFrequencyOption) && !(task_repeat_frequency_types?.loading || task_repeat_frequency_types?.error) &&
                                    <Stack direction="row" sx={{ width: '100%', justifyContent:'justify'}} >
                                        <FormControl>
                                            <InputLabel id="select-task_repeat_frequency-label">Repeat Frequency</InputLabel>
                                            <Select
                                                labelId="select-task_repeat_frequency-label"
                                                id="task_repeat_frequency.id"
                                                name="task_repeat_frequency.id"
                                                label="Repeat Frequency"
                                                size={'fifty'}
                                                defaultValue={entity?.value?.repeatFrequency || 1}
                                                {...register('repeatFrequency')}
                                                sx={{mb: 2, mr: 1}}
                                                onMouseDown={()=>setShowButtonToolTip(!showButtonToolTip)}
                                                onChange={()=>setShowButtonToolTip(true)}
                                            >
                                                {[1,2,3,4,5,6,7,8,9,10].map((repeatFrequency) => (
                                                    <MenuItem key={repeatFrequency} value={repeatFrequency}>{repeatFrequency}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel id="select-task_repeat_frequency_types-label">Frequency Type</InputLabel>
                                            <Select
                                                labelId="select-task_repeat_frequency_types-label"
                                                id="task_repeat_frequency_types.id"
                                                name="task_repeat_frequency_types.id"
                                                label="Repeat Frequency"
                                                size={'fifty'}
                                                defaultValue={entity?.value?.repeatFrequencyType.id || ""}
                                                {...register('repeatFrequencyType.id')}
                                                sx={{mb: 2}}
                                                onMouseDown={()=>setShowButtonToolTip(!showButtonToolTip)}
                                                onChange={()=>setShowButtonToolTip(true)}
                                            >
                                                {task_repeat_frequency_types?.value?.map((repeatFrequencyType) => (
                                                    <MenuItem key={repeatFrequencyType.id} value={repeatFrequencyType.id}>{repeatFrequencyType.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    }
                                    {!(task_statuses?.loading || task_statuses?.error) &&
                                    <FormControl>
                                        <InputLabel id="select-status-label">Status</InputLabel>
                                        <Select
                                        labelId="select-status-label"
                                        id="status.id"
                                        name="status.id"
                                        label="Status"
                                        defaultValue={entity?.value?.status.id || ""}
                                        {...register('status.id')}
                                        sx={{mb: 2}}
                                        onMouseDown={()=>setShowButtonToolTip(!showButtonToolTip)}
                                        onChange={()=>setShowButtonToolTip(true)}
                                        >
                                        {task_statuses?.value?.map((status) => (
                                            <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    }
                                    {!(task_priorities?.loading || task_priorities?.error) &&
                                    <FormControl>
                                        <InputLabel id="select-priority-label">Priority</InputLabel>
                                        <Select
                                            labelId="select-priority-label"
                                            id="priority.id"
                                            name="priority.id"
                                            defaultValue={entity?.value?.priority?.id || ""}
                                            label="Priority"
                                            {...register('priority.id')}
                                            sx={{mb: 2}}
                                            onMouseDown={()=>setShowButtonToolTip(!showButtonToolTip)}
                                            onChange={()=>setShowButtonToolTip(true)}
                                        >
                                            {task_priorities?.value?.map((priority) => (
                                                <MenuItem key={priority.id} value={priority.id}>{priority.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    }
                                    {!(task_categories?.loading || task_categories?.error) &&
                                    <FormControl>
                                        <InputLabel id="select-category-label">Category</InputLabel>
                                        <Select
                                            labelId="select-category-label"
                                            id="category.id"
                                            name="category.id"
                                            defaultValue={entity?.value?.category.id || ""}
                                            label="Category"
                                            {...register('category.id')}
                                            sx={{mb: 2}}
                                            onMouseDown={()=>setShowButtonToolTip(!showButtonToolTip)}
                                            onChange={()=>setShowButtonToolTip(true)}
                                        >
                                            {task_categories?.value?.map((category) => (
                                                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    }
                                    {!(custom_lists?.loading || custom_lists?.error) &&
                                    <FormControl>
                                        <InputLabel id="select-custom-list-label">Custom Lists</InputLabel>
                                        <Select
                                            labelId="select-custom-list-label"
                                            id="custom_list.id"
                                            name="custom_list.id"
                                            defaultValue={entity?.value?.customList?.id || ""}
                                            label="Custom Lists"
                                            {...register('customList.id')}
                                            sx={{mb: 2}}
                                            onMouseDown={()=>setShowButtonToolTip(!showButtonToolTip)}
                                            onChange={()=>setShowButtonToolTip(true)}
                                        >
                                            {custom_lists?.value?.map((custom_list) => (
                                                <MenuItem key={custom_list.id} value={custom_list.id}>{custom_list.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    }
                                    {!(all_users?.loading || all_users?.error) &&
                                    <FormControl>
                                        <InputLabel id="select-assigned-to-label">Assigned To</InputLabel>
                                        <Select
                                            labelId="select-assigned-to-label"
                                            id="assignedTo.id"
                                            name="assignedTo.id"
                                            defaultValue={entity?.value?.assignedTo?.id || ""}
                                            label="Assigned To"
                                            {...register('assignedTo.id')}
                                            sx={{mb: 2}}
                                            onMouseDown={()=>setShowButtonToolTip(!showButtonToolTip)}
                                            onChange={()=>setShowButtonToolTip(true)}
                                        >
                                            {all_users?.value?.list?.map((user) => (
                                                <MenuItem key={user.id} value={user.id}>{user.firstname} {user.lastname}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    }
                                </Stack>
                            }
                                <FormControl fullWidth>
                                    {/*version 2*/}
                                    <Stack direction="column">
                                        {(quickAddFlag==true) &&
                                        <Stack direction="row"
                                               sx={{justifyContent: 'space-between', alignItems: 'center', mt: 1, mb: 1}}>
                                            <Button fullWidth  variant="outlined" type="button"
                                                    onClick={(e) => setQuickAddFlag(!quickAddFlag)}>
                                                Show All Options <KeyboardDoubleArrowDownIcon />
                                            </Button>
                                        </Stack>
                                        }

                                        <Stack direction="row" sx={{ justifyContent:'space-between', alignItems: 'center', mt:2, mb: 4}}>
                                            <Button fullWidth variant="contained" color="primary" sx={{minWidth: '80px'}} type="button" onClick={(e)=>saveAndStay(e)} startIcon={<SaveOutlinedIcon/>}>Save</Button>
                                            {/*{isPageInPopupMode &&*/}
                                            {/*<Button fullWidth variant="contained" color="primary" sx={{minWidth: '130px', ml:1}} disabled={isRepeatOptionModified} type="button" onClick={(e) => handleSaveAndClose(e)} >Save & Close</Button>*/}
                                            {/*}*/}
                                            <Button fullWidth variant="contained" color="primary" sx={{minWidth: '80px', ml:1}} type="button" startIcon={<CancelPresentationIcon/>} onClick={(e)=> handleWindowClose(e)}>{isPageInPopupMode ? 'Close' :'Cancel'}</Button>
                                            {/*<Button variant="contained" color="primary" disabled={true} type="button" onClick={(e)=>saveAndAddNew(e)}><AddBoxOutlinedIcon /></Button>*/}
                                            {/*<Button variant="contained" color="primary" type="button" disabled={true} onClick={(e)=>closeAndCreateFollowup(e)}><AddTaskOutlinedIcon /></Button>*/}
                                            {/*<Button variant="contained" color="primary" type="button" disabled={true} onClick={(e)=>remindMeLater(e)}><SnoozeOutlinedIcon /></Button>*/}
                                        </Stack>
                                        {isPageInPopupMode &&
                                        <Stack direction="row" sx={{ justifyContent:'space-between', alignItems: 'center', mb: 4}}>
                                             {/*<Button fullWidth variant="contained" color="primary" type="button" sx={{ml:2}} onClick={(e) => handleSaveAndClose(e)} startIcon={<SaveOutlinedIcon/>} endIcon={<CancelPresentationIcon/>}/>*/}
                                            <Button fullWidth variant="contained" color="primary" sx={{minWidth: '130px', ml:1}} disabled={isRepeatOptionModified} type="button" onClick={(e) => handleSaveAndClose(e)} >Save & Close</Button>
                                        </Stack>
                                        }


                                        {id &&
                                        <Stack direction="row"
                                               sx={{justifyContent: 'space-between', alignItems: 'center', mt: 0, mb: 0}}>
                                            <Button fullWidth  variant="outlined" type="button"
                                                    onClick={(e) => handleShowMoreDetails(e)}>{toggleMoreDetails ?
                                                <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
                                            </Button>
                                        </Stack>
                                        }
                                    </Stack>


                                    {/*version 1*/}
                                    {/*<Stack direction="row" sx={{ justifyContent:'space-between', alignItems: 'center', mt:4, mb: 6}}>*/}
                                    {/*    /!*<IconButton aria-label="Save" color="primary" type="submit" sx={{backgroundColor: '#e8f8f5'}}><SaveIcon /></IconButton>*!/*/}
                                    {/*    <CustomToolTip title="Save" open={showButtonToolTip} placement="top" arrow>*/}
                                    {/*        <Button variant="contained" color="primary" type="button" onClick={(e)=>saveAndStay(e)}><SaveOutlinedIcon /></Button>*/}
                                    {/*    </CustomToolTip>*/}
                                    {/*    <CustomToolTip title="Save & Add New" open={showButtonToolTip} placement="bottom" arrow>*/}
                                    {/*        <Button variant="contained" color="primary" disabled={true} type="button" onClick={(e)=>saveAndAddNew(e)}><AddBoxOutlinedIcon /></Button>*/}
                                    {/*    </CustomToolTip>*/}
                                    {/*    <CustomToolTip title="Done & Follow up" open={showButtonToolTip} placement="top" arrow>*/}
                                    {/*        /!*<Button variant="contained" color="primary" type="button" disabled={id===undefined} onClick={(e)=>closeAndCreateFollowup(e)}><AddTaskOutlinedIcon /></Button>*!/*/}
                                    {/*        <Button variant="contained" color="primary" type="button" disabled={true} onClick={(e)=>closeAndCreateFollowup(e)}><AddTaskOutlinedIcon /></Button>*/}
                                    {/*    </CustomToolTip>*/}
                                    {/*    <CustomToolTip title="Remind after 3 days" open={showButtonToolTip} placement="bottom" arrow>*/}
                                    {/*        /!*<Button variant="contained" color="primary" type="button" disabled={id===undefined} onClick={(e)=>remindMeLater(e)}><SnoozeOutlinedIcon /></Button>*!/*/}
                                    {/*        <Button variant="contained" color="primary" type="button" disabled={true} onClick={(e)=>remindMeLater(e)}><SnoozeOutlinedIcon /></Button>*/}
                                    {/*    </CustomToolTip>*/}
                                    {/*    <CustomToolTip title="More details" open={showButtonToolTip} placement="top" arrow>*/}
                                    {/*        <Button variant="contained" color="primary" type="button" disabled={id===undefined}*/}
                                    {/*                sx={{backgroundColor: '#e8f8f5'}}*/}
                                    {/*                onClick={(e) => handleShowMoreDetails(e)}>{toggleMoreDetails ?*/}
                                    {/*            <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}*/}
                                    {/*        </Button>*/}
                                    {/*    </CustomToolTip>*/}
                                    {/*</Stack>                                */}

                                        {/*version 0*/}
                                        {/*<Stack direction="row" sx={{ justifyContent:'space-between', alignItems: 'center', mt:4, mb: 6}}>*/}
                                        {/*<IconButton aria-label="Save & Add New" color="primary" type="button" sx={{backgroundColor: '#e8f8f5'}} onClick={(e)=>saveAndAddNew(e)}><AddBoxIcon /></IconButton>*/}
                                        {/*<IconButton aria-label="Duplicate" color="primary" type="button" sx={{backgroundColor: '#e8f8f5'}} onClick={(e)=>saveAndAddNew(e)}><ContentCopyIcon /></IconButton>*/}
                                        {/*<IconButton aria-label="Follow up" color="primary" type="button" sx={{backgroundColor: '#e8f8f5'}} onClick={(e)=>saveAndAddNew(e)}><MoreTimeIcon /></IconButton>*/}
                                        {/*<Button variant="contained" color="primary" type="button" onClick={(e)=>saveAndAddNew(e)} sx={{ml:1}}>Save & Add New</Button>*/}
                                        {/*<Button variant="outlined" color="secondary" type="button" onClick={(e)=>handleShowMoreDetails()} sx={{ml:1, width:'120px'}}>{showMoreDetailsLabel}</Button>*/}
                                        {/*</Stack>*/}

                                </FormControl>
                                {id && toggleMoreDetails &&
                                    <Stack direction="column">
                                        {/*<Accordion>*/}
                                        {/*    <AccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
                                        {/*        <Typography>Additional Details</Typography>*/}
                                        {/*    </AccordionSummary>*/}
                                        {/*    <AccordionDetails>*/}
                                        {!(all_users?.loading || all_users?.error) &&
                                        <FormControl>
                                            <TextField
                                                id="createdBy.name"
                                                name="createdBy.name"
                                                type="text"
                                                variant='outlined'
                                                color='primary'
                                                label="Created By"
                                                disabled
                                                value={`${entity?.value?.createdBy?.firstname} ${entity?.value?.createdBy?.lastname}` || ""}
                                                sx={{mt: 3, mb: 2}}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        }
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']} sx={{mb: 2}}>
                                                <DateTimePicker
                                                    id="createdAt"
                                                    name="createdAt"
                                                    label="Created At"
                                                    disabled
                                                    format={"MMM DD, YYYY hh:mm:ss A"}
                                                    value={dayjs(entity?.value.createdAt)}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']} sx={{mb: 2}}>
                                                <DateTimePicker
                                                    id="lastModifiedAt"
                                                    name="lastModifiedAt"
                                                    label="Last Modified At"
                                                    disabled
                                                    format={"MMM DD, YYYY hh:mm:ss A"}
                                                    value={dayjs(entity?.value.lastModifiedAt)}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {/*    </AccordionDetails>*/}
                                        {/*</Accordion>*/}
                                        <TextField
                                            id="groupId"
                                            name="groupId"
                                            hidden
                                            autoFocus={false}
                                            {...register('groupId')}
                                        />
                                    </Stack>
                                }
                        </Stack>
                    </form>
                    }
                </Stack>
                <Stack>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openSaveConfirmationPopup}
                        onClose={handleSaveConfirmationPopupClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openSaveConfirmationPopup}>
                            <Box sx={CreateProjectPopupStyle}>
                                <Stack direction="column" spacing={2} sx={{width: '400px', height: '200px', justifyContent:'center', alignItems:'center'}}>
                                    <Stack direction="column" spacing={0} sx={{width: '300px'}}>
                                        <Button variant="contained" size="small" type="button" sx={{padding:3}} onClick={(e) => handleSaveThisTaskAlone()} >Update this task alone</Button>
                                        <Button variant="contained" size="small" type="button" sx={{padding:3}}  onClick={(e) => handleSaveThisTaskAndReccurentTasks()} >Update this task and its recurrent tasks</Button>
                                        <Button variant="contained" size="small" type="burron" sx={{padding:3}} onClick={(e) => handleDoNothing()} >Don't save anything</Button>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Fade>
                    </Modal>
                </Stack>
            {/*</Box>*/}
            </Stack>
        </React.Fragment>
    );
}
