import React from 'react';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';

export { ContactUs };

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    // margin: 'auto',
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    [theme.breakpoints.up('sm')]: {
        width: '600px'
    },
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

function ContactUs() {
    return (
        <Stack
            sx={{
                justifyContent: 'center',
//                height: '100dvh',
                p: 2,
                mt: 6
            }}
        >
            <Card variant="outlined">
                <Stack spacing={3} sx={{justifyContent: 'left', maxWidth: '600px', alignItems: 'left'}}>
                    <Stack spacing={2} sx={{justifyContent: 'left', alignItems: 'left', gap:2, mt:2, mb: 3, ml:2, mr: 2}}>
                        <Stack direction="column" spacing={3} sx={{justifyContent: 'left', alignItems: 'left', gap:4, mt:2}}>
                            <Typography component="h1" variant="h4" sx={{ width: '95%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}>
                                Contact Us:
                            </Typography>
                        </Stack>
                        <Stack direction="column" spacing={1} sx={{justifyContent: 'left', alignItems: 'left', gap:1, mt:1}}>
                            <Typography component="h1" variant="h4" sx={{ width: '95%', fontSize: 'clamp(1rem, 6vw, 1.15rem)' }}>
                                Ahamed Kabeer
                            </Typography>
                            <Typography component="h1" variant="h4" sx={{ width: '95%', fontSize: 'clamp(1rem, 6vw, 1.15rem)' }}>
                                Email: kabeer.nagercoil.dev@gmail.com
                            </Typography>
                            <Typography component="h1" variant="h4" sx={{ width: '95%', fontSize: 'clamp(1rem, 6vw, 1.15rem)' }}>
                                Phone: +91 7358960642
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    )
}