import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { alertActions } from '../_services/essentials/store';
import Stack from '@mui/material/Stack';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';

export { AppAlertBox };

function AppAlertBox() {
    const dispatch = useDispatch();
    const location = useLocation();
    const alert = useSelector(x => x.alert.value);

    useEffect(() => {
        // clear alert on location change
        dispatch(alertActions.clear());
    }, [location, dispatch]);

    const handleClose = (event) => {
        // if (reason === 'clickaway') {
        //     return;
        // }
        dispatch(alertActions.clear())
//        setOpen(false);
    };

    if (!alert) return null;
    return (
        <Snackbar
            sx={{ width: '90%' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alert ? true: false}
            autoHideDuration={5000}
            onClose={handleClose}
            message={alert.message}
        />
    );
}
