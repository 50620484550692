import React, { useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '../../../_services/essentials/helpers';
import {userActions, alertActions, breadcrumbsActions} from '../../../_services/essentials/store';
import Typography from "@mui/material/Typography";
import {Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import {notNull} from "@mui/x-charts/internals/notNull";
import {Authorization} from "../../../_components/Authorization";

export { AddEdit };

function AddEdit() {

    const entity_name = "user";
    const entity_label = "User";
    const entities_name = "users";
    const entities_label = "Users";

    const { id } = useParams();
    const dispatch = useDispatch();

    const entity = useSelector(x => x.users?.item);
    const statuses = useSelector(x => x.users?.statuses);
    const customers = useSelector(x => x.users?.customers);
    const account_roles = useSelector(x => x.users?.account_roles);
    const previousURL = useSelector(x => x.breadcrumbs?.previous_url);
    // form validation rules
    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required("Required*"),
        lastname: Yup.string()
            .required('Required*'),
        email: Yup.string().email("Please enter a valid Email")
            .required('Required*'),
        phone: Yup.string()
            .required('Required*'),
        password:  Yup.string().when('id', {
            is: (val) => {
                return !val;
            },
            then: (schema) => schema.required("Required*").min(6, 'Password must be at least 6 characters'),
            otherwise: (schema) => schema.notRequired(),
        }),
        confirmPassword: Yup.string().when('id', {
                is: (val) => {
                    return !val;
                },
                then: (schema) => schema.required("Required*")
                    .oneOf([Yup.ref('password'), null], 'Password and Confirm Password must match'),
                otherwise: (schema) => schema.notRequired(),
        }),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

     //alert('entity: '+ JSON.stringify(entity));
    // alert('statuses: '+ JSON.stringify(statuses));

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const accessTo = useSelector(x => x.auth.accessTo);

    useEffect(() => {
        // dispatch(breadcrumbsActions.setCurrentTitle(`Users`));
        if (id) {
            // fetch user details into redux state and
            // populate form fields with reset()
            dispatch(userActions.getById(id)).unwrap()
                .then(user => {
                    reset(user);
              //      setUserValue(user);
                });

        } else {
            const newEntity = {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                password: "",
                confirmPassword: "",
                customer:{
                    id: 1
                },
                accountStatus: {
                    id: 1
                },
                accountRoles: [
                    {
                        id: 4
                    }
                ]
            };
            dispatch(userActions.getNewEntity(newEntity)).unwrap()
                .then(user => {
                    reset(user);
                    //      setUserValue(user);
                });
            //setUserValue(newUser);
        }
    }, [id, dispatch, reset]);

    useEffect(() => {
        dispatch(userActions.getAllStatuses());
        dispatch(userActions.getAllCustomers());
        dispatch(userActions.getAllAccountRoles());
    }, [dispatch]);


    async function onSubmit(data) {
        dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            let message;
            if (id) {
                await dispatch(userActions.update({ id, data })).unwrap();
                //TODO: Make use of return value. and display message based on return value
                message = 'User account is updated now';
            } else {
                const response = await dispatch(userActions.register(data)).unwrap();
                dispatch(alertActions.error(response));
                //TODO: Make use of return value. and display message based on return value
                message = response;
                //message = 'User added';
            }

            // redirect to user list with success message
            history.navigate(-1);
            dispatch(alertActions.success({ message, showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        // <Authorization for={["Admin", "Super Admin"]}>
            <React.Fragment>
                <Stack
                    sx={{
                        minWidth: '900px',
                        justifyContent: 'space-between', flexGrow: '1', gap: 1
                    }}
                >
                    <Typography component="h2" variant="h6" sx={{mb: 2}}>
                        {id ? `${entity_label}` : `Add New ${entity_label}`}
                    </Typography>
                    {!(entity?.loading || entity?.error) &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction="column" size={{ xs: 12, sm: 12, md: 12, lg: 12 }} sx={{ flexGrow: '1', gap: 1}}>
                            <Stack direction="row" sx={{ flexGrow: '1', gap: 1}}>
                                <TextField
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="First name*"
                                    placeholder={""}
                                    autoComplete="off"
                                    fullWidth
                                    sx={{mb: 4}}
                                    inputlabelprops={{ shrink: true }}
                                    {...register('firstname', {required: true})}
                                    error={Boolean(errors.firstname)}
                                    helperText={errors.firstname ? errors.firstname.message : ""}
                                />
                                <TextField
                                    id="lastname"
                                    name="lastname"
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="Last name*"
                                    placeholder={""}
                                    autoComplete="off"
                                    fullWidth
                                    sx={{mb: 4}}
                                    inputlabelprops={{ shrink: true }}
                                    {...register('lastname', {required: true})}
                                    error={Boolean(errors.lastname)}
                                    helperText={errors.lastname ? errors.lastname.message : ""}
                                />
                            </Stack>
                            <Stack direction="row" sx={{ flexGrow: '1', gap: 1}}>
                                <TextField
                                    id="email"
                                    name="email"
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="Email*"
                                    placeholder={""}
                                    autoComplete="off"
                                    fullWidth
                                    sx={{mb: 4}}
                                    inputlabelprops={{ shrink: true }}
                                    {...register('email', {required: true})}
                                    error={Boolean(errors.email)}
                                    helperText={errors.email ? errors.email.message : ""}
                                />
                                <TextField
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="Phone*"
                                    placeholder={""}
                                    autoComplete="off"
                                    fullWidth
                                    sx={{mb: 4}}
                                    inputlabelprops={{ shrink: true }}
                                    {...register('phone', {required: true})}
                                    error={Boolean(errors.phone)}
                                    helperText={errors.phone ? errors.phone.message : ""}
                                />
                            </Stack>
                            <Stack direction="row" sx={{ flexGrow: '1', gap: 1}}>
                                {!(customers?.loading || customers?.error) &&
                                <FormControl fullWidth>
                                    <InputLabel id="select-customer.id-label">Customer*</InputLabel>
                                    <Select
                                        labelId="select-customer.id-label"
                                        id="customer.id"
                                        name="customer.id"
                                        label="Customer*"
                                        defaultValue={entity?.value?.customer?.id || ""}
                                        {...register('customer.id', {required: true})}
                                        inputlabelprops={{ shrink: true }}
                                        sx={{mb: 4, border: 0}}
                                    >
                                        {customers?.value?.map((customer) => (
                                            <MenuItem key={customer.id} value={customer.id}>{customer.organizationName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                }
                                {!(account_roles?.loading || account_roles?.error) &&
                                <FormControl fullWidth>
                                    <InputLabel id="select-role.id-label">Role*</InputLabel>
                                    <Select
                                        labelId="select-role.id-label"
                                        id="role.id"
                                        name="role.id"
                                        label="Role*"
                                        defaultValue={entity?.value?.accountRoles[0]?.id || ""}
                                        {...register('accountRoles.0.id')}
                                        sx={{mb: 4, border: 0}}
                                    >
                                        {account_roles?.value?.map((role) => (
                                            <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                }
                            </Stack>

                            {!id && accessTo.ADMIN_AND_ABOVE &&
                            <Stack direction="row" sx={{ flexGrow: '1', gap: 1}}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="password"
                                        name="password"
                                        type="password"
                                        variant='outlined'
                                        color='primary'
                                        label="Password*"
                                        placeholder={""}
                                        autoComplete="off"
                                        sx={{mb: 4}}
                                        inputlabelprops={{ shrink: true }}
                                        {...register('password', {required: true})}
                                        error={Boolean(errors.password)}
                                        helperText={errors.password ? errors.password.message : ""}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        variant='outlined'
                                        color='primary'
                                        label="Confirm Password*"
                                        placeholder={""}
                                        autoComplete="off"
                                        sx={{mb: 4}}
                                        inputlabelprops={{ shrink: true }}
                                        {...register('confirmPassword', {required: true})}
                                        error={Boolean(errors.confirmPassword)}
                                        helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                                    />
                                </FormControl>
                            </Stack>
                            }

                            <Stack direction="row" sx={{ flexGrow: '1', gap: 1}}>
                                {id && !(statuses?.loading || statuses?.error) &&
                                <FormControl fullWidth>
                                    <InputLabel id="select-status.id-label">Status*</InputLabel>
                                    <Select
                                        labelId="select-status.id-label"
                                        id="accountStatus.id"
                                        name="accountStatus.id"
                                        label="Status*"
                                        defaultValue={entity?.value?.accountStatus.id || ""}
                                        {...register('accountStatus.id')}
                                        sx={{mb: 4, border: 0}}
                                    >
                                        {statuses?.value?.map((status) => (
                                            <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                }
                            </Stack>
                        </Stack>
                        <Stack direction="column" size={{ xs: 12, sm: 12, lg: 12 }} sx={{ flexGrow: '1', gap: 1}}>
                        <FormControl fullWidth>
                            <Stack direction="row" spacing={2} sx={{mb: 0.5, mt:0.5}} >
                                <Button type={"submit"} variant="contained" color="primary" size="small" endIcon={<SaveIcon />}>
                                    Save
                                </Button>
                                <Button onClick={() => reset()} variant="outlined" color="secondary" size="small" endIcon={<SettingsBackupRestoreIcon />}>
                                    Reset
                                </Button>
                                <Button onClick={() => history.navigate(-1)} variant="outlined" color="secondary" size="small" startIcon={<CancelIcon />}>
                                    Cancel
                                </Button>
                            </Stack>
                        </FormControl>
                    </Stack>
                    </form>
                    }
                </Stack>
        </React.Fragment>
    // </Authorization>
    );
}
