import React, { useEffect, useState } from 'react';
//import Stack from "@mui/material/Stack";
//import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
//import { CardMedia } from "@mui/material";
import { history, uxscreen } from '_services/essentials/helpers';
//import Box from "@mui/material/Box";
import {useParams} from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import {Stack} from '@mui/material';
import amalImage from  "../../_assets/images/web/testimonials/amal.png";
import arafatImage from  "../../_assets/images/web/testimonials/arafat.png";
import fathimaImage from  "../../_assets/images/web/testimonials/fathima.png";
import taskTimelineImage from  "../../_assets/images/web/task_timeline.png";
import taskStatusCheckImage from  "../../_assets/images/web/task_status_check.png";
import dailyTaskListImage from  "../../_assets/images/web/daily_task_list.png";
import cistronLogoImage from  "../../_assets/images/web/cistron_logo.png";
import planyatFullTransLogoImage from  "../../_assets/images/web/planyat_full_trans_logo.png";
import responsiveDesignImage from  "../../_assets/images/web/responsive_design.png";
import  "../../_assets/css/web/imageStyle.css";

export { Home };

function Home() {


    const { pageName } = useParams();
    function handleMenuClick(url) {
        history.navigate(url, { state:{}, replace:false });
    }

    const isHomePage = !!!pageName;

    return (
        <div>
            {(isHomePage) &&
            <div className="banner_section layout_padding">
                <div className="container">
                    <div id="my_slider" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h1 className="banner_taital">Transform your Day with PlanyAT<br/></h1>
                                        <div className="contact_bt"><a href="#"><Button
                                            onClick={() => handleMenuClick('/account/register')}>Get
                                            Started</Button></a></div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="image_1"><img src={responsiveDesignImage} alt="" loading="lazy" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h1 className="banner_taital">Get<br/>Set<br/>Repeat<br/><br/><br/></h1>
                                        <div className="contact_bt"><a href="#"><Button
                                            onClick={() => handleMenuClick('/account/register')}>Get
                                            Started</Button></a></div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="image_1"><img src="/assets/images/img-1.png" alt=""/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h1 className="banner_taital">Boost Your Productivity<br/>Track Tasks<br/>Achieve
                                            Goals<br/><br/></h1>
                                        <div className="contact_bt"><a href="#"><Button
                                            onClick={() => handleMenuClick('/account/register')}>Get
                                            Started</Button></a></div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="image_1"><img src="/assets/images/img-1.png" alt=""/></div>
                                    </div>
                                </div>
                            </div>
                            {/*<a className="carousel-control-prev" href="#my_slider" role="button" data-slide="prev">*/}
                            {/*    <i className="fa fa-left"><img src="/assets/images/left-icon.png" alt=""/></i>*/}
                            {/*</a>*/}
                            {/*<a className="carousel-control-next" href="#my_slider" role="button" data-slide="next">*/}
                            {/*    <i className="fa fa-right"><img src="/assets/images/right-icon.png" alt=""/></i>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className="templet_bg">
                {(isHomePage || pageName === 'about') &&
                <div className="email_section layout_padding">
                    <div className="container">
                        <h1 className="solution_text">About Us</h1>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="image_2">
                                    <img
                                        src={cistronLogoImage}
                                        alt={`Cistron Software Development and Training`}
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <p className="sample_text"><b>Cistron Software Development and Training</b></p>
                                <p>Founded in 2025, Cistron is an innovative IT startup dedicated to delivering cutting-edge solutions in the software development industry. We specialize in three core services designed to help businesses grow and thrive in the digital world:</p>
                                    <p><b>1. Software Development</b>
                                        <br />&emsp;We offer comprehensive software development services tailored to meet the specific needs of your business. Whether you need a custom-built application, a mobile app, or a complex enterprise solution, our team of skilled developers ensures top-quality performance, scalability, and security.
                                    </p>
                                    <p><b>2. Software Services</b>
                                        <br />&emsp;At Cistron, we provide ongoing software services to enhance and maintain your systems. From software testing, updates, and maintenance to cloud integrations and optimization, we ensure that your software remains up-to-date, efficient, and aligned with your business goals.
                                    </p>
                                    <p><b>3. Software Training</b>
                                        <br />&emsp;To empower your team, we offer specialized software training programs. Our expert trainers will guide your staff through the ins and outs of various software tools and technologies, ensuring they gain the skills and knowledge necessary to succeed in today’s fast-paced digital environment.
                                    </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="image_2"><img src={planyatFullTransLogoImage} alt="" loading="lazy" /></div>
                            </div>
                            <div className="col-md-6">
                                <p className="sample_text"><b>PlanyAT</b></p>
                                <p>PlanyAT is an unique planner app created by "Cistron Software Development and Training". It is designed to help you efficiently manage your daily tasks. By segmenting your goals into achievable activities, our app helps increase productivity and reduces stress. Achieve more by breaking down large projects into manageable steps.</p>
                                {/*<div className="contact_bt"><a href="#">Read More</a></div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                }
                {(false) &&
                <div className="solution_section layout_padding">
                    <div className="container">
                        <h1 className="solution_text">Visualize your plans</h1>
                        <div className="image_3"><img src="/assets/images/img-3.png" alt=""/></div>
                        <p className="sample_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quisLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quisLorem ipsum dolor
                            sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </p>
                        <div className="make_bt"><a href="#">Make Now</a></div>
                    </div>
                </div>
                }
                {(isHomePage || pageName === 'services') &&
                <div className="template_section layout_padding">
                    <div className="container">
                        <h1 className="solution_text">Services</h1>
                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="cool_text img">
                                                <img  src={taskTimelineImage} alt={`Time Management`} loading="lazy" />
                                            </div>
                                            <h3 className="cool_text">Time Management</h3>
                                            <p className="lorem_dolor_text">Enhance your time management skills and
                                                productivity by organizing your tasks effectively..</p>

                                        </div>
                                        <div className="col-sm-4">
                                            <div className="cool_text img">
                                                <img src={taskStatusCheckImage} alt="Goal Setting" loading="lazy" />
                                            </div>
                                            <h3 className="cool_text">Goal Setting</h3>
                                            <p className="lorem_dolor_text">Set and segment your goals into achievable
                                                tasks, making it easier to complete long-term projects.</p>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="cool_text img">
                                                <img src={dailyTaskListImage} alt="Daily Task Lists" loading="lazy" />
                                            </div>
                                            <h3 className="cool_text">Daily Task Lists</h3>
                                            <p className="lorem_dolor_text">Keep track of your daily activities and
                                                ensure nothing falls through the cracks with our intuitive task
                                                lists.</p>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="carousel-item">*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-sm-4">*/}
                                {/*            <div className="image_5"><img src="/assets/images/img-5.png" alt=""/></div>*/}
                                {/*            <h3 className="cool_text">Free Hairstyle Website html Template</h3>*/}
                                {/*            <p className="lorem_dolor_text">Lorem ipsum dolor sit amet, consectetur*/}
                                {/*                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore*/}
                                {/*                magna aliqua.</p>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-sm-4">*/}
                                {/*            <div className="image_5"><img src="/assets/images/img-6.png" alt=""/></div>*/}
                                {/*            <h3 className="cool_text">Free Hairstyle Website html Template</h3>*/}
                                {/*            <p className="lorem_dolor_text">Lorem ipsum dolor sit amet, consectetur*/}
                                {/*                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore*/}
                                {/*                magna aliqua.</p>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-sm-4">*/}
                                {/*            <div className="image_5"><img src="/assets/images/img-7.png" alt=""/></div>*/}
                                {/*            <h3 className="cool_text">Free Hairstyle Website PSD Template</h3>*/}
                                {/*            <p className="lorem_dolor_text">Lorem ipsum dolor sit amet, consectetur*/}
                                {/*                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore*/}
                                {/*                magna aliqua.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="carousel-item">*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-sm-4">*/}
                                {/*            <div className="image_5"><img src="/assets/images/img-5.png" alt=""/></div>*/}
                                {/*            <h3 className="cool_text">Free Hairstyle Website html Template</h3>*/}
                                {/*            <p className="lorem_dolor_text">Lorem ipsum dolor sit amet, consectetur*/}
                                {/*                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore*/}
                                {/*                magna aliqua.</p>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-sm-4">*/}
                                {/*            <div className="image_5"><img src="/assets/images/img-6.png" alt=""/></div>*/}
                                {/*            <h3 className="cool_text">Free Hairstyle Website html Template</h3>*/}
                                {/*            <p className="lorem_dolor_text">Lorem ipsum dolor sit amet, consectetur*/}
                                {/*                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore*/}
                                {/*                magna aliqua.</p>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-sm-4">*/}
                                {/*            <div className="image_5"><img src="/assets/images/img-7.png" alt=""/></div>*/}
                                {/*            <h3 className="cool_text">Free Hairstyle Website PSD Template</h3>*/}
                                {/*            <p className="lorem_dolor_text">Lorem ipsum dolor sit amet, consectetur*/}
                                {/*                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore*/}
                                {/*                magna aliqua.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                }
                {(isHomePage || pageName === 'testimonials') &&
                <div className="design_section layout_padding" id="testimonials_page">
                    <div className="container">
                        <h1 className="solution_text">Testimonials</h1>
                        <Stack direction={"column"} spacing={2} padding={2} sx={{width: '100%', mt:3, borderRadius: '15px', border:1, borderColor: '#d7dbdd'}} alignItems='center'>
                            <div className="image_testimonials">
                                <Stack direction={"column"} sx={{width: '100%'}} alignItems='center'>
                                    <Avatar
                                        sizes="small"
                                        alt={`Arafat Ansari`}
                                        src={arafatImage}
                                        sx={{ width: 200, height: 200 }}
                                        href={"/"}
                                    />
                                </Stack>
                            </div>
                            <p className="sample_text">"PlanyAT App has revolutionized the way I organize my daily tasks. The list management, category identification, and reminders are incredibly helpful."</p>
                            <span className="testimonial_person"><b>Arafat Ansari</b><br />IT professional</span>
                        </Stack>
                        <Stack direction={"column"} spacing={2} padding={2} sx={{width: '100%', mt:3, borderRadius: '15px', border:1, borderColor: '#d7dbdd'}} alignItems='center'>
                            <div className="image_testimonials">
                                <Stack direction={"column"} sx={{width: '100%'}} alignItems='center'>
                                    <Avatar
                                        sizes="small"
                                        alt={`Amal Hussain`}
                                        src={amalImage}
                                        sx={{ width: 200, height: 200 }}
                                        href={"/"}
                                    />
                                </Stack>
                            </div>
                            <p className="sample_text">"PlanyAT app helps me track all the assignments and activities. It's Calendar feature helps to finish the assignments ahead of time"</p>
                            <span className="testimonial_person"><b>Amal Hussain</b><br />Student</span>
                        </Stack>
                        <Stack direction={"column"} spacing={2} padding={2} sx={{width: '100%', mt:3, borderRadius: '15px', border:1, borderColor: '#d7dbdd'}} alignItems='center'>
                            <div className="image_testimonials">
                                <Stack direction={"column"} sx={{width: '100%'}} alignItems='center'>
                                    <Avatar
                                        sizes="small"
                                        alt={`Fathima`}
                                        src={fathimaImage}
                                        sx={{ width: 200, height: 200 }}
                                        href={"/"}
                                    />
                                </Stack>
                            </div>
                            <p className="sample_text">"I love how PlanyAT helps me break down my big projects into smaller, more manageable tasks. It's a game-changer for my workflow!"</p>
                            <span className="testimonial_person"><b>Fathima</b><br />Freelance Writer</span>
                        </Stack>
                        {/*<div className="make_bt"><a href="#">Make Now</a></div>*/}
                    </div>
                </div>
                }
                {(isHomePage || pageName === 'request_demo') &&
                // <div className="contact_section layout_padding" id="contact_page">
                //     <div className="container">
                //         <h1 className="solution_text">Request Demo</h1>
                //         <div className="mail_section">
                //             <input type="" className="mail_text" placeholder="Name" name="Name"/>
                //             <input type="" className="mail_text" placeholder="Phone Number" name="Phone Number"/>
                //             <input type="" className="mail_text" placeholder="Email" name="Email"/>
                //             <textarea className="massage_text" placeholder="Message" rows="5" id="comment"
                //                       name="Message"></textarea>
                //             <div className="send_bt"><a href="#">send</a></div>
                //         </div>
                //     </div>
                // </div>
                <div className="contact_section layout_padding" id="contact_page">
                    <div className="container">
                        <h1 className="solution_text">Request Demo</h1>
                        <div className="row">
                            <p className="sample_text">Looking for a Demo on How PlanyAT can Improve our Daily Tasks? Call us on <b>+91 7358960642</b><br />at<br />Anytime between 08 AM and 08 PM</p>
                            <div className="send_bt"><a href={"callto:+917358960642"}>Click to Call</a></div>
                        </div>
                    </div>
                </div>
                }
                {(isHomePage || pageName === 'contact_us') &&
                <div className="footer_section layout_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div className="footer_logo"><a href="index.html"><img
                                    src="/assets/images/planyat_logo_mini.png" alt=""/></a></div>
                                <h4 className="contact_text">Contact Us</h4>
                                <h4>Cistron Software Development and Training</h4>
                                <div className="address_section">
                                    Address: Software Division, Zainab Suite, Velladichivilai, 1st Floor, Azad Garden 2, Nagercoil, TAMIL NADU, KANNIYAKUMARI , Pin 629002
                                </div>
                                <div className="call_section">
                                    <div className="call_section_left">
                                        <div className="map_icon"><img src="/assets/images/call-icon1.png" alt=""/> (Mon-Fri 08 AM - 08 PM)</div>
                                        <p className="location_text"><a href={"callto:+917358960642"}>+91 7358960642</a></p>
                                        <br /><p className="location_text"><a href={"callto:+919843527241"}> +91 9843527241</a></p>
                                    </div>
                                    {/*<div class="call_section_middle">-->*/}
                                    {/*    <div class="map_icon"><img src="/assets/images/map-icon.png" alt="" /></div>-->*/}
                                    {/*    <p class="location_text">India</p>-->*/}
                                    {/*</div>*/}
                                    <div className="call_section_right">
                                        <div className="map_icon"><img src="/assets/images/mail-icon1.png" alt=""/></div>
                                        <p className="location_text"><a href={"mailto:info@planyat.com"}>info@planyat.com</a></p>
                                        <br /><p className="location_text"><a href={"mailto:kabeer.nagercoil.dev@gmail.com"}>kabeer.nagercoil.dev@gmail.com</a></p>
                                    </div>
                                </div>
                                <div className="social_icon">
                                    <ul>
                                        <li><a href="https://www.facebook.com/profile.php?id=61574085503235"><img src="/assets/images/fb-icon.png" alt=""/></a></li>
                                        <li><a href="#"><img src="/assets/images/twitter-icon.png" alt=""/></a></li>
                                        <li><a href="https://www.linkedin.com/in/ahamed-kabeer-21352017/"><img src="/assets/images/linkedin-icon.png" alt=""/></a></li>
                                        <li><a href="#"><img src="/assets/images/instagram-icon.png" alt=""/></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="menus_main">
                                    <div className="menus_left">
                                        <h3 className="menus_text">Menu</h3>
                                        <div className="menu_text">
                                            <ul>
                                                <li><Button className="nav-link" onClick={()=>handleMenuClick('/', 1)}>HOME</Button></li>
                                                <li><Button className="nav-link" onClick={()=>handleMenuClick('/page/about', 2)}>ABOUT</Button></li>
                                                <li><Button className="nav-link" onClick={()=>handleMenuClick('/page/services', 3)}>SERVICES</Button></li>
                                                <li><Button className="nav-link" onClick={()=>handleMenuClick('/page/testimonials', 4)}>TESTIMONIALS</Button></li>
                                                <li><Button className="nav-link" onClick={()=>handleMenuClick('/page/contact_us', 5)}>CONTACT US</Button></li>
                                            </ul>

                                        </div>
                                    </div>
                                    {/*<div className="menus_middle">*/}
                                    {/*    <h3 className="Recent_text">Recent Post</h3>*/}
                                    {/*    <p className="staff_text">Participate in staff meetings manage dedicated to*/}
                                    {/*        marketing*/}
                                    {/*        November 25, 2019</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="menus_right">*/}
                                    {/*    <h1 className="Recent_text">Newsletter</h1>*/}
                                    {/*    <input type="text" className="Enter_text" placeholder="Enter your Emil"*/}
                                    {/*           name="Enter your Emil"/>*/}
                                    {/*    <div className="subscribe_bt"><a href="#">Subscribe</a></div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}
