import React, { useState } from 'react';import {Stack} from "@mui/material";
import {Link, NavLink} from 'react-router-dom';
import { MenuItem, Badge, IconButton } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import {history} from "../../_services/essentials/helpers";
import {breadcrumbsActions} from "../../_services/essentials/store";
import Button from "@mui/material/Button";
import planyATLogoMiniImage from "../../_assets/images/web/planyat_logo_mini.png"
import LoginIcon from '@mui/icons-material/Login';
import Typography from "@mui/material/Typography";

export { Nav };

function Nav() {

    const [toggleMenu, setToggleMenu] = useState(false);

    function handleMenuClick(url) {
        setToggleMenu(false);
        history.navigate(url);
    }

    return (
        <div className="header_section" id="home_page">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="logo"><a href="/"><img src={planyATLogoMiniImage} alt="PlanyAT App"  loading="lazy" /></a></div>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation" onClick={()=>setToggleMenu(!toggleMenu) } >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`${toggleMenu ? '': 'collapse navbar-collapse'}`} id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Button className="nav-link" onClick={()=>handleMenuClick('/')}>HOME</Button>
                        </li>
                        <li className="nav-item">
                            <Button className="nav-link" onClick={()=>handleMenuClick('/page/about')}>ABOUT</Button>
                        </li>
                        <li className="nav-item">
                            <Button className="nav-link" onClick={()=>handleMenuClick('/page/services')}>SERVICES</Button>
                        </li>
                        <li className="nav-item">
                            <Button className="nav-link" onClick={()=>handleMenuClick('/page/testimonials')}>TESTIMONIALS</Button>
                        </li>
                        <li className="nav-item">
                            <Button className="nav-link" onClick={()=>handleMenuClick('/page/contact_us')}>CONTACT US</Button>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <Button className="nav-link" onClick={()=>handleMenuClick('/account/login')}>LOGIN</Button>*/}
                        {/*</li>*/}
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <div className="search_icon"><a href="#"><img src="/assets/images/call-icon.png"
                                                                      alt=""/><span className="padding_left0">CALL US : +91 7358960642</span></a>
                        </div>
                        <div className="search_icon"><a href="#"><img src="/assets/images/mail-icon.png"
                                                                      alt=""/><span className="padding_left0">MAIL US : info@planyat.com</span></a>
                        </div>
                        {/*<div className="search_icon"><a href="#"><img src="/assets/images/search-icon.png"*/}
                        {/*                                              alt=""/></a></div>*/}
                            <div className="search_icon">
                                <Stack direction={"row"} >
                                    <Button aria-label="Login" color="primary" sx={{backgroundColor: '#e8f8f5'}} endIcon={<LoginIcon />} onClick={() => handleMenuClick('/account/login')}>Sign In</Button>
                                </Stack>
                            </div>

                    </form>
                </div>
            </nav>
        </div>
    );
}