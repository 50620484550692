import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, breadcrumbsActions, tasksActions as myActions} from "../../../_services/essentials/store";
import useScreenSize from "../../../_components/UserScreenSize";
import {useParams} from "react-router-dom";
import Pagination from '@mui/material/Pagination';
//import Search from '../../../_components/common/Search';

import moment from 'moment';
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Link from '@mui/material/Link';
import ViewHeadlineRoundedIcon from '@mui/icons-material/ViewHeadlineRounded';
import Copyright from '../../../_components/internals/components/Copyright';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {history, uxscreen} from "../../../_services/essentials/helpers";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import { Button, Stack, FormControl} from '@mui/material';
//import SearchIcon from '@mui/icons-material/Search';
import AddTaskIcon from '@mui/icons-material/AddTask';

function renderStatus(status) {
  const colors = {
    "Open":	"primary",
    "In Progress": "default",
    "Closed": "success",
    "On Hold": "default",
    "Blocked": "default",
    "Dropped": "default",
    "Archived": "default",
    "Follow up": "default",
  };
  return <Chip label={status.name} color={colors[status.name]} size="small" />;
}

function renderAvatar(params) {
  if (params.value == null) {
    return '';
  }

  return (
      <Avatar
          sx={{
            backgroundColor: params.value.color,
            width: '24px',
            height: '24px',
            fontSize: '0.85rem',
          }}
      >
        {params.value.name.toUpperCase().substring(0, 1)}
      </Avatar>
  );
}

export { List };

function List() {
  const listAPIResponse = useSelector(x => x.tasks?.listAPIResponse);
  const entities = listAPIResponse?.value?.list;
  const totalPages = listAPIResponse?.value?.totalPages;
  const totalItems = listAPIResponse?.value?.totalItems;

  const validationSchema = Yup.object().shape({
    // organizationName: Yup.string()
    //     .required('Organization Name is required'),
    // websiteURL: Yup.string()
    //     .required('websiteURL URL is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, control, setValue } = useForm(formOptions);

  const dispatch = useDispatch();
  const screenSize = useScreenSize();
  const loggedInUser = useSelector(x => x.auth.loggedInUser);

  const isModalModified = useSelector(x => x.breadcrumbs?.is_modal_modified);
  const currentPageTitle = useSelector(x => x.breadcrumbs?.current_title);

//  /tasks/customized/fresh/false/search/*/status/1/priority/-1/category/4/custom_list/-1' },

  const {
    keyword,
    status_id,
    status_group_id,
    priority_id,
    category_id,
    custom_list_id,
    due_date_status
  } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [taskFilter, setTaskFilter] = useState({
  });
  const [dataFilter, setDataFilter] = useState({
    "taskFilter": taskFilter
  });

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'dueDate',
      sort: 'asc',
    }
  ]);

  const [pageFilter, setPageFilter] = useState({
    "pageNumber": 1,
    "pageSize": 50,
    sortField: sortModel[0]?.field || 'dueDate',
    sortDirection: sortModel[0]?.sort || 'asc',
    "dataFilter": dataFilter
  });

  useEffect(() => {
    setTaskFilter({
      ...taskFilter,
      "dueDateStatus": due_date_status,
      "keyword": keyword,
      "statusId": status_id,
      "statusGroupId": status_group_id,
      "priorityId": priority_id,
      "categoryId": category_id,
      "customListId": custom_list_id,
      "userId": loggedInUser?.id
    });
  }, [due_date_status, keyword, status_id, status_group_id, priority_id, category_id, custom_list_id, loggedInUser ]);

  useEffect(() => {
    setDataFilter({
      "taskFilter": taskFilter
    });
  }, [taskFilter]);

  useEffect(() => {
    setPageFilter({
      ...pageFilter,
      "dataFilter": dataFilter
    })
  }, [dataFilter]);

  useEffect(() => {
    //dispatch(myActions.getAll());
    dispatch(myActions.getPaginatedList(pageFilter));
    setLoading(false);
  }, [pageFilter]);


  useEffect(() => {
    if(isModalModified?.value) {
      setPageFilter({
        ...pageFilter,
        "refresh": true,
      })
    }
  }, [isModalModified]);

  const columns = [
    { field: 'id',
      renderHeader: () => (<h6><strong>{'#'}</strong></h6>),
      flex: 0.2,
      minWidth: 20,
      renderCell: (params) =>
          <Typography sx={{fontSize: '14px', mt:0.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
    },
    {
      field: 'name',
      renderHeader: () => (<h6><strong>{'Task Name'}</strong></h6>),
      flex: 0.5,
      minWidth: 350,
      renderCell: (params) =>
          <Typography sx={{fontSize: '14px', mt:0.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value}</Typography>
    },
    {
      field: 'status',
      renderHeader: () => (<h6><strong>{'Status'}</strong></h6>),
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => renderStatus(params.value),
    },
    {
      field: 'priority',
      renderHeader: () => (<h6><strong>{'Priority'}</strong></h6>),
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) =>
          <Typography sx={{fontSize: '14px', mt:0.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value.name}</Typography>
    },
    {
      field: 'category',
      renderHeader: () => (<h6><strong>{'Category'}</strong></h6>),
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) =>
          <Typography sx={{fontSize: '14px', mt:0.5, cursor:'pointer'}} onClick={()=> showDetails(params.row.id)} >{params.value.name}</Typography>
    },
    {
      field: 'dueDate',
      renderHeader: () => (<h6><strong>{'Due Date'}</strong></h6>),
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      minWidth: 190,
      renderCell: (params) => {return moment(params.value).format('MMM DD, YYYY (ddd)')},
    },
    {
      field: 'reminderAt',
      renderHeader: () => (<h6><strong>{'Reminder Date'}</strong></h6>),
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => {return moment(params.value).format('MMM DD, YYYY h:mm A')},
    }
  ];

  function showDetails(selectedId) {
    //history.navigate(`/tasks/edit/${selectedId}`);
    dispatch(breadcrumbsActions.setSelectedTaskId(selectedId));
    dispatch(breadcrumbsActions.setKeepModalOpen(true));
    dispatch(breadcrumbsActions.setModalModified(false));
  }

  const updatePageFilter = (updates = {}) => {
    setPageFilter((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  function navigatePage(event, newPageNumber) {
    setLoading(false);
    setPageFilter({
      ...pageFilter, // copy the current properties of "json"
      "pageNumber": newPageNumber // update the "name" property
    });
  }

  async function onSubmit(data) {
    dispatch(alertActions.clear());
    try {
      // alert('data: '+ JSON.stringify(data));
      const newKeyword = data.new_keyword;

      const newTaskFilter = {
        ...taskFilter,
        keyword: newKeyword
      };
      const newDataFilter = {
        ...dataFilter,
        taskFilter: newTaskFilter
      };
      setPageFilter({
        ...pageFilter,
        dataFilter: newDataFilter
      });
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  }

  return (
      <Box sx={{ width: (screenSize.screen ==='web') ? uxscreen.size10 : '350px'}}>
        {(screenSize.screen ==='web') ? (
          <Stack
              direction="column"
              sx={{ justifyContent: 'space-between'}}
          >
            <Stack
                direction="row"
                sx={{ justifyContent: 'space-between', mb: 2 }}
            >
              <Stack direction="row">
                {/*<Typography component="h2" variant="h6">Tasks [</Typography><Typography component="h2" sx={{fontSize:'16px', color:'#e13814', fontWeight:'bold', ml:0.5, mr:0.5, mt:1}}>{totalItems}</Typography><Typography component="h2" variant="h6">]</Typography>*/}
                <Typography component="h2" variant="h6"> {currentPageTitle || 'Tasks'} [ {totalItems} ]</Typography>
              </Stack>
              <Stack
                  direction="row"
                  sx={{ justifyContent: 'space-between' }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl variant="outlined">
                    <OutlinedInput
                        size= {"small"}
                        id="search"
                        placeholder="Keyword"
                        sx={{ flexGrow: 1 }}
                        startAdornment={
                          <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                            <SearchRoundedIcon fontSize="small" />
                          </InputAdornment>
                        }
                        inputProps={{
                          'aria-label': 'keyword',
                        }}
                        {...register(`new_keyword`)}
                    />
                  </FormControl>
                  <Button variant="contained" size="small" type="submit">Search</Button>
                </form>
              </Stack>
              <Stack>
                <Button variant="contained" size="small" onClick={()=>history.navigate("/tasks/add")} endIcon={<AddTaskIcon />} >Add</Button>
              </Stack>
            </Stack>
            <Grid container spacing={1}>
              <Grid sx={{width: uxscreen.size10}}>
                <DataGrid
                  rowHeight={45}
                  // checkboxSelection
                  rows={entities}
                  columns={columns}
                  loading={loading}
                  disableColumnFilter
                  disableColumnSelector
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                  sortingMode="server"
                  sortingOrder={['asc', 'desc']}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    setLoading(true);
                    setSortModel(newSortModel);
                    updatePageFilter({
                      sortField: newSortModel[0]?.field,
                      sortDirection: newSortModel[0]?.sort,
                      pageNumber: 1
                    });


                  }}
                  initialState={{
                    // pagination: { paginationModel: { pageSize: 20 } },
                  }}
                  // pageSizeOptions={[10, 20, 50]}
                  hideFooterPagination={true}
                  disableColumnResize
                  density="compact"
                  sx={{
                    // flexGrow: 1,
                    minHeight: 500,
  //                  height: '100%',
                    // border: 1,
                    // '& .MuiDataGrid-topContainer': {
                    //   color: '#fff',
                    //   // border: 0
                    // },
                    '& .MuiDataGrid-row': {
                      borderBottom: 0
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      justifyContent: "center",
                      alignItems: "left"
                    },
                    '& .MuiDataGrid-row:hover .corner-value': {
                      visibility: 'visible',
                      opacity: 1,
                    },

                  }}
                  slotProps={{
                    filterPanel: {
                      filterFormProps: {
                        logicOperatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                        },
                        columnInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' },
                        },
                        operatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' },
                        },
                        valueInputProps: {
                          InputComponentProps: {
                            variant: 'outlined',
                            size: 'small',
                          },
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
          ):(
            <Stack
                direction="column"
                sx={{ justifyContent: 'space-between' }}
            >
              <Stack
                  direction="column"
                  sx={{ justifyContent: 'space-between', mb: 2 }}
              >
                <Stack direction="column" sx={{ justifyContent: 'space-between', alignItems: 'center',  mb: 2 }}>
                  {/*<Typography component="h2" variant="h6">Tasks [</Typography><Typography component="h2" sx={{fontSize:'16px', color:'#e13814', fontWeight:'bold', ml:0.5, mr:0.5, mt:1}}>{totalItems}</Typography><Typography component="h2" variant="h6">]</Typography>*/}
                  <Typography component="h2" variant="h6" sx={{fontWeight: 'bold'}}> {currentPageTitle || 'Tasks'} [ {totalItems} ]</Typography>
                </Stack>
                <Stack
                    direction="column"
                    sx={{ justifyContent: 'space-between', width:'100%', alignItems: 'center' }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl variant="outlined">
                      <OutlinedInput
                          size= {"small"}
                          id="search"
                          placeholder="Keyword"
                          sx={{ flexGrow: 1 }}
                          startAdornment={
                            <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                              <SearchRoundedIcon fontSize="small" />
                            </InputAdornment>
                          }
                          inputProps={{
                            'aria-label': 'keyword',
                          }}
                          {...register(`new_keyword`)}
                      />
                    </FormControl>
                    <Button variant="contained" size="small" type="submit" sx={{ml:1}} >Search</Button>
                  </form>
                  {/*<Stack>*/}
                  {/*  <Button variant="contained" size="small" onClick={()=>history.navigate("/tasks/add")} endIcon={<AddTaskIcon />} >Add</Button>*/}
                  {/*</Stack>*/}
                </Stack>
              </Stack>
              <Grid container>
                {entities?.map((task, index) => (
                  <Grid key={index} sx={{width: (screenSize.screen ==='web') ? uxscreen.size12 : '350px', mb:1}}>
                    <Card variant="outlined" sx={{ height: '100%', flexGrow: 1,
                      border: '1px solid black',
                      borderRadius: '8px',
                    }}>
                      <CardContent>
                        <Typography component="h2" variant="subtitle2" gutterBottom>
                          <Button
                              // variant="link"
                              color="primary"
                              startIcon={<ViewHeadlineRoundedIcon color="primary"/>}
                              // href={`/tasks/edit/${task.id}`}
                              onClick={()=>showDetails(task.id)}
                          />
                          {task.customList.name} - {task.priority.name} - {task.category.name}
                        </Typography>

                        <Stack
                            direction="column"
                            sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
                        >
                          <Stack sx={{ justifyContent: 'space-between' }}>
                            <Stack
                                direction="row"
                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                            >
                              <Typography variant="h6" component="p">
                                {task.name}
                              </Typography>
                              {renderStatus(task.status)}
                              {/*<Chip size="small" color={task.status.colorCode} label={task.status.name} />*/}
                            </Stack>
                            <Typography variant="caption" component="p">
                              {task.description.slice(0, 50) + (task.description.length > 50 ? "..." : "")}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'primary' }}>
                              Due Date: {moment(task.dueDate).format('MMM DD, YYYY (ddd)')}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'primary' }}>
                              Reminder At: {moment(task.reminderAt).format('MMM DD, YYYY (ddd) h:mm A')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          )}
        {(totalPages > 1)  &&
        <Pagination variant="outlined" color="primary" count={totalPages}  page={pageFilter.pageNumber} onChange={navigatePage}  sx={{mt: 2}} />
        }
      </Box>
  );
}
