import { useNavigate, useLocation } from 'react-router-dom';
import { history, uxscreen } from '_services/essentials/helpers';
import { AppLayout, WebLayout, AppAlertBox } from '_components';
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {breadcrumbsActions} from "./_services/essentials/store";
import Box from "@mui/material/Box";

export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    dispatch(breadcrumbsActions.setCurrentURL(history.location.pathname));

//    useEffect(()=>{
        // if(history.location.pathname='/account/login'){
        //     setMainButton({label: "Register", url: "/account/register"});
        // } else {
        //     setMainButton({label: "Login", url: "/account/login"});
        // }
        //setMainButton({label: "Login", url: "/account/login"});
//    });
    return (
        <Box>
        {auth && (history.location?.pathname!=='/') ? (
            <AppLayout />
            ) : (
            <WebLayout />
            )}
        </Box>
    );
}

