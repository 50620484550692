import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';

import {authActions} from '../../../_services/essentials/store';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import {styled} from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import {history, uxscreen} from "../../../_services/essentials/helpers";
import CancelIcon from "@mui/icons-material/Cancel";

export { ForgotPassword };

function ForgotPassword() {
    const dispatch = useDispatch();

    // form validation rules
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const Card = styled(MuiCard)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '100%',
        padding: theme.spacing(4),
        gap: theme.spacing(2),
        margin: 'auto',
        boxShadow:
            'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
        [theme.breakpoints.up('sm')]: {
            width: '600px'
        },
        ...theme.applyStyles('dark', {
            boxShadow:
                'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
        }),
    }));

    function onSubmit({email}) {
        dispatch(authActions.resetPassword({ email }));
    }

    return (
        <Stack
            sx={{
                justifyContent: 'space-between',
//                height: '100dvh',
                p: 2,
                mt: 6
            }}
        >
            <Card variant="outlined">
                {/*<SitemarkIcon />*/}
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{ fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                >
                    Reset Password
                </Typography>
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{ width: '100%', fontSize: '16px' }}
                >
                    Enter the email address associated with your account and we'll send you a link to reset your password.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}
                    >
                        <Stack direction={"column"}
                               sx={{
                                   justifyContent: 'space-between',
                               }}
                        >
                            <Stack direction={"column"}
                                   sx={{
                                       justifyContent: 'space-between',
                                       p: 2
                                   }}
                            >

                                <FormControl>
                                    <TextField
                                        id="email"
                                        type="text"
                                        name="email"
                                        placeholder="your@email.com"
                                        autoComplete="off"
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        sx={{ ariaLabel: 'email', mb:2 }}
                                        defaultValue={""}
                                        {...register('email', {required: true})}
                                        error={Boolean(errors.email)}
                                        helperText={errors.email ? errors.email.message : ""}
                                    />
                                </FormControl>

                                {/*<FormControlLabel*/}
                                {/*    control={<Checkbox value="remember" color="primary" />}*/}
                                {/*    label="Remember me"*/}
                                {/*/>*/}
                                {/*<ForgotPassword open={open} handleClose={handleClose} />*/}
                            </Stack>
                            <Stack direction={"row"}
                                sx={{
                                    justifyContent: 'space-between',
                                    p: 2
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                >
                                    Reset Password
                                </Button>
                                <Button onClick={() => history.navigate(-1)} variant="outlined" color="primary" size="small" startIcon={<CancelIcon />}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </form>
            </Card>
        </Stack>
    )
}