import React, { useState } from 'react';
import {Stack} from "@mui/material";
import {Link, NavLink} from 'react-router-dom';
import { MenuItem, Badge, IconButton } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import {history} from "../../_services/essentials/helpers";
import {breadcrumbsActions} from "../../_services/essentials/store";
import Button from "@mui/material/Button";

export { Footer };

function Footer() {

    const [toggleMenu, setToggleMenu] = useState(false);

    function handleMenuClick(url) {
        setToggleMenu(false);
        history.navigate(url);
    }

    return (
        <Stack
            direction={"column"}
            sx={{
            }}>
            <div className="copyright_section">
                {/*<div className="container">*/}
                <div className="copyright_text">Copyright © 2025 | CISTRON SOFTWARE DEVELOPMENT AND TRAINING | All Rights Reserved.
                    {/*<span className="teams_text">Terms of Service Privacy</span>*/}
                </div>
                {/*</div>*/}
            </div>
        </Stack>
    );
}