import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';

import MenuButton from './MenuButton';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../_services/essentials/store";

function SideMenuMobile({ open, toggleDrawer }) {
  const loggedInUser = useSelector(x => x.auth.loggedInUser);
  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: 'none',
//          backgroundColor: 'background.paper',
          background: `linear-gradient(to right,
                 rgba(255, 255, 255, 1),
                 #ffffff)`,
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: '70dvw',
          height: '100%',
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          <Stack
            direction="row"
            sx={{ gap: 1, alignItems: 'center', flexGrow: 1, p: 1 }}
          >
            <Avatar
              sizes="small"
              alt={`${loggedInUser?.firstname} ${loggedInUser?.lastname}`}
              src="/static/images/avatar/7.jpg"
              sx={{ width: 24, height: 24 }}
            />
            <Typography component="p" variant="h6">
              {loggedInUser?.firstname} {loggedInUser?.lastname}
            </Typography>
          </Stack>
          <MenuButton showBadge>
            <NotificationsRoundedIcon />
          </MenuButton>
        </Stack>
        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent toggleDrawer={toggleDrawer(false)}/>
          <Divider />
        </Stack>
        {/*<CardAlert />*/}
        <Stack sx={{ p: 2 }}>
          <Button variant="outlined"  onClick={() => {alert('Logout'); logout()}} fullWidth startIcon={<LogoutRoundedIcon />}>
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

SideMenuMobile.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
};

export default SideMenuMobile;
