import { Routes, Route } from 'react-router-dom';
import { Home as WebHome } from '../_pages/web';
import { PrivateRoute, NotAuthorized } from '../_components';
import { Dashboard, AboutUs, Home as LandingHome } from '../_pages/application/dashboard';
import { AccountLayout } from '../_pages/essentials/account';
import { UsersLayout } from '../_pages/application/users';
import { Layout as CustomerLayout } from '../_pages/application/customers';
import { Layout as TaskLayout } from '../_pages/application/tasks';
import React from "react";
import MainGrid from "./common/MainGrid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {uxscreen} from "../_services/essentials/helpers";
import {ContactUs} from "../_pages/web";

export { CustomRoute };

function CustomRoute() {
    return (
        <Stack
            sx={{
                alignItems: 'center',
                mt: 3,
                mb: 2
            }}
        >
            <Routes>
                {/* Private Routes */}
                <Route element={<PrivateRoute/>}>
                    <Route path="/home" element={<LandingHome/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/users/*" element={<UsersLayout/>}/>
                    <Route path="/customers/*" element={<CustomerLayout/>}/>
                    <Route path="/tasks/*" element={<TaskLayout/>}/>
                    {/*<Route path="/tasks/customized/due_date_status/:due_date_status/search/:keyword/status/:status_id/priority/:priority_id/category/:category_id/custom_list/:custom_list_id" element={<TaskLayout/>}/>*/}
                    <Route path="/error/not_authorized" element={<NotAuthorized/>}/>
                    <Route path="/about_us" element={<AboutUs/>}/>
                    <Route path="/reference_page" element={<MainGrid/>}/>

                </Route>

                {/* Public Routes */}
                <Route path="account/*" element={<AccountLayout/>}/>
                <Route path="/" element={<WebHome/>}/>
                <Route path="/page/:pageName" element={<WebHome/>}/>
                <Route path="/contact_us" element={<ContactUs/>}/>
            </Routes>
        </Stack>
    );
}

