import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import {useEffect} from "react";
import {useState} from "react";
import {history} from "../_services/essentials/helpers";
import {useLocation} from "react-router-dom";
import {AddEdit} from "../_pages/application/tasks";
import {breadcrumbsActions} from "../_services/essentials/store";
import {useDispatch} from "react-redux";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
//import Stack from '@mui/material/Stack';

export { ModalPlaceholder };

function ModalPlaceholder({ open, id }: ModalPlaceholderProps) {

    history.location = useLocation();
    const dispatch = useDispatch();
    const [ keepModalOpen, setKeepModalOpen ] = useState(open);
//    alert('keepModalOpen; '+JSON.stringify(keepModalOpen));
    function closePage(){
        setKeepModalOpen (false);
        dispatch(breadcrumbsActions.setSelectedTaskId(null));
        dispatch(breadcrumbsActions.setKeepModalOpen(false));
//        dispatch(authActions.logout());
    }

    // const outerStyle = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     height:'90%',
    //     transform: 'translate(-50%, -50%)',
    //     // width: 400,
    //     bgcolor: 'background.paper',
    //     //border: '2px solid #fff',
    //     borderRadius: 4,
    //     p: 1,
    // };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        height:'90%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
//        bgcolor: 'background.paper',
//        border: '2px solid #fff',
        //background: 'linear-gradient(to bottom, #FFD3A5 0%, #FD6585 100%)',
        background: 'linear-gradient(90deg, rgba(227,255,252,1) 0%, rgba(229,190,245,1) 51%, rgba(236,238,136,1) 100%)',
    borderRadius: 0,
        boxShadow: 24,
        p: 2,
        overflow:"auto",
        scrollbarWidth: '0.2px',
        '&::-webkit-scrollbar': {
            width: '0.2em',
        },
        '&::-webkit-scrollbar-track': {
//            background: '#ffffff',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#1ccdd5',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#1ccdd5',
        }
    };

    return (
        // <Box sx={outerStyle}>
        <Modal
            open={keepModalOpen?.value}
            onClose={()=>{console.log('')}}
        >
        {/* <Dialog*/}
        {/*    open={keepModalOpen}*/}
        {/*    onClose={()=>{console.log('')}}*/}
        {/*    PaperProps={{*/}
        {/*        component: 'Box',*/}
        {/*    }}*/}
        {/*>*/}
            {/*<DialogTitle><Button onClick={()=>closePage()}>Close</Button></DialogTitle>*/}
            {/*<DialogContent*/}
            {/*    sx={{ display: 'flex', flexDirection: 'column', gap: 0, width: '100%' }}*/}
            {/*>*/}
            {/*    <DialogContentText>*/}
            {/*<Box sx={outerStyle}>*/}
            <Box sx={style}>
                {(id === -1) &&
                    <AddEdit quickAddFlag={true}/>
                }
                {(id !== -1) &&
                    <AddEdit id={id}/>
                }
            </Box>
            {/*</Box>*/}
            {/*    </DialogContentText>*/}
            {/*</DialogContent>*/}
            {/*<DialogActions sx={{ pb: 3, px: 3 }}>*/}
            {/*    <Button variant="contained" type="submit">Continue</Button>*/}
            {/*</DialogActions>*/}
        {/*</Dialog>*/}
        </Modal>
        // </Box>
    );
}
