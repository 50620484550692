import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import { authActions } from '../../../_services/essentials/store';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Button} from "@mui/material";
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
// import AppTheme from '../shared-theme/AppTheme';
// import ColorModeSelect from '../../../_components/shared-theme/ColorModeSelect';
import { history } from '../../../_services/essentials/helpers';
import { userActions, alertActions } from '../../../_services/essentials/store';
import {UserAgreement} from './UserAgreement'


export { Register };

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    // margin: 'auto',
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    [theme.breakpoints.up('sm')]: {
        width: '600px'
    },
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

function Register() {
    const dispatch = useDispatch();

    // form validation rules
    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required('First Name is required'),
        lastname: Yup.string()
            .required('Last Name is required'),
        email: Yup.string().email("Please enter a valid Email")
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const [agreementSigned, setAgreementSigned] = useState(false);
    const [agreementPage, setAgreementPage] = React.useState(false);

    const handleClickAgreementPageOpen = () => {
        setAgreementPage(true);
    };

    const handleAgreementPageClose = () => {
        setAgreementPage(false);
    };

    async function onSubmit(data) {
        dispatch(alertActions.clear());

        try {
            const response = await dispatch(userActions.register(data)).unwrap();

            // redirect to login page and display success alert
            history.navigate('/account/login');
            dispatch(alertActions.success({ message: response, showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <Stack
            sx={{
                justifyContent: 'center',
//                height: '100dvh',
                p: 2,
                mt: 2
            }}
        >
            <Card variant="outlined">
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                >
                    Sign up
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
                    >
                        <FormControl>
                            <FormLabel htmlFor="firstname">First name*</FormLabel>
                            <TextField
                                id="firstname"
                                name="firstname"
                                type="text"
                                fullWidth
                                placeholder=""
                                autoComplete="off"
                                defaultValue={""}
                                {...register('firstname', {required: true})}
                                error={Boolean(errors.firstname)}
                                helperText={errors.firstname ? errors.firstname.message : ""}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="lastname">Last name*</FormLabel>
                            <TextField
                                id="lastname"
                                name="lastname"
                                type="text"
                                fullWidth
                                placeholder=""
                                autoComplete="off"
                                defaultValue={""}
                                {...register('lastname', {required: true})}
                                error={Boolean(errors.lastname)}
                                helperText={errors.lastname ? errors.lastname.message : ""}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="email">Email*</FormLabel>
                            <TextField
                                id="email"
                                name="email"
                                type="text"
                                fullWidth
                                placeholder=""
                                autoComplete="off"
                                variant="outlined"
                                defaultValue={""}
                                {...register('email', {required: true})}
                                error={Boolean(errors.email)}
                                helperText={errors.email ? errors.email.message : ""}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="password">Password*</FormLabel>
                            <TextField
                                fullWidth
                                id="password"
                                name="password"
                                type="password"
                                placeholder=""
                                autoComplete="off"
                                variant="outlined"
                                defaultValue={""}
                                {...register('password', {required: true})}
                                error={Boolean(errors.password)}
                                helperText={errors.password ? errors.password.message : ""}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={
                                <>
                                    <Checkbox value={"Agreemenmt"} checked={agreementSigned} color="primary" onClick={()=>setAgreementSigned(!agreementSigned)}/>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        sx={{ width: '100%', fontSize: '14px' }}
                                    >
                                        I agree to the <Link onClick={()=>handleClickAgreementPageOpen()} variant="body2" sx={{ alignSelf: 'center' }}> terms and conditions </Link>.
                                    </Typography>
                                </>
                            }
                        />
                        <UserAgreement open={agreementPage} handleAgreementPageClose={handleAgreementPageClose} setAgreementSigned={setAgreementSigned} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={!agreementSigned}
                        >
                            Sign up
                        </Button>
                        <Typography sx={{ textAlign: 'center' }}>
                            Already have an account?{' '}
                            <Link
                                to="/account/login"
                                variant="body2"
                                sx={{ alignSelf: 'center' }}
                            >
                                Sign in
                            </Link>
                        </Typography>
                    </Box>
                </form>
            </Card>
            {/*Following lines 'Empty <Stack></Stack>' is written for helping mobile user to use mobile keyboard without flikkering */}
            {/*<Stack*/}
            {/*    sx={{*/}
            {/*        justifyContent: 'center',*/}
            {/*        p: 2,*/}
            {/*        mt: 6,*/}
            {/*        height: '200px'*/}
            {/*    }}*/}
            {/*></Stack>*/}
        </Stack>
    )
}