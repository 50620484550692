import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import { authActions } from '../../../_services/essentials/store';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Button} from "@mui/material";
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
// import AppTheme from '../shared-theme/AppTheme';
// import ColorModeSelect from '../../../_components/shared-theme/ColorModeSelect';



export { Login };

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    // margin: 'auto',
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
        [theme.breakpoints.up('sm')]: {
            width: '600px'
        },
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

// const SignInContainer = styled(Stack)(({ theme }) => ({
//     padding: 20,
//     marginTop: '10vh',
//     '&::before': {
//         content: '""',
//         display: 'block',
//         position: 'absolute',
//         zIndex: -1,
//         inset: 0,
//         backgroundImage:
//             'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
//         backgroundRepeat: 'no-repeat',
//         ...theme.applyStyles('dark', {
//             backgroundImage:
//                 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
//         }),
//     },
// }));

// export default function SignIn(props: { disableCustomTheme?: boolean }) {
//     const [emailError, setEmailError] = React.useState(false);
//     const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
//     const [passwordError, setPasswordError] = React.useState(false);
//     const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
//     const [open, setOpen] = React.useState(false);
//
//     const handleClickOpen = () => {
//         setOpen(true);
//     };
//
//     const handleClose = () => {
//         setOpen(false);
//     };
//
//     const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
//         event.preventDefault();
//         const data = new FormData(event.currentTarget);
//         console.log({
//             email: data.get('email'),
//             password: data.get('password'),
//         });
//     };

    // const validateInputs = () => {
    //     const email = document.getElementById('email') as HTMLInputElement;
    //     const password = document.getElementById('password') as HTMLInputElement;
    //
    //     let isValid = true;
    //
    //     if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
    //         setEmailError(true);
    //         setEmailErrorMessage('Please enter a valid email address.');
    //         isValid = false;
    //     } else {
    //         setEmailError(false);
    //         setEmailErrorMessage('');
    //     }
    //
    //     if (!password.value || password.value.length < 6) {
    //         setPasswordError(true);
    //         setPasswordErrorMessage('Password must be at least 6 characters long.');
    //         isValid = false;
    //     } else {
    //         setPasswordError(false);
    //         setPasswordErrorMessage('');
    //     }
    //
    //     return isValid;
    // };


function Login() {
    const dispatch = useDispatch();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email("Please enter a valid Email"),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email, password }) {
        // alert*('test');
         return dispatch(authActions.login({ email, password }));
    }

    return (
        <Stack
            sx={{
                justifyContent: 'center',
//                height: '100dvh',
                p: 2,
                mt: 2
            }}
        >
            <Card variant="outlined">
                {/*<SitemarkIcon />*/}
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                >
                    Sign in
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <TextField
                                id="email"
                                type="text"
                                name="email"
                                placeholder="your@email.com"
                                autoComplete="off"
                                fullWidth
                                variant="outlined"
                                sx={{ ariaLabel: 'email' }}
                                defaultValue={""}
                                {...register('email', {required: true})}
                                error={Boolean(errors.email)}
                                helperText={errors.email ? errors.email.message : ""}
                            />
                        </FormControl>
                        <FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Link
                                    component="button"
                                    to={"/account/forgot_password"}
                                    variant="body2"
                                    sx={{ alignSelf: 'baseline' }}
                                >
                                    Forgot your password?
                                </Link>
                            </Box>
                            <TextField
                                name="password"
                                placeholder="••••••"
                                type="password"
                                id="password"
                                autoComplete="off"
                                fullWidth
                                variant="outlined"
                                {...register('password', {required: true})}
                                error={Boolean(errors.password)}
                                helperText={errors.password ? errors.password.message : ""}
                                sx={{mb:4}}
                            />
                        </FormControl>
                        {/*<FormControlLabel*/}
                        {/*    control={<Checkbox value="remember" color="primary" />}*/}
                        {/*    label="Remember me"*/}
                        {/*/>*/}
                        {/*<ForgotPassword open={open} handleClose={handleClose} />*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            Sign in
                        </Button>
                        <Typography sx={{ textAlign: 'center' }}>
                            Don&apos;t have an account?{' '}
                            <span>
                        <Link
                            to={"/account/register"}
                            variant="body2"
                            sx={{ alignSelf: 'center' }}
                        >
                          Sign up
                        </Link>
                      </span>
                        </Typography>
                    </Box>
                </form>
            </Card>
            {/*Following lines 'Empty <Stack></Stack>' is written for helping mobile user to use mobile keyboard without flikkering */}
            {/*<Stack*/}
            {/*    sx={{*/}
            {/*        justifyContent: 'center',*/}
            {/*        p: 2,*/}
            {/*        mt: 6,*/}
            {/*        height: '200px'*/}
            {/*    }}*/}
            {/*></Stack>*/}
        </Stack>
    )
}