import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiToolbar from '@mui/material/Toolbar';
import { tabsClasses } from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SideMenuMobile from './SideMenuMobile';
import MenuButton from './MenuButton';
// import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {Button, CardMedia, IconButton} from "@mui/material";
import {history} from '../../_services/essentials/helpers';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {breadcrumbsActions} from "../../_services/essentials/store";
import {useDispatch} from "react-redux";

const Toolbar = styled(MuiToolbar)({
  width: '100%',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  gap: '12px',
  flexShrink: 0,
  [`& ${tabsClasses.flexContainer}`]: {
    gap: '8px',
    p: '8px',
    pb: 0,
  },
});

export default function AppNavbar() {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    function addNewTask() {
        //history.navigate('/tasks/quick_add')
        dispatch(breadcrumbsActions.setSelectedTaskId(-1));
        dispatch(breadcrumbsActions.setKeepModalOpen(true));
        dispatch(breadcrumbsActions.setModalModified(false));
    }

  return (
    <AppBar
      position="fixed"
      sx={{
        display: { xs: 'auto', md: 'none' },
        boxShadow: 0,
        bgcolor: 'background.paper',
        backgroundImage: 'none',
        borderBottom: '1px solid',
        borderColor: 'divider',
        top: 'var(--template-frame-height, 0px)',
      }}
    >
      <Toolbar variant="regular">
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
          }}
        >
            <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
                <MenuButton aria-label="menu" onClick={(e)=>history.navigate(-1)}>
                    <KeyboardArrowLeftIcon />
                </MenuButton>
                <MenuButton aria-label="menu" onClick={(e)=>history.navigate(1)}>
                    <KeyboardArrowRightIcon />
                </MenuButton>
                <SideMenuMobile open={open} toggleDrawer={toggleDrawer} />
            </Stack>
            <Stack direction="row" spacing={1} sx={{ justifyContent: 'center',
            }}>
                {/*<CardMedia src={"../assets/images/planyat_logo_v2.png"} image={"../assets/images/planyat_logo_v2.png"} alt='PlanyAT Logo' sx={{ width: 350, height: 20 }} />*/}
                <Box
                    component="img"
                    sx={{
                        height: 40,
                    }}
                    alt="PlanyAT Logo"
                    src={'../assets/images/planyat_logo_mini.png'}
                />
                {/*<CustomIcon />*/}
                {/*<Typography sx={{ height: '25px', color: '#000064', fontSize: '25px' }}>*/}
                {/*    PlanyAT*/}
                {/*</Typography>*/}
                {/*<IconButton aria-label="Add New" SIZE={"small"} type="button" sx={{color: '#ff6400'}} onClick={(e)=>history.navigate('/tasks/quick_add')}><AddCircleOutlineOutlinedIcon /></IconButton>*/}
            </Stack>
            <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
                <MenuButton aria-label="menu" onClick={(e)=>addNewTask()}>
                    <AddIcon />
                </MenuButton>
                <MenuButton aria-label="menu" onClick={toggleDrawer(true)}>
                    <MenuRoundedIcon />
                </MenuButton>
                <SideMenuMobile open={open} toggleDrawer={toggleDrawer} />
            </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export function CustomIcon() {
  return (
    <Box
      sx={{
        width: '1.5rem',
        height: '1.5rem',
        bgcolor: 'black',
        borderRadius: '999px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        backgroundImage:
          'linear-gradient(135deg, hsl(210, 98%, 60%) 0%, hsl(210, 100%, 35%) 100%)',
        color: 'hsla(210, 100%, 95%, 0.9)',
        border: '1px solid',
        borderColor: 'hsl(210, 100%, 55%)',
        boxShadow: 'inset 0 2px 5px rgba(255, 255, 255, 0.3)',
      }}
    >
      <DashboardRoundedIcon color="inherit" sx={{ fontSize: '1rem' }} />
    </Box>
  );
}
