import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {useSelector} from "react-redux";

// create slice

const name = 'breadcrumbs';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const breadcrumbsActions = { ...slice.actions, ...extraActions };
export const breadcrumbsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        //value: JSON.parse(localStorage.getItem('auth'))
        url_list: [],
        url_list_length: 0,
        current_title: null,
        selected_menu_index: 0,
        previous_url: "/home",
        selected_task_id: null,
        keep_modal_open: false,
        is_modal_modified: false
    }
}

function createExtraActions() {

    return {
        setCurrentURL: setCurrentURL(),
        setCurrentTitle: setCurrentTitle(),
        setSelectedMenuIndex: setSelectedMenuIndex(),
        setSelectedTaskId: setSelectedTaskId(),
        setKeepModalOpen: setKeepModalOpen(),
        setModalModified: setModalModified(),
    };

    function setCurrentURL() {
        return createAsyncThunk(
            `${name}/setCurrentURL`,
            async function (currentURL) {
                return currentURL;
            }
        );
    }

    function setCurrentTitle() {
        return createAsyncThunk(
            `${name}/setCurrentTitle`,
            async function (title) {
                return title;
            }
        );
    }

    function setSelectedMenuIndex() {
        return createAsyncThunk(
            `${name}/setSelectedMenuIndex`,
            async function (selectedMenuIndex) {
                return selectedMenuIndex;
            }
        );
    }

    function setSelectedTaskId() {
        return createAsyncThunk(
            `${name}/setSelectedTaskId`,
            async function (selectedTaskId) {
                return selectedTaskId;
            }
        );
    }

    function setKeepModalOpen() {
        return createAsyncThunk(
            `${name}/setKeepModalOpen`,
            async function (keepModalOpen) {
                return keepModalOpen;
            }
        );
    }

    function setModalModified() {
        return createAsyncThunk(
            `${name}/setModalModified`,
            async function (isModalModified) {
                return isModalModified;
            }
        );
    }
}


function createExtraReducers() {
    return (builder) => {
        setCurrentURL();
        setCurrentTitle();
        setSelectedMenuIndex();
        setSelectedTaskId();
        setKeepModalOpen();
        setModalModified();

        function setCurrentURL() {
            var { pending, fulfilled, rejected } = extraActions.setCurrentURL;
            builder
                .addCase(pending, (state) => {
//                    state.url_list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.url_list_length = state.url_list.length;
                    if(state.url_list_length===0){
                        state.url_list.push(action.payload);
                    } else if(state.url_list[state.url_list_length-1] !== action.payload) {
                        state.url_list.push(action.payload);
                    }

                    state.previous_url = state.url_list.length>=2 ? state.url_list[state.url_list.length-2] : "/home";
                })
                .addCase(rejected, (state, action) => {
  //                  state.url_list = { error: action.error };
                });
        }

        function setCurrentTitle() {
            var { pending, fulfilled, rejected } = extraActions.setCurrentTitle;
            builder
                .addCase(pending, (state) => {
//                    state.current_title = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.current_title = action.payload;
                })
                .addCase(rejected, (state, action) => {
//                    state.current_title = { error: action.error };
                });
        }

        function setSelectedMenuIndex() {
            var { pending, fulfilled, rejected } = extraActions.setSelectedMenuIndex;
            builder
                .addCase(pending, (state) => {
//                  state.selected_menu_index = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.selected_menu_index = action.payload;
                })
                .addCase(rejected, (state, action) => {
//                    state.selected_menu_index = { error: action.error };
                });
        }

        function setSelectedTaskId() {
            var { pending, fulfilled, rejected } = extraActions.setSelectedTaskId;
            builder
                .addCase(pending, (state) => {
//                    state.selected_task_id = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.selected_task_id = action.payload;
                })
                .addCase(rejected, (state, action) => {
//                    state.selected_task_id = { error: action.error };
                });
        }

        function setKeepModalOpen() {
            var { pending, fulfilled, rejected } = extraActions.setKeepModalOpen;
            builder
                .addCase(pending, (state) => {
                    state.keep_modal_open = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.keep_modal_open = {value: action.payload};
                })
                .addCase(rejected, (state, action) => {
                    state.keep_modal_open = { error: action.error };
                });
        }

        function setModalModified() {
            var { pending, fulfilled, rejected } = extraActions.setModalModified;
            builder
                .addCase(pending, (state) => {
                    state.is_modal_modified = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.is_modal_modified = {value: action.payload};
                })
                .addCase(rejected, (state, action) => {
                    state.is_modal_modified = { error: action.error };
                });
        }

    };

}