import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { List, AddEdit, TaskCalendar } from './';


export { Layout };

function Layout() {
    return (
        <Routes>
            <Route index element={<List />} />
            <Route path="add" element={<AddEdit />} />
            <Route path="quick_add" element={<AddEdit quickAddFlag={true}/>} />
            <Route path="edit/:id" element={<AddEdit />} />
            <Route path="task_calendar" element={<TaskCalendar />} />
            {/*<Route path="/tasks/customized/status/:statusId" element={<List />} />*/}
            <Route path="customized/due_date_status/:due_date_status/search/:keyword/status_group/:status_group_id/priority/:priority_id/category/:category_id/custom_list/:custom_list_id" element={<List/>}/>
        </Routes>
    );
}
