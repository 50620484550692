import React, {useState, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
//import Box from '@mui/material/Box';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {useSelector, useDispatch} from "react-redux";
import {breadcrumbsActions} from "../../_services/essentials/store";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {history} from '../../_services/essentials/helpers';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
//    border: `1px solid ${theme.palette.divider}`,
    //backgroundColor: '#e6f0ff', //Here
    //backgroundColor: 'rgba(161,104,177,0)', //Here
    // background: `linear-gradient(to right,
    //              rgba(251, 108, 174, 0.2),
    //              #ffffff)`,
    // background: `linear-gradient(to right,
    //              rgba(255, 183, 0, 0.1),
    //              #ffffff)`,
    background: `linear-gradient(to right,
                 #dbf6f3,
                 #f6e2ff)`,

    // background: `linear-gradient(to right,
    //              rgba(251, 108, 174, 0.2),
    //              rgba(251, 108, 174, 0))`,
    border: 0,
    '&:not(:last-child)': {
        //borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',  //Here
            //outline: '10px solid slategrey' //Here
        }
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', height: '50px' }} />}
        {...props}
    />
))(({ theme }) => ({
    //backgroundColor: 'rgba(51, 204, 51,0.1)', //Here
  //  backgroundColor: 'rgba(255, 255, 255)',
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
        {
            transform: 'rotate(90deg)'
        },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(0.5)
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgb(19,24,50, 0.76)',
//        borderColor:  'rgb(144,149,177)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    //padding: theme.spacing(2),
    //padding: theme.spacing(0),
  //  borderTop: '1px solid rgba(0, 0, 0.1)',
    //backgroundColor: 'rgba(161,104,177, 0.07)',
    //backgroundColor: 'rgba(112, 219, 112, 0.1)',
    //backgroundColor: '#fedbd0'
//    backgroundColor: '#ffffff'  //Here
}));

export default function MenuContent({ toggleDrawer }) {

    const mainMenuItems = [
        { text: 'Dashboard', icon: <HomeRoundedIcon />, url:'/dashboard' },
        { text: 'Add New Task', icon: <AddBoxOutlinedIcon />, url:'/tasks/add' },
        { text: 'All Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks' },
        { text: 'Over Due Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/over/search/*/status_group/1/priority/-1/category/-1/custom_list/-1' },
    ];

    const  taskMenuItems= [
        { text: 'All Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks' },
        { text: 'All Open Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/all/search/*/status_group/1/priority/-1/category/-1/custom_list/-1' },
        { text: 'All Open fresh Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/fresh/search/*/status_group/1/priority/-1/category/-1/custom_list/-1' },
        { text: 'Office Open Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/all/search/*/status_group/1/priority/-1/category/4/custom_list/-1' },
        { text: 'Office Open Fresh Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/fresh/search/*/status_group/1/priority/-1/category/4/custom_list/-1' },
        { text: 'Over Due Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/over/search/*/status_group/1/priority/-1/category/-1/custom_list/-1' },
        { text: 'Upcoming Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/upcoming/search/*/status_group/1/priority/-1/category/-1/custom_list/-1' },
        { text: 'Recently Added Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/recently_added/search/*/status_group/-1/priority/-1/category/-1/custom_list/-1' },
        { text: 'Recently Updated Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/recently_updated/search/*/status_group/-1/priority/-1/category/-1/custom_list/-1' },
        { text: 'Recently Closed Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/recently_updated/search/*/status_group/2/priority/-1/category/-1/custom_list/-1' },
        // { text: 'Inbound Open Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/all/search/*/status_group/1/priority/-1/category/-1/custom_list/-1' },
        // { text: 'Outbound Open Tasks', icon: <AnalyticsRoundedIcon />, url:'/tasks/customized/due_date_status/all/search/*/status_group/1/priority/-1/category/-1/custom_list/-1' },
    ];

    const totalmainMenuItems = mainMenuItems.length;

    const calendarMenutems = [
        { text: 'Task Calendar', icon: <AnalyticsRoundedIcon />, url:'/tasks/task_calendar'},
    ];

    const adminMenutems = [
        { text: 'Customers', icon: <CorporateFareIcon />, url:'/customers' },
        { text: 'Users', icon: <PeopleRoundedIcon />, url:'/users' },
    ];

    const appHelpMenuItems = [
//        { text: 'Reference Page', icon: <InfoRoundedIcon />, url:'/reference_page' },
        { text: 'About Us', icon: <InfoRoundedIcon />, url:'/about_us' },
    ];

    const [menuArray, setMenuArray] = useState([
        {id: 0, name: "Main", expanded: true, visibility: 'USER_AND_ABOVE', data: mainMenuItems},
        {id: 1, name: "Task", expanded: false, visibility: 'USER_AND_ABOVE', data: taskMenuItems},
        {id: 2, name: "Calendar", expanded: false, visibility: 'USER_AND_ABOVE', data: calendarMenutems},
//        {id: 3, name: "List", expanded: false, visibility: 'USER_AND_ABOVE', data: []},
        {id: 4, name: "Administration", expanded: false, visibility: 'ADMIN_AND_ABOVE', data: adminMenutems},
        {id: 5, name: "Help", expanded: false, visibility: 'USER_AND_ABOVE', data: appHelpMenuItems}
    ]);

    const handleChange = (arrayItem, newExpanded)  => {
            setMenuArray(
                menuArray.map(menuItem => menuItem.id !== arrayItem.id ? {...menuItem, expanded: false} : {...arrayItem, expanded: newExpanded})
            )
        };

    const accessTo = useSelector(x => x.auth.accessTo);
    const selectedMenuIndex = useSelector(x => x.breadcrumbs?.selected_menu_index);
    const dispatch = useDispatch();

    function setSelectedMenuIndex(selectedMenuIndex) {
        dispatch(breadcrumbsActions.setSelectedMenuIndex(selectedMenuIndex));
    }

    function handleMenuClick(url, pageName, index) {
        setSelectedMenuIndex(index);
        if(toggleDrawer){
            toggleDrawer(false);
        }
        dispatch(breadcrumbsActions.setCurrentTitle(pageName));
        history.navigate(url, { state:{}, replace:false });
    }

    return (
        <Stack direction="column">
            <Stack sx={{ flexGrow: 1, p: 0, justifyContent: 'space-between',
              overflow:"auto",
              // scrollbarWidth: 'thin',
              // '&::-webkit-scrollbar': {
              //     width: '0.2em',
              // },
              // '&::-webkit-scrollbar-track': {
              //     background: "#f1f1f1",
              // },
              // '&::-webkit-scrollbar-thumb': {
              //     backgroundColor: '#888',
              // },
              // '&::-webkit-scrollbar-thumb:hover': {
              //     background: '#555'
              // }
             // backgroundColor: '#ffffff',
              scrollbarWidth: '0.5px',
              '&::-webkit-scrollbar': {
                  width: '0.2em',
              },
              '&::-webkit-scrollbar-track': {
                  background: '#ffffff',
              },
              '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#d5d5d5',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                  background: '#d5d5d5'
              }
            }}>
              {menuArray?.map((arrayItem, index) => (
                  (accessTo && accessTo[arrayItem.visibility]) &&
                  <Accordion key={`accordion-${arrayItem.id}`} expanded={arrayItem.expanded}
                             onChange={() => handleChange(arrayItem, !arrayItem.expanded)}
                             sx={{
                                 // backgroundColor: '#e6f0ff'
                                 padding: 0,
                             }}
                  >
                      <AccordionSummary key={`accordion-summary-${arrayItem.id}`} aria-controls={`menu-control-${index}`}
                                id={`menu-header-${index}`}
                                sx={{
                                   //backgroundColor: '#cce6ff'
                                }}
                      >
                          <Typography key={`accordion-heading-${arrayItem.id}`} sx={{height: '25px'}} component="span">
                              {arrayItem.name}
                          </Typography>
                      </AccordionSummary>
                      <AccordionDetails key={`accordion-details-${arrayItem.id}`}>
                          <List dense>
                              {arrayItem?.data?.map((item, index) => (
                                  <ListItem key={index} sx={{display: 'block'}}>
                                      {/*<ListItemButton href={item.url} selected={index === selectedMenuIndex}*/}
                                      {/*                onClick={() => setSelectedMenuIndex(index)}>*/}
                                      {/*    <ListItemIcon>{item.icon}</ListItemIcon>*/}
                                      {/*    <ListItemText primary={item.text}/>*/}
                                      {/*</ListItemButton>*/}
                                      <ListItemButton selected={index === selectedMenuIndex}
                                                      onClick={() => handleMenuClick(item.url, item.text, index)}>
                                          <ListItemIcon>{item.icon}</ListItemIcon>
                                          <ListItemText primary={item.text}/>
                                      </ListItemButton>
                                  </ListItem>
                              ))}
                          </List>
                      </AccordionDetails>
                  </Accordion>
              ))}
        </Stack>
        <Stack sx={{ flexGrow: 1, p: 0, justifyContent: 'space-between'}}>
        </Stack>

        {/* <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>*/}
        {/*   <List dense>*/}
        {/*     {mainMenuItems.map((item, index) => (*/}
        {/*      <ListItem key={index} disablePadding sx={{ display: 'block' }}>*/}
        {/*        <ListItemButton  href={item.url} selected={index === selectedMenuIndex} onClick={() => setSelectedMenuIndex(index)}>*/}
        {/*          <ListItemIcon>{item.icon}</ListItemIcon>*/}
        {/*          <ListItemText primary={item.text} />*/}
        {/*        </ListItemButton>*/}
        {/*      </ListItem>*/}
        {/*    ))}*/}
        {/*  </List>*/}
        {/*    {accessTo.ADMIN_AND_ABOVE ? (*/}
        {/*        <List dense>*/}
        {/*            {adminMenutems.map((item, index) => (*/}
        {/*                <ListItem key={index} disablePadding sx={{ display: 'block' }}>*/}
        {/*                    <ListItemButton  href={item.url} selected={(totalmainMenuItems+index) === selectedMenuIndex} onClick={() => setSelectedMenuIndex((totalmainMenuItems+index))}>*/}
        {/*                        <ListItemIcon>{item.icon}</ListItemIcon>*/}
        {/*                        <ListItemText primary={item.text} />*/}
        {/*                    </ListItemButton>*/}
        {/*                </ListItem>*/}
        {/*            ))}*/}
        {/*        </List>*/}
        {/*    ):(<></>)}*/}

        {/*</Stack>*/}
    </Stack>
  );
}
