import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SelectContent from './SelectContent';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import OptionsMenu from './OptionsMenu';
import {useSelector} from "react-redux";

const drawerWidth = 280;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu() {

    const loggedInUser = useSelector(x => x.auth.loggedInUser);

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          // backgroundColor: 'background.paper',
            //background: `linear-gradient(to right top,
            //rgba(51, 204, 51, 0.4),
            //#ffffff)`, //Here
            // background: `linear-gradient(to right top,
            //      rgba(112, 219, 112, 0.4),
            //      #ffffff)`,
            // background: `linear-gradient(to right top,
            //      rgba(78, 173, 254, 1),
            //      #ffffff)`,
            // background: `linear-gradient(to right,
            //      rgba(251, 108, 174, 0.5),
            //      rgba(251, 108, 174, 0.1))`,
            // background: `linear-gradient(to right,
            //              rgba(211, 227, 253, 0.2),
            //              #ffffff)`,
            // background: `linear-gradient(to right,
            //      rgba(255, 183, 0, 0.1),
            //      #ffffff)`,
            // background: `linear-gradient(to right,
            //      rgba(255, 183, 0, 0.2),
            //      #ffffff)`,
            background: `linear-gradient(to right,
                 #dbf6f3,
                 #f6e2ff)`,
        },
      }}
    >
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    display: 'flex',*/}
      {/*    mt: 'calc(var(--template-frame-height, 0px) + 4px)',*/}
      {/*    p: 1.5,*/}
      {/*  }}*/}
      {/*>*/}
        {/*<SelectContent />  //TODO: use this Feature for listing purpose  */}
      {/*</Box>*/}
        <Box sx={{
            height: 90,
            border: '1px solid #e6ffe6',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box
                component="img"
                sx={{
                    mt: 4,
                    mb: 4,
                    ml: 3.5,
                    mr: 0.5,
                    height: 40,
                }}
                alt="PlanyAT Logo"
                src={'../assets/images/planyat_logo_mini.png'}
            />
        </Box>
      <Divider />
      <MenuContent />
        <Stack direction="row" sx={{height: '100%'}}></Stack>{/*Following line height: '100%' is used to adjust the screen size based on the content*/}
      {/*<CardAlert />   //TODO: use this Feature for Alert purpose  */}
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Avatar
          sizes="small"
          alt={`${loggedInUser?.firstname} ${loggedInUser?.lastname}`}
          src="/static/images/avatar/7.jpg"
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ mr: 'auto' }}>
          <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
              {loggedInUser?.firstname} {loggedInUser?.lastname}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {loggedInUser?.email}
          </Typography>
        </Box>
        <OptionsMenu />
      </Stack>
    </Drawer>
  );
}
