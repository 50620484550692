import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '../_services/essentials/helpers';
//import { authActions } from '../_services/essentials/store';
//import {useEffect} from "react";

export { Authorization };

function Authorization(props) {
    const auth = useSelector(x => x.auth.value);
//    const loggedInUser = useSelector(x => x.auth.loggedInUser);
    const loggedInUserRoles = useSelector(x => x.auth.loggedInUser?.roles);
    const allowedRoles = props.for;

    // useEffect(() => {
    //     alert('allowedRoles: '+JSON.stringify(allowedRoles));
    //     alert(allowedRoles.includes("ADMIN"));
    //     alert('loggedInUserRoles: '+JSON.stringify(loggedInUserRoles));
    //     alert('Condition: '+loggedInUserRoles?.some(role => allowedRoles.includes(role.name)));
    // }, []);

    if(auth) {
        if (!loggedInUserRoles?.some(role => allowedRoles.includes(role.name))) {
            return <Navigate to="/error/not_authorized"/>
        } else {
            return (
                <React.Fragment>
                    {props.children}
                </React.Fragment>
            );
        }

    } else {
        return <Navigate to="/account/login" state={{ from: history.location }} />
    }
        return <Outlet/>;
}