import React from 'react';
import {AddEdit as CreateNewTask} from "../tasks";
import {Dashboard} from "./Dashboard";
export { Home };

function Home() {

    return (
         // <CreateNewTask quickAddFlag={true}/>
        <Dashboard />
    );
}
