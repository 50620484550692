import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {customAxios} from "../functions/custom_axios";

// create slice

const name = 'tasks';
const entity = 'task';
const entities = 'tasks';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const tasksActions = { ...slice.actions, ...extraActions };
export const tasksReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null,
        task_statuses: null,
        task_priorities: null,
        task_categories: null,
        custom_lists: null,
        all_users: null,
        task_repeat_options: null,
        task_repeat_frequency_types: null,
        listAPIResponse: null
    }
}

function createExtraActions() {

    return {
        getAll: getAll(),
        getById: getById(),
        getNewEntity: getNewEntity(),
        create: create(),
        update: update(),
        updateUpcomingRecurrentTasks: updateUpcomingRecurrentTasks(),
        delete: _delete(),
        getPaginatedList: getPaginatedList(),
        getAllTaskStatuses: getAllTaskStatuses(),
        getAllTaskPriorities: getAllTaskPriorities(),
        getAllTaskCategories: getAllTaskCategories(),
        getAllCustomLists: getAllCustomLists(),
        getAllUsers: getAllUsers(),
        getCustomizedUsers: getCustomizedUsers(),
        getAllRepeatOptions: getAllRepeatOptions(),
        getAllRepeatFrequencyTypes: getAllRepeatFrequencyTypes()
    };

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/${entities}`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async function (id, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/${entity}/${id}`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {}
                }
            }

        );
    }

    function getNewEntity() {
        return createAsyncThunk(
            `${name}/getNewEntity`,
            async function (entity) {
                //alert('entity: '+JSON.stringify(entity));
                return entity;
            }
        );
    }

    function create() {
        return createAsyncThunk(
            `${name}/create`,
            async function ({ data }, { getState, dispatch }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.post(`/api/${entity}`, data,  {headers});
                    const responseData = response.data;
                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async function ({ id, data }, { getState, dispatch }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    //alert('Input Data: '+JSON.stringify(data));
                    const response = await customAxios.put(`/api/${entity}/${id}`, data,  {headers});
                    const responseData = response.data;
                    //alert('Output Data: '+JSON.stringify(responseData));
                    return responseData;
                } catch (error) {
                    //alert('Error: '+ error);
                    return {}
                }
            }
        );
    }

    function updateUpcomingRecurrentTasks() {
        return createAsyncThunk(
            `${name}/updateUpcomingRecurrentTasks`,
            async function ({ id, data }, { getState, dispatch }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    //alert('Input Data: '+JSON.stringify(data));
                    const response = await customAxios.put(`/api/customized/recurrent_tasks/${id}`, data,  {headers});
                    const responseData = response.data;
                    //alert('Output Data: '+JSON.stringify(responseData));
                    return responseData;
                } catch (error) {
                    //alert('Error: '+ error);
                    return {}
                }
            }
        );
    }

    // prefixed with underscore because delete is a reserved word in javascript
    function _delete() {
        return createAsyncThunk(
            `${name}/delete`,
            async function (id, { getState, dispatch }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.delete(`/api/${entity}/${id}`, {headers});
                    const responseData = response.data;
                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

    function getPaginatedList() {
        return createAsyncThunk(
            `${name}/getPaginatedList`,
            async function (filter, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.post(`/api/customized/${entities}`, filter, {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {};
                }
            }
        );
    }

    function getAllTaskStatuses() {
        return createAsyncThunk(
            `${name}/getAllTaskStatuses`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/task_statuses`,  {headers});
                    // alert('response: '+JSON.stringify(response));
                    // const response = {
                    //     data: [
                    //         {id:1, name: "Open"},
                    //         {id:2, name: "In Progress"},
                    //         {id:3, name: "Closed"},
                    //         {id:4, name: "On Hold"},
                    //         {id:5, name: "Blocked"},
                    //         {id:6, name: "Dropped"},
                    //         {id:7, name: "Archived"},
                    //         {id:8, name: "Follow up"}
                    //     ]
                    // };
                    //const token = response.token;
                    const responseData = response.data;

                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    //alert(error);
                    return [];
                }
            }
        );
    }

    function getAllTaskPriorities() {
        return createAsyncThunk(
            `${name}/getAllTaskPriorities`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/task_priorities`,  {headers});
                    // const response = {
                    //     data: [
                    //         {id: 1, name: "Critical"},
                    //         {id: 2, name: "High"},
                    //         {id: 3, name: "Medium"},
                    //         {id: 4, name: "Low"},
                    //         {id: 5, name: "Trivial"}
                    //     ]
                    // };
                    //const token = response.token;
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getAllTaskCategories() {
        return createAsyncThunk(
            `${name}/getAllTaskCategories`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/task_categories`,  {headers});
                    // const response = {
                    //     data: [
                    //         {id:1, name: "Personal"},
                    //         {id:2, name: "Administrative Work"},
                    //         {id:3, name: "Home"},
                    //         {id:4, name: "Office works"},
                    //         {id:5, name: "Relative"},
                    //         {id:6, name: "Social works"},
                    //         {id:7, name: "Friends"},
                    //         {id:8, name: "General"}
                    //     ]
                    // };
                    //const token = response.token;
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getAllCustomLists() {
        return createAsyncThunk(
            `${name}/getAllCustomLists`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/custom_lists`,  {headers});
                    //const token = response.token;
                    // const response = {
                    //     data: [
                    //         {id:1, name: "Main"},
                    //         {id:2, name: "Home Making"},
                    //         {id:3, name: "Money"}
                    //     ]
                    // };
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getAllUsers() {
        return createAsyncThunk(
            `${name}/getAllUsers`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/users`,  {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getCustomizedUsers() {
        return createAsyncThunk(
            `${name}/getCustomizedUsers`,
            async function (filter, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`,
                    };
                    const response = await customAxios.post(`/api/customized/users`, filter, {headers});
                    //const token = response.token;
                    // alert('response: '+JSON.stringify(response));
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {};
                }
            }
        );
    }

    function getAllRepeatOptions() {
        return createAsyncThunk(
            `${name}/getAllRepeatOptions`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/task_repeat_options`,  {headers});
                    //const token = response.token;
                    // const response = {
                    //     data: [
                    //         {id:1, name: "None"},
                    //         {id:2, name: "Daily"},
                    //         {id:3, name: "Weekdays"},
                    //         {id:4, name: "Weekly"},
                    //         {id:5, name: "Monthly"},
                    //         {id:6, name: "Yearly"},
                    //         // {id:7, name: "Custom"},
                    //         {id:8, name: "Every 2 days"},
                    //         {id:9, name: "Bi-Weekly"},
                    //         {id:10, name: "Bi-Monthly"},
                    //         {id:11, name: "Quarterly"},
                    //         {id:12, name: "Half Yearly"},
                    //         {id:13, name: "Bi-Annual"}
                    //     ]
                    // };
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

    function getAllRepeatFrequencyTypes() {
        return createAsyncThunk(
            `${name}/getAllRepeatFrequencyTypes`,
            async function (args, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    //const response = await customAxios.get(`/api/custom_lists`,  {headers});
                    //const token = response.token;
                    const response = {
                        data: [
                            {id:1, name: "Day(s)"},
                            {id:2, name: "Week(s)"},
                            {id:3, name: "Month(s)"},
                            {id:4, name: "Year(s)"}
                        ]
                    };
                    const responseData = response.data;
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return [];
                }
            }
        );
    }

}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
        getNewEntity();
//        update();
        _delete();
        getPaginatedList();
        getAllTaskStatuses();
        getAllTaskPriorities();
        getAllTaskCategories();
        getAllCustomLists();
        getAllUsers();
        getCustomizedUsers();
        getAllRepeatOptions();
        getAllRepeatFrequencyTypes();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        function getNewEntity() {
            var { pending, fulfilled, rejected } = extraActions.getNewEntity;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        // function update() {
        //     var { pending, fulfilled, rejected } = extraActions.update;
        //     builder
        //         .addCase(pending, (state) => {
        //             state.item = { loading: true };
        //         })
        //         .addCase(fulfilled, (state, action) => {
        //             //state.item = { value: action.payload };
        //         })
        //         .addCase(rejected, (state, action) => {
        //             state.item = { error: action.error };
        //         });
        // }

        function _delete() {
            var { pending, fulfilled, rejected } = extraActions.delete;
            builder
                .addCase(pending, (state, action) => {
                    const user = state.list.value.find(x => x.id === action.meta.arg);
                    user.isDeleting = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.list.value = state.list.value.filter(x => x.id !== action.meta.arg);
                })
                .addCase(rejected, (state, action) => {
                    const user = state.list.value.find(x => x.id === action.meta.arg);
                    user.isDeleting = false;
                });
        }

        function getPaginatedList() {
            var { pending, fulfilled, rejected } = extraActions.getPaginatedList;
            builder
                .addCase(pending, (state) => {
                    state.listAPIResponse = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.listAPIResponse = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.listAPIResponse = { error: action.error };
                });
        }

        function getAllTaskStatuses() {
            var { pending, fulfilled, rejected } = extraActions.getAllTaskStatuses;
            builder
                .addCase(pending, (state) => {
                    state.task_statuses = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.task_statuses = { value: action.payload };
                    //alert('Payload: '+JSON.stringify(action.payload));
                })
                .addCase(rejected, (state, action) => {
                    state.task_statuses = { error: action.error };
                });
        }

        function getAllTaskPriorities() {
            var { pending, fulfilled, rejected } = extraActions.getAllTaskPriorities;
            builder
                .addCase(pending, (state) => {
                    state.task_priorities = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.task_priorities = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.task_priorities = { error: action.error };
                });
        }

        function getAllTaskCategories() {
            var { pending, fulfilled, rejected } = extraActions.getAllTaskCategories;
            builder
                .addCase(pending, (state) => {
                    state.task_categories = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.task_categories = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.task_categories = { error: action.error };
                });
        }

        function getAllCustomLists() {
            var { pending, fulfilled, rejected } = extraActions.getAllCustomLists;
            builder
                .addCase(pending, (state) => {
                    state.custom_lists = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.custom_lists = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.custom_lists = { error: action.error };
                });
        }

        function getAllUsers() {
            var { pending, fulfilled, rejected } = extraActions.getAllUsers;
            builder
                .addCase(pending, (state) => {
                    state.all_users = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.all_users = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.all_users = { error: action.error };
                });
        }

        function getCustomizedUsers() {
            var { pending, fulfilled, rejected } = extraActions.getCustomizedUsers;
            builder
                .addCase(pending, (state) => {
                    state.all_users = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.all_users = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.all_users = { error: action.error };
                });
        }

        function getAllRepeatOptions() {
            var { pending, fulfilled, rejected } = extraActions.getAllRepeatOptions;
            builder
                .addCase(pending, (state) => {
                    state.task_repeat_options = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.task_repeat_options = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.task_repeat_options = { error: action.error };
                });
        }

        function getAllRepeatFrequencyTypes() {
            var { pending, fulfilled, rejected } = extraActions.getAllRepeatFrequencyTypes;
            builder
                .addCase(pending, (state) => {
                    state.task_repeat_frequency_types = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.task_repeat_frequency_types = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.task_repeat_frequency_types = { error: action.error };
                });
        }

    };
}
