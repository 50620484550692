import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {customAxios} from "../functions/custom_axios";

// create slice

const name = 'dashboard';
//const entity = 'dashboard';
//const entities = 'dashboard';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const dashboardActions = { ...slice.actions, ...extraActions };
export const dashboardReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        filter: {
            specialities: null,
            countries: null,
            search_terms: null
        }
    }
}

function createExtraActions() {

    return {
        getCurrentMonthTaskData:getCurrentMonthTaskData(),
        getTasksPerMonthData: getTasksPerMonthData(),
        getOverDueMetricsData: getOverDueMetricsData()
    };

    function getCurrentMonthTaskData() {
        return createAsyncThunk(
            `${name}/getCurrentMonthTaskData(`,
            async function ({userId, chart_limit}, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/chart_data/task/status_chart/${userId}/${chart_limit}`, {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

    function getTasksPerMonthData() {
        return createAsyncThunk(
            `${name}/getTasksPerMonthData(`,
            async function ({userId, chart_limit}, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/chart_data/task/tasks_per_month/${userId}/${chart_limit}`, {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

    function getOverDueMetricsData() {
        return createAsyncThunk(
            `${name}/getOverDueMetricsData(`,
            async function ({userId}, { getState }) {
                try {
                    const token = getState().auth.value;
                    const headers = {
                        "Authorization": `Bearer ${token}`
                    };
                    const response = await customAxios.get(`/api/word_cloud/task/over_due_metrics/${userId}`, {headers});
                    //const token = response.token;
                    const responseData = response.data;
                    //console.log('responseData: '+ JSON.stringify(responseData));
                    // set auth user in redux state
                    return responseData;
                } catch (error) {
                    return {}
                }
            }
        );
    }

}

function createExtraReducers() {
    return (builder) => {
        // getAllSearchTerms();

        // function getAllSearchTerms() {
        //     var { pending, fulfilled, rejected } = extraActions.getAllSearchTerms;
        //     builder
        //         .addCase(pending, (state) => {
        //             state.filter = {
        //                 ...state.filter,
        //                 search_terms: { loading: true }
        //             };
        //         })
        //         .addCase(fulfilled, (state, action) => {
        //             state.filter = {
        //                 ...state.filter,
        //                 search_terms: { value: action.payload }
        //             };
        //         })
        //         .addCase(rejected, (state, action) => {
        //             state.filter = {
        //                 ...state.filter,
        //                 search_terms: { error: action.error }
        //             };
        //         });
        // }

    };
}
