import React from 'react';
import Typography from "@mui/material/Typography";
import {breadcrumbsActions} from "../../../_services/essentials/store";
import {useDispatch} from "react-redux";

export { AboutUs };

function AboutUs() {

    const dispatch = useDispatch();
    return (
        <div>
            <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                This app is used to remeber the work we do daily.
            </Typography>
            <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                - By Amal (CEO of A-Cube Group of companies)
            </Typography>
        </div>
    );
}
