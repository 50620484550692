//TODO: Implement scheduler timelime gannt chart https://codesandbox.io/p/sandbox/schedulerapp-musdg
import React, {useEffect, useState} from "react";
import Scheduler from "react-mui-scheduler";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {breadcrumbsActions, tasksActions as myActions} from "../../../_services/essentials/store";
import dayjs from 'dayjs';
import {StatusColors} from "../../../_services/essentials/constants";
import useScreenSize from "../../../_components/UserScreenSize";
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export { TaskCalendar };

function TaskCalendar() {
    const screenSize = useScreenSize();
    const [state] = useState({
        options: {
            //transitionMode: "zoom", // or fade
            transitionMode: "fade", // or fade
            startWeekOn: "Mon", // or Sun
            defaultMode: (screenSize.screen ==='web') ? "month" : "timeline", // or week | day | timeline
            //defaultMode: "day", // or week | day | timeline
            minWidth: 400,
            maxWidth: 540,
            minHeight: 540,
            maxHeight: 540
        },
        alertProps: { //Info: This will show notification banner on the top of the calendar
            open: false,
            color: "info", // info | success | warning | error
            severity: "info", // info | success | warning | error
            message: "🚀 Let's start with awesome react-mui-scheduler 🔥 🔥 🔥",
            showActionButton: true,
            showNotification: true,
            delay: 1500
        },
        toolbarProps: {
            showSearchBar: false,
            showSwitchModeButtons: true,
            showDatePicker: true
        }
    });

    const {
        fresh_flag,
        keyword,
        status_id,
        priority_id,
        category_id,
        custom_list_id,
        due_date_status
    } = useParams();

    // const listAPIResponse = useSelector(x => x.users?.listAPIResponse);
    // const entities = listAPIResponse?.value?.list;
    // const totalPages = listAPIResponse?.value?.totalPages;
    // const totalItems = listAPIResponse?.value?.totalItems;
    const dispatch = useDispatch();
    const { customerId } = useParams();

    const loggedInUser = useSelector(x => x.auth.loggedInUser);

    const [currentPage, setCurrentPage] = useState(1);
    const [taskFilter, setTaskFilter] = useState(false);

    useEffect(() => {
        setTaskFilter({
            "dueDateStatus": due_date_status,
            "keyword": keyword,
            "statusId": status_id,
            "priorityId": priority_id,
            "categoryId": category_id,
            "customListId": custom_list_id,
            "userId": loggedInUser?.id || -1
        });
    }, [loggedInUser]);

    const [dataFilter, setDataFilter] = useState(false);
//    alert('dataFilter: '+JSON.stringify(dataFilter));
    const [pageFilter, setPageFilter] = useState(false);

    useEffect(() => {
        if(taskFilter) {
            setDataFilter({
                "taskFilter": taskFilter
            });
        }
    }, [taskFilter]);

    useEffect(() => {
        if(dataFilter && currentPage) {
            setPageFilter({
                "pageNumber": currentPage,
                "pageSize": 500,
                "sortField": "dueDate",
                "sortDirection": "desc",
                "dataFilter": dataFilter
            })
        }
    }, [currentPage, dataFilter]);

    useEffect(() => {
        if(pageFilter) {
            // dispatch(breadcrumbsActions.setCurrentTitle(`Calendar Tasks`));
            loadTasksForCalendar(pageFilter);
        }
    }, [pageFilter]);

    const [taskEvents, setTaskEvents] = useState([]);
    const [loadingPercentage, setLoadingPercentage] = useState(-1);
    const [taskEventsSizeInDB, setTaskEventsSizeInDB] = useState(-1);

    async function loadTasksForCalendar(pageFilter) {
        const task_list = await dispatch(myActions.getPaginatedList(pageFilter));
        if(task_list && task_list?.payload?.list) {
            //alert('task_list?.payload?.list'+ JSON.stringify(task_list.payload.list));
            // alert('task_list: '+ task_list);
            // alert('task_list: '+ JSON.stringify(task_list?.payload?.list));
            setTaskEventsSizeInDB(task_list.payload.list.length);
            // if(task_list.payload.list.length===0){
            //     setTaskEvents([]);
            // } else {
                await task_list.payload.list.map(singleTask => {
                    //alert('taskEvents: '+JSON.stringify(taskEvents));
//                alert(new Date(singleTask.dueDate));
                    //alert('singleTask.category: '+ JSON.stringify(singleTask.category));

                    const eachEvent = {
                        id: singleTask.id,
                        label: singleTask.name,
                        groupLabel: singleTask.category?.name,
                        user: `${singleTask.assignedTo.firstname} ${singleTask.assignedTo.lastname}`,
                        color: StatusColors[singleTask.status?.name],
                        startHour: "00:00:00 AM",
                        endHour: "11:59:59 PM",
                        date: dayjs(singleTask.dueDate).format('YYYY-MM-DD'),
                        //date: "2024-12-10",
                        createdAt: new Date(),
                        createdBy: `${singleTask.createdBy.firstname} ${singleTask.createdBy.lastname}`,
                    };
                    setTaskEvents((oldValue) => [...oldValue, eachEvent]);
                });
            // }
        }
    }

    useEffect(() => {
        if(taskEventsSizeInDB>=0) {
            setLoadingPercentage(Math.round(taskEvents.length/taskEventsSizeInDB)*100);
        }
    }, [taskEvents, taskEventsSizeInDB]);

    function CircularProgressWithLabel(
        props: CircularProgressProps & { value: number },
    ) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ color: 'text.secondary' }}
                    >{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const handleCellClick = (event, row, day) => {
        // Do something...
    };

    const handleEventClick = (event, item) => {
        // Do something...
//        alert(item.label + ' - Due on: ' + dayjs(item.date).format('MMM DD, YYYY (ddd)') );
            dispatch(breadcrumbsActions.setSelectedTaskId(item.id));
            dispatch(breadcrumbsActions.setKeepModalOpen(true));
            dispatch(breadcrumbsActions.setModalModified(false));
    };

    const handleEventsChange = (item) => {
        // Do something...
    };

    const handleAlertCloseButtonClicked = (item) => {
        // Do something...
    };

    return (
        (loadingPercentage<100) ? (
            <CircularProgressWithLabel value={loadingPercentage} />
        ):(
            <Scheduler
                orientation="porttrait"
                locale="en"
                events={taskEvents}
                legacyStyle={false}
                options={state?.options}
                alertProps={state?.alertProps}
                toolbarProps={state?.toolbarProps}
                onEventsChange={handleEventsChange}
                onCellClick={handleCellClick}
                onTaskClick={handleEventClick}
                onAlertCloseButtonClicked={handleAlertCloseButtonClicked}
            />
         )
    );
}
